<div class="container-xl">
    <div class="vertical-tabs">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#myPlaned1" role="tab" aria-controls="home">PLANNED VISITS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#myPlaned2" role="tab" aria-controls="profile">PAST VISITS</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="myPlaned1" role="tabpanel"> 
          <div class="sv-tab-panel">
              <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                  <P>CONACT PERSON SURENDRA <span style="font-weight: 800;">| PHONE : 8076614718</span></P>
                </li>
                <li class="btn">
                    <button style="background-color: white; color: black;">CANCEL</button>
                    <button style="background-color: black; color: white;">CHANGE PLAN</button>
                </li>
                <!-- <li class="btn1">
                  <button style="background-color: white; color: black;">CANCEL</button>
                  <button style="background-color: black; color: white;">CHANGE PLAN</button>
              </li> -->
              </ul>
              <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                  <!-- <p class="logo"> M</p> -->
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                  <P>CONACT PERSON SURENDRA <span style="font-weight: 800;">| PHONE : 8076614718</span></P>
                </li>
                <li class="btn">
                    <button style="background-color: white; color: black;">CANCEL</button>
                    <button style="background-color: black; color: white;">CHANGE PLAN</button>
                </li>
              </ul>
              <ul>
                <li>
                  
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                  <!-- <p class="logo"> M</p> -->
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                  <P>CONACT PERSON SURENDRA <span style="font-weight: 800;">| PHONE : 8076614718</span></P>
                </li>
                <li class="btn">
                    <button style="background-color: white; color: black;">CANCEL</button>
                    <button style="background-color: black; color: white;">CHANGE PLAN</button>
                </li>
              </ul>
              <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                  <!-- <p class="logo"> M </p> -->
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                  <P>CONACT PERSON SURENDRA <span style="font-weight: 800;">| PHONE : 8076614718</span></P>
                </li>
                <li class="btn">
                    <button style="background-color: white; color: black;">CANCEL</button>
                    <button style="background-color: black; color: white;">CHANGE PLAN</button>
                </li>
              </ul>
          </div>
        </div>




     <!--for responsive-->



        
     <div class="tab-pane" id="myPlaned2" role="tabpanel">
      <div class="sv-tab-panel">
        <ul>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
            <div class="vLine"></div>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
        </ul>
        <ul>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
            <div class="vLine"></div>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
        </ul>
        <ul>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
            <div class="vLine"></div>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
        </ul>
        <ul>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
            <div class="vLine"></div>
            <li>
                <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
            </li>
            <li>
              <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
              <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
              <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
            </li>
        </ul>
      </div>
    </div>
     

       
       
     

     <!--till here-->







        <div class="tab-pane" id="myPlaned2" role="tabpanel">
          <div class="sv-tab-panel">
            <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
                <div class="vLine"></div>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
            </ul>
            <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
                <div class="vLine"></div>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
            </ul>
            <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
                <div class="vLine"></div>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
            </ul>
            <ul>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
                <div class="vLine"></div>
                <li>
                    <img src="assets/img/room4.jpg" alt="" width="70px" height="60px" >
                </li>
                <li>
                  <p style="font-weight: 800;">FRIDAY, 30th JUNE AT 4:00 PM</p>
                  <p>AWADH COMPLEX 152, LAXMI NAGAR DELHI - 110091</p>
                  <P style="color:#00000085;">SHARED ROOM 3BHK | SEMI FURNISHED</P>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>