import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './UI/home/home.component';
import { LoginComponent } from './UI/login/login.component';
import { ChangePasswordComponent } from './UI/change-password/change-password.component';
import { RegisterComponent } from './UI/register/register.component';
import { AuthGuard } from './Service/Users/auth.guard';
import { AdminNavbarComponent } from './UI/Admin/admin-navbar/admin-navbar.component';
import { AdminHomeComponent } from './UI/Admin/admin-home/admin-home.component';
import { HousesComponent } from './UI/Admin/houses/houses.component';
import { UsersComponent } from './UI/Admin/users/users.component';
import { AmenitiesComponent } from './UI/Admin/amenities/amenities.component';
import { AmenitiesdetailsComponent } from './UI/Admin/amenitiesdetails/amenitiesdetails.component';
import { BookingsComponent } from './UI/Admin/bookings/bookings.component';
import { NewAmenitiesComponent } from './UI/Admin/new-amenities/new-amenities.component';
import { NewUserComponent } from './UI/Admin/new-user/new-user.component';
import { NewHouseComponent } from './UI/Admin/new-house/new-house.component';
import { VendorComponent } from './vendor/vendor.component';
import { ListYourHouseComponent } from './UI/list-your-house/list-your-house.component';
import { ReferEarnComponent } from './UI/refer-earn/refer-earn.component';

import { UserDetailsComponent } from './UI/user-details/user-details.component';

import { QuestionComponent } from './UI/question/question.component';
import { PageCardComponent } from './UI/page-card/page-card.component';
import { PaymentComponent } from './UI/Admin/payment/payment.component';

import { SearchRoomsComponent } from './UI/search-rooms/search-rooms.component';
import { RoomDetailsComponent } from './UI/search-rooms/room-details/room-details.component';

import { ErrorComponent } from './UI/error/error.component';

import { CreateOwnerDetailsComponent } from './UI/Admin/create-owner-details/create-owner-details.component';
import { InvoiceDetailComponent } from './UI/Admin/invoice/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './UI/Admin/invoice/invoice-list/invoice-list.component';
import { PaymentReminderComponent } from './UI/Admin/payment-reminder/payment-reminder.component';
import { RecurringInvoiceComponent } from './UI/Admin/recurring-invoice/recurring-invoice.component';
import { RecurringDetailComponent } from './UI/Admin/recurring-detail/recurring-detail.component';
import { RecurringInvoiceCheckComponent } from './UI/Admin/recurring-invoice-check/recurring-invoice-check.component';
import { NavBarComponent } from './UI/nav-bar/nav-bar.component';
import { UsersInfoComponent } from './UI/users-info/users-info.component';
import { MyAccountComponent } from './UI/my-account/my-account.component';
import { NewBookingComponent } from './UI/new-booking/new-booking.component';
import { BookingDetailsComponent } from './UI/booking-details/booking-details.component';
import { NewInvoiceComponent } from './UI/new-invoice/new-invoice.component';

const routes: Routes = [
  // {path:'',redirectTo:'/login',pathMatch:'full'},
  {
    path: 'home',
    component: HomeComponent,
    // canActivate:[AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'listYourHouse', component: ListYourHouseComponent },
  { path: 'error', component: ErrorComponent },

  //Admin
  { path: 'AdminNabBar', component: AdminNavbarComponent },
  { path: 'AdminHome', component: AdminHomeComponent },
  { path: 'Houses', component: HousesComponent },
  { path: 'Users', component: UsersComponent },
  { path: 'userdetails/:id', component: UserDetailsComponent },
  { path: 'Amenities', component: AmenitiesComponent },
  { path: 'AmenetiesDetails/:id', component: AmenitiesdetailsComponent },
  { path: 'Bookings', component: BookingsComponent },

  { path: 'NewHouse', component: NewHouseComponent },
  { path: 'NewBookings', component:NewBookingComponent },
  { path: 'BookingDetails/:id', component: BookingDetailsComponent },
  { path: 'NewUser', component: NewUserComponent },
  { path: 'NewAmenity', component: NewAmenitiesComponent },
  { path: 'CreateOwnerDetails', component: CreateOwnerDetailsComponent },
  { path: 'InvoiceDetail/:id', component: InvoiceDetailComponent },
  { path: 'InvoiceList', component: InvoiceListComponent },
  { path: 'NewInvoice', component: NewInvoiceComponent },
  { path: 'PaymentReminder', component: PaymentReminderComponent },
  { path: 'RecurringInvoice', component: RecurringInvoiceComponent },
  { path: 'RecurringDetail', component: RecurringDetailComponent },
  { path: 'RecurringInvoiceCheck', component: RecurringInvoiceCheckComponent },

  { path: 'user-details', component: UserDetailsComponent },

  { path: 'users-info', component: UsersInfoComponent },

  { path: 'my-account', component: MyAccountComponent },

  { path: 'nav-bar', component: NavBarComponent },


  { path: 'vendor', component: VendorComponent },
  // refer&earn////////
  { path: 'refer', component: ReferEarnComponent },
  { path: 'question', component: QuestionComponent },

  { path: 'page-card', component: PageCardComponent },
  { path: 'payment', component: PaymentComponent },


  { path: 'search_rooms', component: SearchRoomsComponent },
  { path: 'room-details', component: RoomDetailsComponent },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
