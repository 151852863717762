import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AmenitiesService {
    

    public amenetiesId: any;

    constructor(private http: HttpClient, private router: Router) {}
    
    public getAmenities() : Observable<any> {
      return this.http.get('/api/Amenities')
    } 
    
    public saveAmenitiesDetails(amenity) : Observable<any> {
      return this.http.post('/api/Amenities', amenity)
    }

    public getAmenetiesbyId(amenetiesId: any) : Observable<any> {
      let url = '/api/Amenities' ;
      url = `${url}/${amenetiesId}`
      return this.http.get(url);
    }

 

    public editAmenitiesDetails(amenetiesId: any, amenitiesDetails: any) : Observable<any> {
      let url = '/api/Amenities' ;
      url = `${url}/${amenetiesId}`
      return this.http.put(url, amenitiesDetails)
    }

    public deleteAmenitiesDetails(amenetiesId: any) : Observable<any> {
      let url = '/api/Amenities' ;
      url = `${url}/${amenetiesId}`
       return this.http.delete(url) 
    }

}