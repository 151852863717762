import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  searchKey:string;

  filterData =[
      {"name":"Asmen Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Anup Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Anuj Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Avanish Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Rishi Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Shrish Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Prashant Singh", "contact":"7874541231", "telant_Id":"SBIN1245"},
      {"name":"Kamal Singh", "contact":"7874541231", "telant_Id":"SBIN1245"}
  ]
  filterData1 =[
    {"name":"Asmen Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Anup Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Anuj Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Avanish Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Rishi Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Shrish Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Prashant Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."},
    {"name":"Kamal Singh", "contact":"7874541231", "day":"Tuesday", "time":"10:00a.m."}
]
filterData2 =[
  {"name":"Asmen Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Anup Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Anuj Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Avanish Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Rishi Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Shrish Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
  {"name":"Prashant Singh", "contact":"7874541231", "date":"10-01-2021", "paymentStatus":"fully","agreementStatus":"completed","address":"Delhi", "roomNo":"101", "invoice":"7001","fieldManager":"abc", "status":"moved-in"},
 
]
filterData3 =[
  {"name":"Asmen Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Anup Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Anuj Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Avanish Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Rishi Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Shrish Singh", "contact":"7874541231", "date":"10-01-2021", "invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
  {"name":"Prashant Singh", "contact":"7874541231", "date":"10-01-2021","invoice":"7001","reason":"no","fieldManager":"abc", "rating":"9","status":"moved-in"},
 
]
filterData4 =[
  {"name":"Asmen Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Anup Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Anuj Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Avanish Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Rishi Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Shrish Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Prashant Singh", "contact":"7874541231", "id":"1245"},
  {"name":"Kamal Singh", "contact":"7874541231", "id":"1245"}
]
  ngOnInit(): void {  
  }
  onSearchClear(){
    this.searchKey="";
  }
  openDialog() {
    this.dialog.open(DialogBoxComponent);
  }
}

