<!-- <app-nav-bar></app-nav-bar> -->

<div class="section">
  <div class="row m-0 p-0">
    <div class="col-md-12">
      <div class="mt-5 mb-5">
        <p class="text-center apple">BOOKING MADE EASY</p>
        <h1 class="text-center">
          We are <img src="assets/img/logo-sm.svg" alt="" />
        </h1>
      </div>
    </div>

    <div class="col-md-12">
      <div class="m-3 forSh pr-4 pl-4 pt-5 pb-2">
        <ul class="listView row">
          <li class="col-lg-3">
            <label>WHERE TO?</label>
            <mat-form-field class="example-full-width" floatLabel="never">
              <span matPrefix class="material-icons" style="color: #dbdbdb;">
                location_on
              </span>
              <input matInput ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
              
            </mat-form-field>
            
          </li>
          <li class="col-lg-3">
            <label>WHO ARE YOU?</label>
            <mat-form-field class="example-full-width" floatLabel="never">
              <mat-select>
                <mat-option value="1">I'm a Boy</mat-option>
                <mat-option value="2">I'm a Girl</mat-option>
                <mat-option value="3">We are family</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="col-lg-3">
            <label>HOUSE TYPE</label>
            <mat-form-field class="example-full-width" floatLabel="never">
              <mat-select>
                <mat-option value="1">SHARED ROOM</mat-option>
                <mat-option value="2">PRIVATE ROOM</mat-option>
                <mat-option value="3">FULL HOUSE</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="col-lg-3">
            <div class="wrap">
              <button>

                <a [routerLink]="['/search_rooms']">SEARCH</a>

                <a [routerLink]="['/page-card']">SEARCH</a>

              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 section4">
      <img class="img-responsive" src="/assets/img/home-1.svg" alt="" />
    </div>
    <div class="col-md-12 section5">
      <div class="section5_1">What Our Services?</div>
      <div class="section5_2">
        Travelling is just part of the equation, you also need to figure out a
        place to stay. How to balance it? We are here to do the needful with our
        profound catalog of affordable places to stay. Rooms, Homes, Private
        Villas, you just name it. We have it all.Oh, did we mention we have
        Private and Shared Dorms as well? Experience the upcoming trend today.
        Share, Meet & Interact, all under one roof.
      </div>
    </div>
  </div>
  <div class="container-fluid section6">
    <div class="row p-0 m-0">
      <div class="col-md-4 section6_1 text-center">
        <img src="/assets/img/home2.svg" height="61px" alt="Shared rooms" />
        <div class="section6header">
          SHARED ROOMS
        </div>
        <div class="section6content">
          <p>
            Day or night, we are here for you.Talk to our
            support team.
          </p>
        </div>
        <div class="link_arrowdiv">
          <a href="#">
            <img
              height="13px"
              width="8px"
              src="/assets/img/next.svg"
              alt="Linkarrow"
            />
            <span class="explore">EXPLORE MORE</span>
          </a>
        </div>
      </div>
      <div class="col-md-4 section6_1 text-center">
        <img src="/assets/img/home3.svg" height="61px" alt="Shared rooms" />
        <div class="section6header">
          PRIVATE ROOMS
        </div>
        <div class="section6content">
          <p>
            Day or night, we are here for you.Talk to our
            support team.
          </p>
        </div>
        <div class="link_arrowdiv">
          <a href="#">
            <img
              height="13px"
              width="8px"
              src="/assets/img/next.svg"
              alt="Linkarrow"
            />
            <span class="explore">EXPLORE MORE</span>
          </a>
        </div>
      </div>
      <div class="col-md-4 section6_1 text-center">
        <img src="/assets/img/home4.svg" height="61px" alt="Shared rooms" />
        <div class="section6header">
          SHARED ROOMS
        </div>
        <div class="section6content">
          <p>
            Happiness quite unshared can scarcely be called happiness; it has no
            taste
          </p>
        </div>
        <div class="link_arrowdiv">
          <a href="#">
            <img
              height="13px"
              width="8px"
              src="/assets/img/next.svg"
              alt="Linkarrow"
            />
            <span class="explore">EXPLORE MORE</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid section7">
    <div class="container-fluid section7_01">
      <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-2 col-sm-2 col-xs-3">
          <div class="text-center">
            <div class="img-responsive">
              <img
                class="img-responsive"
                style="
                  -webkit-filter: drop-shadow(10px 3px 0px rgba(0, 0, 0, 0.14));
                  filter: drop-shadow(10px 3px 0px rgba(0, 0, 0, 0.14));
                "
                src="/assets/img/section1.svg"
                alt="Search"
              />
            </div>
            <div class="section7head" style="margin-top: 35px;">step 1</div>
            <div class="section7content">
              Find a place that fits all your preferences.
            </div>
          </div>
        </div>
        <div
          class="col-md-1 col-sm-1 res_hide text-center"
          style="margin-top: 33px;"
        >
          <img class="arrow"
            style="opacity: 0.24;"
            src="/assets/img/next-2.svg"
            alt="Processarrow"
          />
        </div>
        <div class="col-md-2 col-sm-2 col-xs-3">
          <div class="text-cnter" style="margin-top: 0; padding-top: 0;">
            <div class="img-responsive-left1">
              <img
                class="img-responsive"
                style="
                  -webkit-filter: drop-shadow(8px 3px 0px rgba(0, 0, 0, 0.14));
                  filter: drop-shadow(8px 3px 0px rgba(0, 0, 0, 0.14));
                "
                src="/assets/img/section2.svg"
                alt="Visit"
              />
            </div>
            <div
              class="section7head"
              style="margin-top: 25px; margin-left: 4px;"
            >
              step 2
            </div>
            <div class="section7content">
              Finalise a time and date to visit the homes you like.
            </div>
          </div>
        </div>
        <div
          class="col-md-1 col-sm-1 res_hide text-center"
          style="margin-top: 33px;"
        >
          <img class="arrow"
            style="opacity: 0.24;"
            src="/assets/img/next-2.svg"
            alt="Processarrow"
          />
        </div>
        <div class="col-md-2 col-sm-2 col-xs-3">
          <div class="text-center">
            <div class="img-responsive-right1">
              <img
                class="img-responsive"
                style="
                  -webkit-filter: drop-shadow(6px 3px 0px rgba(0, 0, 0, 0.14));
                  filter: drop-shadow(8px 3px 0px rgba(0, 0, 0, 0.14));
                "
                src="/assets/img/section3.svg"
                alt="Book"
              />
            </div>
            <div class="section7head" style="margin-top: 35px;">step 3</div>
            <div class="section7content">
              Pay a token amount online to lock the bed / room / house.
            </div>
          </div>
        </div>
        <div
          class="col-md-1 col-sm-1 res_hide text-center"
          style="margin-top: 33px;"
        >
          <img class="arrow"
            style="opacity: 0.24;"
            src="/assets/img/next-2.svg"
            alt="Processarrow"
          />
        </div>
        
        <div class="col-md-2 col-sm-2 col-xs-3">
          <div class="text-center">
            <div class="img-responsive-right2">
              <img
                class="img-responsive"
                style="
                  -webkit-filter: drop-shadow(6px 3px 0px rgba(0, 0, 0, 0.14));
                  filter: drop-shadow(6px 1px 0px rgba (0, 0, 0, 0.14));
                "
                src="/assets/img/section4.svg"
                alt="Movein"
              />
            </div>
            <div class="section7head" style="margin-top: 25px;">step 4</div>
            <div class="section7content">
              Choose a move-in date and just show up!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid section8">
  <div class="row m-0 p-0">
    <div class="col-md-6 col-sm-6 section8_1">
      <div class="section8_1head">
        Refer &amp; Earn Rs. 500
      </div>
      <div class="section8_1content1">
        Invite your friends to StayHook via your invite code and your friend
        will get a coupon worth Rs. 500. What’s even better? Once they confirm a
        booking via StayHook, even you will get a coupon worth Rs. 500!
      </div>
      <div class="section8_1content2">
        It’s like sharing cookies. Who doesn’t love cookies, right?
      </div>
      <div class="section8_1content3">
        <div class="row m-0 p-0">
          <div class="col-md-6" style="padding: 0px; margin: 0;">
            <div class="section8_1_1">ENTER FRIEND'S EMAIL</div>
            <mat-form-field class="example-full-width-2" floatLabel="never">
              <input matInput placeholder="Email" name="email" />
            </mat-form-field>
            <div style="margin-top: 30px;">
              <a href="referandearn.html">
                <img
                  height="13px"
                  width="8px"
                  src="/assets/img/next.svg"
                  alt="Linkarrow"
                />
                <span class="explore">EXPLORE MORE</span>
              </a>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <button type="submit" class="searchbtn btn invite">
              INVITE FRIENDS
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 text-center">
      <div class="img-responsive" style="margin-top: 10px;">
        <img src="/assets/img/refer&ern.svg" alt="Refer &amp; earn" />
      </div>

      <div class="shpinr">1 SHP = 1 INR</div>
      <div class="shp">SHP= Stayhook Points</div>
    </div>
  </div>
</div>
<div class="container-fluid section9">
  <div class="row m-0 p-0">
    <div class="col-md-6 col-sm-6">
      <img src="/assets/img/mobile.svg" alt="Mobile app" />
    </div>
    <div class="col-md-6 col-sm-6 section9_2">
      <div class="section9_2btn">
        <span class="section9_2btntext">COMING SOON..</span>
      </div>
      <div style="margin-top: 30px;">
        Now you can do all this (and more) right from your mobile. Download our
        app and get started !
      </div>
      <div class="container-fluid apps_btnsarea">
        <div class="section9_2_1btn">
          <div style="float: left;">
            <img src="/assets/img/play.svg" alt="Playstore" />
          </div>
          <div class="section9_2_1">
            GET IT ON
          </div>
          <div class="section9_2_2">
            GOOGLE PLAY
          </div>
        </div>
        <div class="section9_2_1btn appstorebtn">
          <div style="float: left;">
            <img src="/assets/img/apple.svg" />
          </div>
          <div class="section9_2_1">
            GET IT ON
          </div>
          <div class="section9_2_2">
            APP STORE
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
