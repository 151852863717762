import { Component, OnInit } from '@angular/core';
import { BookingsService } from './bookings.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  bookings = [];

  constructor(private bookings_service: BookingsService, private router: Router, private route: ActivatedRoute) { 
    this.ongetBookings()
  }

  ngOnInit(): void {
  }

  onSelect(bookingsId) {
    return this.router.navigate(['/BookingDetails',bookingsId])
     
   }

  ongetBookings() {
    // console.log('hi')
    this.bookings_service.getBookings().subscribe(
      res => {
        console.log('hi')
        this.bookings = res
      }
    )
  }

  onDeleteBookings(bookingsId){
   if(confirm('Are you sure you want to cancel booking?')){
     this.bookings_service.deleteBookingsDetails(bookingsId).subscribe(
       res => {
          console.log(res)
       }
     )
   }
   let currentUrl = this.router.url;
   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   this.router.onSameUrlNavigation = 'reload';
   this.router.navigate([currentUrl]);
   alert('Booking cancelled successfully')
  }

}
