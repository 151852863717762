<div class="container-fluid fluid">
    <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
            <div class="dash-panel-head">
                <span class="pull-right margin-10">
                  <a
                    class="btn active btn-default btn-create"
                    [routerLink]="['/NewInvoice']"
                    routerLinkActive="router-link-active"
                    >New Invoice</a
                  >
                </span>
              </div>
            <div class="row">
                <div class="col-md-1">
                    <button class="mat-b" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <span class="material-icons  dehaze1">dehaze</span> 
                      </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <a [routerLink]="['/RecurringDetail']" class="menu_nav" routerLinkActive="router-link-active">Recurring Detail</a>
                        </button>
                        <button mat-menu-item>
                            <a [routerLink]="['/RecurringInvoiceCheck']" class="menu_nav" routerLinkActive="router-link-active">Recurring Invoice Check</a>
                        </button>
                    </mat-menu>
                </div>
                <div class="col-md-11">
                    <mat-form-field>
                        <span class="material-icons">
                            calendar_today</span>
                        <mat-select>
                            <mat-option value="one">This Week</mat-option>
                            <mat-option value="two">7 Days Earlier</mat-option>
                            <mat-option value="three">15 Days Earlier</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="material-icons setting">
                        settings
                    </span><a href="#">Customize Report</a>
                    <div class="query-builder1">
                        <button class="btn design_btn">
                            <span class="material-icons">
                                query_builder
                            </span>Schedule Report</button>
                    </div>
                    <mat-form-field class="query-builder1">
                        <span class="material-icons">
                            print
                        </span>
                        <mat-select>
                            <mat-option value="one">1</mat-option>
                            <mat-option value="two">2</mat-option>
                            <mat-option value="three">3</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="query-builder1 mar-1">
                        <mat-label>Export As</mat-label>
                        <mat-select>
                            <mat-option value="one">1</mat-option>
                            <mat-option value="two">2</mat-option>
                            <mat-option value="three">3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-bordered table-striped all-user-table">
                    <thead>
                        <tr>
                            <th class="thead">ID</th>
                            <th class="thead">Date</th>
                            <th class="thead">Transaction</th>
                            <th class="thead">Type</th>
                            <th class="thead">Status</th>
                            <th class="thead">Customer ID</th>
                            <th class="thead">Age</th>
                            <th class="thead">Amount</th>
                            <th class="thead">Balance Due</th>
                            <th class="thead">Mode of Payment</th>
                            <th class="thead">Billing Address</th>
                            <th class="thead">Shipping Address</th>
                            <th class="thead">Description</th>
                            <th class="thead">Created By</th>
                            <th class="thead">Created On</th>
                            <th class="thead">Modified By</th>
                            <th class="thead">Modified On</th>
                            <th class="thead">IsActive</th>
                            <th class="thead">Customer</th>
                            <th class="thead">Action</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of invoices">
                            <td class="thead" >{{item.id}}</td>
                            <td class="thead">{{item.invoiceDate}}</td>
                            <td class="thead">{{item.transactionId}}</td>
                            <td class="thead">{{item.invoiceType}}</td>
                            <td class="thead">
                                {{item.invoiceStatus}}
                            </td>
                            <td class="thead">
                                {{item.customerId}}
                                </td>
                            <td class="thead">{{item.age}}</td>
                            <td class="thead">{{item.amount}}</td>
                            <td class="thead">{{item.balanceDue}}</td>
                            <td class="thead">{{item.modeOfPayment}}</td>
                            <td class="thead">{{item.billToAddress}}</td>
                            <td class="thead">{{item.shipToAddress}}</td>
                            <td class="thead">{{item.invoiceDescription}}</td>
                            <td class="thead">{{item.createdBy}}</td>
                            <td class="thead">{{item.createdOn}}</td>
                            <td class="thead">{{item.moifiedBy}}</td>
                            <td class="thead">{{item.modifiedOn}}</td>
                            <td class="thead">{{item.isActive}}</td>
                            <td class="thead">{{item.customer}}</td>
                            <td 
                        class="thead" style="cursor: pointer;" (click) = "onSelect(item.id)"><a>Edit</a>
                        <a>
                            <a
                            id="delete_booking"
                            class="delete"
                            style="cursor: pointer;"
                            (click) = "onDeleteInvoiceDetails(item.id)">
                            <i class="fa fa-trash" ></i>
                            </a>
                        </a></td>


                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>