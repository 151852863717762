import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-planed-visits',
  templateUrl: './my-planed-visits.component.html',
  styleUrls: ['./my-planed-visits.component.scss']
})
export class MyPlanedVisitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
