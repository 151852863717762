<div class="recurring-check">
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">Customer Name*</span> 
        </div>
        <div class="col-md-10">
            <span class="rec-invoice">
                <select  class="form-control"><option>Krishna Shankar Tiwari</option></select>
                <button type="submit"><i class="fa fa-search"></i></button> <span class="inr-1">INR</span>
            </span>
            <a href="#" class="para-1">
                <span class="material-icons">how_to_reg</span> 
                <span style="position: absolute;"> View Customer Details</span>
            </a>
            <tr class="para-2">
                <td>
                    <p>BILLING ADDRESS <a href="#">CHANGE</a></p>
                    <p>Flat No. 1402, Tower -A2, Golft City, Plot</p>
                    <p>No 11, Sector-75 Noida-201307</p>
                    <p>Noida</p>
                    <p>Uttar Pradesh India</p>
                </td>
                <td>
                    <p>SHIPPING ADDRESS <a href="#">CHANGE</a></p>
                    <p>2/206, SHANKER BHAVAN SHANI</p>
                    <P>MANDIR,FARRUKHABAD UTTAR</P>
                    <P>PRADESH-209625</P>
                    <P>Farrukhabad</P>
                    <p>Uttar Pradesh India</p>
                </td>
            </tr>
            <span class="para-3">
                <p>GST TREATMENT <span class="material-icons">edit</span></p>
                <p>Consumer</p>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">Place Of Supply*</span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <select  class="form-control"><option>Krishna Shankar Tiwari</option></select>
            </span>
        </div>
    </div> 
</div>   
<div class="recurring-check-1" style="background-color: #fff;">
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">Invoice#*</span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <input type="text" class="in-1">
            </span>
            <span class="material-icons rec-invoice-2">settings_suggest</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <span style="font-size: 12px;">Order Number</span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <input type="text">
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">Invoice Date*</span>
        </div>
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-4">
                    <span class="rec-invoice-8">
                        <input type="text">
                    </span>
                    <p class="para-4">To create transaction dated before 01/07/2021 <a href="#">click here</a> </p>
                </div>
                <div class="col-md-4">
                    <span  class="rec-invoice-3" style="font-size: 12px;">Terms</span>
                    <span class="rec-invoice-3">
                        <select  class="form-control"><option>Net 5</option></select>
                    </span>
                </div>
                <div class="col-md-4">
                    <span  class="rec-invoice-3 color1" style="font-size: 12px;">Due Date</span>
                    <span class="rec-invoice-3">
                        <input type="text" placeholder="20/08/2020">
                    </span>
                </div>
            </div>  
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">Salesperson</span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <select  class="form-control"><option>Selector Add Salesperson</option></select>
            </span>
        </div>
    </div> 
    <div class="row">
        <div class="col-md-2">
            <span style="font-size: 12px;">PAN</span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <input type="text">
            </span>
            <span  class="rec-invoice-4 color-name color1">MODE OF PAYMEN*</span>
            <span class="rec-invoice-4">
                <select  class="form-control"><option>BANK TRANSFER</option>
                    <!-- <span class="material-icons">clear</span> -->
                </select>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <span class="color-name">HelpLine Number... </span><span class="material-icons ques">help_outline </span>
        </div>
        <div class="col-md-10">
            <span class="rec-invoice-1">
                <input type="text">
            </span>
        </div>
    </div>
    <div class="row" style="margin-top: 60px;">
        <span class="material-icons icon1" style="color: #00000099;font-size: 15px;">dialpad</span>
        <table style="border:1px solid black">
            <tr>
                <th class="itme-det" style="width: 45%;">Item Details</th>
                <th style="text-align: right;">Quantity</th>
                <th style="text-align: right;">Rate</th>
                <th>Tax<span class="material-icons" style="position:absolute; font-size: 17px;">error_outline</span></th>
                <th style="text-align: right;">Amount</th>
            </tr>
            <tr>
                <td> ADVANCE RENT
                    <tr>
                        <span class="td-sub">CANCEL<p>SAC: <a href="#">Update <span class="material-icons">edit</span></a></p></span>
                    </tr>
                </td>
                <td style="text-align: right; padding: 0px 10px 32px 0px;">1</td>
                <td style="text-align: right; padding: 0px 10px 32px 0px;">11000</td>
                <td style="padding: 0px 0px 32px 0px;"> <select  class="form-control" style="background-color: #00000012; height: 36px; border: none;"><option>Non-Taxable</option></select></td>
                <td style="text-align: right; padding: 0px 10px 32px 0px;">11000.00</td>
            </tr>
        </table>
        <span class="glyphicon glyphicon-option-horizontal" style="color: #00000099;"></span>
        <span class="glyphicon glyphicon-remove-circle" style="color: #ff00009c;"></span>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="add-1">
                <span class="material-icons icone2">add</span> <span class="add-2">Add another line</span> 
                <span class="material-icons icone3">arrow_drop_down</span>
                <div class="msg-1">
                    <span>Customer Notes</span>
                    <input type="text" placeholder="Thank you for choosing Stayhook">
                    <span style="color: #00000066; font-size: 10px;">Will be displayed on the invoice</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="add-3">
                <table style="width: 100%;">
                    <tr>
                        <td>Sub Total</td>
                        <td></td>
                        <td>11000.00</td>
                    </tr>
                    <tr>
                        <td>Discount</td>
                        <td>  <input type="text" style="width: 40%; height: 27px;"><button><i class="fa fa-rupee" style="margin-left:7px; height:18px;"></i><span class="material-icons" style="font-size: 17px;" >arrow_drop_down</span></button></td>
                        <td>0.0</td>
                    </tr>
                    <tr>
                        <td> <span class="rec-invoice-5">
                            <input type="text" placeholder="Adjustment">
                        </span></td>
                        <td><input type="text" style="width: 45%; height: 27px"><span class="material-icons ques">help_outline </span></td>
                        <td>0.0</td>
                    </tr>
                    <tr>
                        <td style="font-weight: 700;">Total ( <i class="fa fa-rupee"></i> )</td>
                        <td></td>
                        <td style="font-weight: 700;">11000.00</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <button class="btn-1" type="button">Save as Draft</button>
            <button class="btn-1 btn-2">Save and send <i class="fa fa-caret-up"></i>  </button>
            <button class="btn-1" type="button">Cancle</button>
        </div>
        <div class="col-md-6">
            <span class="temp-1"><b>Template:</b> 'Speradsheet Template' <a href="#">Change</a></span>
        </div>
    </div>
</div>    

