<div class="container">
    <div class="row" *ngIf="hide1">
        <div class="col-md-6">
            <div class="date1">
                <article class="calendar-wrapper">
                    <igx-calendar></igx-calendar>
                </article>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <p>
                    Thank You ! Your<br>
                    License Date has been<br>
                    selected, Now select <br>
                    youe Moving Date or<br>
                    You can Move on the <br>
                    Same date as well
                </p>
                <p class="set-move">
                    <i class="fa">&#xf105;</i> <button (click)="onClick1()">SET MOVING DATE</button>
                </p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="hide2">
        <div class="card selcet-1">
            <span>SELECT MINIMUMM LOCKING DATE</span>
            <p>
                Som useful info about<br> minimum locking date & it's price <br>briefly goes here.a
            </p>
            <button (click)="onClick2()">3 MONTHS . Rs. 9,000/-</button>
            <button (click)="onClick2()">6 MONTHS . Rs. 8,000/-</button>
            <button (click)="onClick2()">11 MONTHS . Rs. 7,000/-</button>
        </div>
    </div>
    <div style="margin-top: 30px;" class="card selcet-2" *ngIf="hide3">
        <div class="select-part-1">
            <img class="sec2" src="/assets/img/opened-door-aperture.png" alt="">
            <span style="text-align: center;margin-top: 10px; font-size: 12px;">BEDROOM 1</span>
            <h3 style="text-align: center; font-weight: 500; font-size: 20px;">Rs. 4000</h3>
        </div>
        <div class="select-part-2">
            <h5 style="margin-top: 10px;">PAYMENT DETAILS</h5>
            <ul>
                <li>
                    <mat-checkbox class="example-margin">Token</mat-checkbox>
                </li>
                <li class="part-1">
                    <span style="font-size: 11px; font-weight: 600;">Rs. 1000</span>
                </li>
            </ul>
            <ul>
                <li>
                    <mat-checkbox class="example-margin">Security Deposit</mat-checkbox>
                </li>
                <li class="part-6">
                    <span style="font-size: 11px; font-weight: 600;">Rs. 7000</span>
                </li>
            </ul>
            <ul>
                <li>
                    <mat-checkbox class="example-margin">Onboarding Charges</mat-checkbox>
                </li>
                <li class="part-3">
                    <span style="font-size: 11px; font-weight: 600;">0</span>
                </li>
            </ul>
            <ul>
                <li>
                    <mat-checkbox class="example-margin">Society Charges</mat-checkbox>
                </li>
                <li class="part-4">
                    <span style="font-size: 11px; font-weight: 600;">0</span>
                </li>
            </ul>
            <ul>
                <li>
                    <mat-checkbox class="example-margin">Rent</mat-checkbox>
                </li>
                <li class="part-5">
                    <span style="font-size: 11px; font-weight: 600;">Rs. 2000</span>
                </li>
            </ul>
        </div>
        <div class="select-part-3">
            <span class="total">Total</span>
            <span style="font-size: 10px;">Inclusive of taxes</span>
            <span style="float: right;" class="total">Rs. 9000</span>
        </div>
        
        <div class="select-part-4">
            <mat-form-field appearance="legacy">
                <mat-label>REFERAL & COUPON CODE</mat-label>
                <input matInput>
            </mat-form-field>
            <button>APPLY NOW</button>
        </div>
        <div class="select-part-5">
            <span style="font-size: 10px; font-weight: 500;">THIS IS ONLY FOR BOY</span><br>
            <mat-checkbox (click)="disabled1()"class="example-margin">you're boy</mat-checkbox>
            <button [disabled]="disabled" >PROCEED TO PAY</button>
        </div>
        <div class="select-part-6">
            <span>By clicking proceed you have read & agree </span>
            <span style="text-decoration: underline;">Important Nots & Conditions</span>
        </div>
    </div>
</div>