<div class="container-fluid" style="padding: 5% 12% 5% 12%;">
  <div class="col-sm-12 pd-lf">
    <div class="dash-panel-head">
      <span class="pull-right margin-10">
        <a
          class="btn active btn-default btn-create"
          [routerLink]="['/NewHouse']"
          routerLinkActive="router-link-active"
          >New House</a
        >
      </span>
    </div>
    
    <!-- panel panel-default -->
    <div class="panel panel-default panel-with-select panel-padding">
      <div class="panel-body no-padding">
        <div class="table-responsive">
          <table class="table table-bordered table-striped all-user-table">
            <thead>
              <tr>
                <th class="thead">ID</th>
                <th class="thead">House Identity</th>
                <th class="thead">Address</th>
                <th class="thead">Created Date</th>
                <th class="thead">Email</th>
                <th class="thead">Phone no.</th>
                <th class="thead">RoleID</th>
                <th class="thead">Role</th>
                <th class="thead">Verified</th>
                <th class="thead" style="width: 78px;">Visible</th>
                <th class="thead" style="width: 78px;">Action</th>
              </tr>
            </thead>
            <!--temporary data-->
            <tbody>
              <tr id="user_6" *ngFor = "let data of houses">
                <td class="thead">
                  {{data.id}}
                </td>
                <td class="thead">{{data.firstName}}</td>
                <td class="thead">{{data.lastName}}</td>
                <td class="thead">{{ data.createdDate }}</td>
                <td class="thead">{{data.email}}</td>
                <td class="thead">{{data.phoneNo}}</td>
                <td class="thead">{{data.roleID}}</td>
                <td class="thead">{{data.role}}</td>
                <td class="thead">{{data.isVerrified}}</td>
                <td class="thead">{{data.userName}}</td>
                <td class="thead">
                  <a style="cursor: pointer">Edit</a>
                     
                  <a 
                    
                    class="amenity_delete"
                    id="amenity"
                    href="javascript:"
                    
                  >
                    <i class="fa fa-trash" ></i>
                  </a>
                </td>
              </tr>
            </tbody>  
            <!-- <tbody>
              <tr>
                <td class="thead">
                  <a href="/">SHFC29</a>
                </td>
                <td class="thead">ad, ad</td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-19"
                      href="/"
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-19"
                      href="/"
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH4C4E</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">Yes</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-18"
                      rel="nofollow"
                      data-method="put"
                      href="/"
                      >Unpublish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH0043</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-17"
                      href="javascript:"
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-17"
                      href="javascript:"
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH2514</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">Yes</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-16"
                      rel="nofollow"
                      data-method="put"
                      href="/"
                      >Unpublish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH41B1</a>
                </td>
                <td class="thead"></td>
                <td class="thead">Yes</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-15"
                      rel="nofollow"
                      data-method="put"
                      href="/"
                      >Unpublish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH5A34</a>
                </td>
                <td class="thead">
                  CC1-1101 SUPERTECH CAPETOWN, NOIDA, UTTAR PRADESH
                </td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-14"
                      href=""
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-14"
                      href=""
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH73D9</a>
                </td>
                <td class="thead">94/4, LALPUR, Uttar Pradesh</td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-13"
                      href=""
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-13"
                      href=""
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH7564</a>
                </td>
                <td class="thead">Golf City, Noida, Uttar Pradesh</td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-12"
                      href="/"
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-12"
                      href="/"
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SHDFFA</a>
                </td>
                <td class="thead">
                  C2 401, GOLF CITY PLOT 11, SECTOR 75, NOIDA, noida, up
                </td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-11"
                      href="/"
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-11"
                      href="/"
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="/">SH3758</a>
                </td>
                <td class="thead">
                  C2 401, GOLF CITY PLOT 11, SECTOR 75, NOIDA, NOIDA, UP
                </td>
                <td class="thead">No</td>
                <td class="thead">
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-10"
                      href="/"
                      >Edit</a
                    >
                    |
                  </div>
                  <div>
                    <a
                      data_type="active"
                      class="active_house"
                      style="text-decoration: underline;"
                      id="house-10"
                      href="/"
                      >Publish</a
                    >
                  </div>
                </td>
              </tr>
            </tbody> -->
          </table>
          <div class="apple_pagination">
            <div class="pagination">
              <span class="previous_page disabled">← Previous </span>
              <em class="current"> 1 </em>
              <a rel="next" href="/"> 2 </a>
              <a class="next_page" rel="next" href="/">Next →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
