<div class="recurring">
    <div class="header">
        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline">
            <mat-label>All Recurring Invoices</mat-label>
            <mat-select>
                <mat-option value="one">1</mat-option>
                <mat-option value="two">2</mat-option>
                <mat-option value="three">3</mat-option>
            </mat-select>
        </mat-form-field>
        <a href="#"><span class="material-icons">
            emoji_objects
            </span><span style="padding-top: 5px;">Page Tips</span></a>
        <span class="material-icons  dehaze1">
            dehaze
        </span>
        <span class="material-icons sett">settings</span>
        <button mat-raised-button><span class="material-icons">
            add
        </span>New</button>
    </div>
    <div style="overflow-x:auto;">
        <table class="table table-design ">
            <tr>
                <th>
                    <button class="mat-b" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <span class="material-icons">archive</span>
                      </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <a [routerLink]="['/RecurringDetail']" class="menu_nav" routerLinkActive="router-link-active">Recurring Detail</a>
                        </button>
                        <button mat-menu-item>
                            <a [routerLink]="['/RecurringInvoiceCheck']" class="menu_nav" routerLinkActive="router-link-active">Recurring Invoice Check</a>
                        </button>
                    </mat-menu>
                </th>
                <th>CUSTOMER NAME</th>
                <th>PROFILE NAME</th>
                <th>FREQUENCY</th>
                <th>LAST INVOICE DATE</th>
                <th>NEXT INVOICE DATE</th>
                <th>STATUS</th>
                <th>AMOUNT</th>
                <th><span class="material-icons">
                    search
                    </span></th>
            </tr>
            <tr  *ngFor="let user of filterData">
                <td></td>
                <td><i class="fas fa-square"></i><span><a href="/RecurringDetail">{{user.customerName}}</a></span></td>
                <td><a href="">{{user.profileName}}</a></td>
                <td>{{user.frequency}}</td>
                <td>{{user.lastInvoiceDate}}</td>
                <td>{{user.nextInvoiceDate}}</td>
                <td><span style="text-transform: uppercase; color: green;">{{user.status}}</span></td>
                <td><i class="fa fa-rupee">{{user.amount}}</i></td>
                <td></td>
            </tr>
        </table>
    </div>
</div>