import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  IsSaved = false;

  NewUserDetails: FormGroup


  constructor(private users_service: UsersService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {
   }

  ngOnInit(): void {
   
    this.NewUserDetails = this.fb.group({
      firstName: [''],
      lastName: [''],
      userName: [''],
      email: [''],
      phoneNo: [''],
      role: [''],
      isVerrified: ['']

    })



  }

  onsaveuserDetails() {
    // console.log(this.NewUserDetails.get('lastName').value);
    // console.log(this.NewUserDetails.value)

    this.users_service.saveuserDetails(this.NewUserDetails.value).subscribe(
      (res) => {
        console.log(res)
        if (res.firstName != null && res.userName != null && res.email != null){
          this.IsSaved = true;
          alert('New user saved successfully');
          this.router.navigateByUrl('/Users')
        }

      }
    )
  }

}
