import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { BookingsService } from '../Admin/bookings/bookings.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
  
  public IsEdited = false;

  NewBookingsDetails: FormGroup;

  public bookingsId;

  public bookingsDetails;

  constructor(private bookings_service: BookingsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {
   
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.bookingsId = id;
    this.ongetBookingbyId()

    this.NewBookingsDetails = this.fb.group({
      customerId: ['', Validators.required],
      customerName: ['', Validators.required],
      houseAddress: ['', Validators.required],
      bookingFor: [''],
      paymentStatus: [''],
      amount: 4000,
      bookingAction: [''],
      bookingDescription: ['']
    })


  }

  ongetBookingbyId(){
    this.bookings_service.getBookingsbyId(this.bookingsId).subscribe(
      res => {
         this.bookingsDetails = res;
         this.NewBookingsDetails = this.fb.group({
          customerId: res.customerId,
          customerName: res.customerName,
          houseAddress: res.houseAddress,
          bookingFor: res.bookingFor,
          paymentStatus: res.paymentStatus,
          amount: 4000,
          bookingAction: res.bookingAction,
          bookingDescription: res.bookingDescription
        })

      }
    )
  }

  oneditBookingDetails() {
    this.bookingsDetails.customerId = this.NewBookingsDetails.get('customerId').value
    this.bookingsDetails.customerName = this.NewBookingsDetails.get('customerName').value
    this.bookingsDetails.houseAddress = this.NewBookingsDetails.get('houseAddress').value
    this.bookingsDetails.bookingFor = this.NewBookingsDetails.get('bookingFor').value
    this.bookingsDetails.paymentStatus = this.NewBookingsDetails.get('paymentStatus').value
    this.bookingsDetails.amount = this.NewBookingsDetails.get('amount').value
    this.bookingsDetails.bookingAction = this.NewBookingsDetails.get('bookingAction').value
    this.bookingsDetails.bookingDescription = this.NewBookingsDetails.get('bookingDescription').value
    this.bookings_service.editBookingsDetails(this.bookingsId, this.bookingsDetails).subscribe(
      res => {
        console.log(res)
        if(res.status == 'Success'){
          this.IsEdited = true;
          alert('Updated successfully');
          this.router.navigateByUrl('/Bookings')

        }

      }
    )
  }

}
