<div id="container">
  <div class="alert alert-info">Signed in successfully.</div>
</div>

<div class="container-fluid fluid">
  <mat-tab-group>
    <mat-tab label="Customer">
      <mat-form-field class="search-form-field" floatLabel="never">
        <input matInput [(ngModel)]="searchKey" placeholder="Search">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon style="font-size: 21px;">close</mat-icon>
        </button>
      </mat-form-field>
      <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
          <div class="table-responsive">
            <table class="table table-bordered table-striped all-user-table">
              <thead>
                <tr>
                  <th class="thead">Name</th>
                  <th class="thead">Contact</th>
                  <th class="thead">Talent Id</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let user of filterData | filter:searchKey">
                  <td class="thead">
                    <a style="cursor: pointer;" (click)="openDialog()">{{user.name}}</a>
                  </td>
                  <td class="thead">{{user.contact}}</td>
                  <td class="thead">{{user.telant_Id}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Create House Owner">
      <mat-form-field class="search-form-field" floatLabel="never">
        <input matInput [(ngModel)]="searchKey" placeholder="Search">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon style="font-size: 21px;">close</mat-icon>
        </button>
      </mat-form-field>
      <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
          <div class="table-responsive">
            <table class="table table-bordered table-striped all-user-table">
              <thead>
                <tr>
                  <th class="thead">Name</th>
                  <th class="thead">Contact</th>
                  <th class="thead">Id</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor = "let user4 of filterData4 | filter:searchKey">
                  <td class="thead">
                    <a href="/CreateOwnerDetails">{{user4.name}}</a>
                  </td>
                  <td class="thead">{{user4.contact}}</td>
                  <td class="thead">{{user4.id}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Visits">
      <mat-form-field class="search-form-field" floatLabel="never"> 
        <input matInput [(ngModel)]="searchKey" placeholder="Search">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon style="font-size: 21px;">close</mat-icon>
        </button>
      </mat-form-field>
      <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
          <div class="table-responsive">
            <table class="table table-bordered table-striped all-user-table">
              <thead>
                <tr>
                  <th class="thead">Name</th>
                  <th class="thead">Contact</th>
                  <th class="thead">Day</th>
                  <th class="thead">Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let user1 of filterData1 | filter:searchKey">
                  <td class="thead">
                    <a href="#">{{user1.name}}</a>
                  </td>
                  <td class="thead">{{user1.contact}}</td>
                  <td class="thead">{{user1.day}}</td>
                  <td class="thead">{{user1.time}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Move-Ins">
      <mat-form-field class="search-form-field" floatLabel="never"> 
        <input matInput [(ngModel)]="searchKey" placeholder="Search">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon style="font-size: 21px;">close</mat-icon>
        </button>
      </mat-form-field>
      <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
          <div class="table-responsive">
            <table class="table table-bordered table-striped all-user-table">
              <thead>
                <tr>
                  <th class="thead">Name</th>
                  <th class="thead">Contact</th>
                  <th class="thead">Date</th>
                  <th class="thead">Payment Status</th>
                  <th class="thead">Agreement Status</th>
                  <th class="thead">Address</th>
                  <th class="thead">Room No.</th>
                  <th class="thead">Invoice</th>
                  <th class="thead">Field Manager</th>
                  <th class="thead">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let user2 of filterData2 | filter:searchKey">
                  <td class="thead">
                    <a href="#">{{user2.name}}</a>
                  </td>
                  <td class="thead">{{user2.contact}}</td>
                  <td class="thead">{{user2.date}}</td>
                  <td class="thead">{{user2.paymentStatus}}</td>
                  <td class="thead">{{user2.agreementStatus}}</td>
                  <td class="thead">{{user2.address}}</td>
                  <td class="thead">{{user2.roomNo}}</td>
                  <td class="thead"><a href="#">{{user2.invoice}}</a></td>
                  <td class="thead">{{user2.fieldManager}}</td>
                  <td class="thead">{{user2.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Move-Out">
      <mat-form-field class="search-form-field" floatLabel="never"> 
        <input matInput [(ngModel)]="searchKey" placeholder="Search">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon style="font-size: 21px;">close</mat-icon>
        </button>
      </mat-form-field>
      <div class="panel panel-default panel-with-select panel-padding">
        <div class="panel-body no-padding">
          <div class="table-responsive">
            <table class="table table-bordered table-striped all-user-table">
              <thead>
                <tr>
                  <th class="thead">Name</th>
                  <th class="thead">Contact</th>
                  <th class="thead">Date</th>
                  <th class="thead">Invoice</th>
                  <th class="thead">Reason If any</th>
                  <th class="thead">Field Manager</th>
                  <th class="thead">Ratings</th>
                  <th class="thead">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let user3 of filterData3 | filter:searchKey">
                  <td class="thead">
                    <a href="#">{{user3.name}}</a>
                  </td>
                  <td class="thead">{{user3.contact}}</td>
                  <td class="thead">{{user3.date}}</td>
                  <td class="thead"><a href="#">{{user3.invoice}}</a></td>
                  <td class="thead">{{user3.reason}}</td>
                  <td class="thead">{{user3.fieldManager}}</td>
                  <td class="thead">{{user3.rating}}</td>
                  <td class="thead">{{user3.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>  
  </mat-tab-group>
</div>
<br /><br />
