<div class="row section-1">
    <div class="col-md-6 col-sm-6 col-xs-6">
        
<h4 class="heading1">List Your House <br><br>With StayHook</h4>
<h4 class="heading12">List Your House <br>With StayHook</h4>
<p class="para1">Gratitude unlocks the fullness of life. It turns What we have into enough,
    and more. It turns denial into acceptance, chaos to order, confusion to clarity.
    It can turn a meal into a feast, a house into a home, a stranger into a friend.</p>
    
    <button type="submit" class="startbtn btn invite">GET STARTED</button>
    </div>

 <div class="col-md-6 col-sm-6 col-xs-6"></div>
</div>


<div class="img pic"> 
    <img src="/assets/img/newhouse1.svg" alt="" class="img-responsive">
    <!-- <img src="/assets/img/Mask Group 2.png" class= "img2"> -->
</div>

<div class="row section-2">
    <div class="col-md-2"></div>
    <div class="col-md-8">
<h6 class="heading2">Customer Needs our Solution</h6>
<p class="para2">
    As well all know it's nearly impossible to find furnished houses of different cities
    online at one place. Currently in India not many are engaging in the Economical house 
    Accommodations. We are working to make all furnished houses online from all over the India.
    So that one can book furnish houses from thier place.
</p>
    </div>
    <div class="col-md-2"></div>
</div>

<br><br>

<div class="row section-3">
    <div class="col-md-4">
<div class="circle">
    62.5%
</div>

<div class="rating">
    <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle checked"></span>
  <span class="fa fa-circle unchecked"></span>
  <span class="fa fa-circle unchecked"></span>
  <span class="fa fa-circle unchecked"></span>
  <span class="fa fa-circle unchecked"></span>
  </div>
<p class="text">
  of individuals have a <br>
  difficulty while booking of <br>
  searching furnished <br>
    house 
</p>

    </div>

    <div class="col-md-4">
        <div class="circle">
            75.0%
        </div> 
<div class="rating">
  <span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle checked"></span>
<span class="fa fa-circle unchecked"></span>
<span class="fa fa-circle unchecked"></span>
<span class="fa fa-circle unchecked"></span>
</div>

<p class="text">
    of individuals Preferred <br>
    online booking over <br>
    Traditional processes.<br> 
  </p>
    </div>

    <div class="col-md-4">
        <div class="circle">
            76.09%
        </div> 
        <div class="rating">
            <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle checked"></span>
          <span class="fa fa-circle unchecked"></span>
          <span class="fa fa-circle unchecked"></span>
          </div>
          <p class="text">
            of individuals Preferred to<br>
            book a single room over full<br>
            apartment because it is <br>
            economical for the working <br>
            individuals.
          </p>
    </div>
</div>

<br><br><br>

<div class="row section-4">
    <div class="col-md-6">
 <h6 class="heading3">How our product/Services<br><br> is a better solution</h6>

 <p class="para3">
    We understand the concern of working individual. We are working to make all furnished
    houses avilable at single platform on a affordable rates and even we provide easy cancellation
    to our customers. So that individual can book houses even from 90 days to 330 days on minimum 
    deposite of 50% off first month rent.
 </p>
    </div>

    <div class="col-md-6">
        <div class="img">
            <img src="/assets/img/key.jpg" alt="" height="250px" width="90%" class="img-responsive">
        </div>    
    </div>
</div>

<br><br><br>
<div class="row section-5">
    <div class="col-md-6">
        <h6 class="heading4">Stayhook Features</h6><br>
        <ul>
            <li>We furnished your place.</li><br>
            <li>We rentout your place on behalf of you.</li><br>
            <li>We manage Everything from documentation to collecting rent depositing in you account</li>
        </ul>
    </div>

    <div class="col-md-6">
        <h6 class="heading4">What We Do and How <br><br>We Do it?</h6><br>
        <ul>
            <li>Under Stayhook we provide house owners planty of options owners can give us
                Un/Semifurnished house or fully furnished house. </li><br>
            <li>We give houses to both families as well as working individuals however under working 
                individual scheme <br>
               <span> Owner can earn more than basic rent.</span>
            </li><br>
            <li>We list Your house on our website www.stayhook.com</li>
        </ul>
    </div>
</div>


<div class="row section-6">
    <div class="col-md-6">
        <div class="img">
            <img src="/assets/img/watch.jpg" alt="" height="370px" width="100%" class="img-responsive">
        </div>  
    </div>

    <div class="col-md-6">
        <h6 class="heading5">Benefits of Choosing<br><br>Stayhook</h6><br>
        <h6 class="more">Earn More*</h6>
        <p class="para-section-6">
            Our Business Model Allows House owners to earn more<br>
            income by renting out individual rooms/bed.
        </p>
        <ul>
            <li>No Brokerage</li><br>
            <li>Legal Help</li><br>
            <li>Safety and Security</li><br>
            <li>Tenant Verification</li><br>
            <li>Assured Rent Collection</li>
        </ul>
    </div>
</div>


<div class="row section-7">
    <div class="col-md-6">
        <h6 class="heading7">Current Scenario</h6><br>
        <h6 class="more">Furnished Houses</h6>
        <p class="para-section-7">
        Every year 2.3 Cr students graduate from college and Universities. Where they go they
        look for furnished houses to stay. We are witnessing a huge market in this sector. Also 
        this market is growing at the rate of 15%-20% per Year (Source HRD ministry 2016) 
        </p>

        <p class="para-section-7">FURNISHED HOUSE</p> 
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width:95%;
          background: black"
          >95%</div>
        </div>
<br>
        <p class="para-section-7">OTHER</p> 
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width:5%;
          background: black">5%</div>
        </div>
      </div>

    <div class="col-md-6">
        <div class="img">
            <img src="/assets/img/stairs.jpg" alt="" height="350px" width="100%" class="img-responsive">
        </div>  
    </div>
</div>


<div class="section-8">
<h6 class="heading-8">Stayhook Renting Model</h6>
        <h6 class="more">Financials (in thousands)</h6>
    <br><br>
<div class="row">
<div class="col-md-6">
    <div class="circle1">
        Traditional<br>Renting<br>Model
    </div><br>
    <h5>17,000 Rs</h5>
    <p>Monthly Rent</p>
</div>

<div class="col-md-6">
    
    <div class="circle2">
        Stayhook<br>Renting<br>Model
    </div> 
    <br>
    <div class="row">
        <div class="col-md-6 stayhookRentingModel">
            <span class="square-pink"></span>
            <span class="stayhookrent">11,000 Rs</span>
            <p class="month">Monthly Rent</p>
            <br><br>
            <span class="square-black"></span>
            <span class="stayhookrent">5,000 Rs</span>
            <p class="month">Monthly Rent</p>
        </div>
        <div class="col-md-6 stayhookRentingModel">
            <span class="square-red"></span>
            <span class="stayhookrent">9,000 Rs</span>
            <p class="month">Monthly Rent</p>
            <br><br>
            <span class="square-brown"></span>
            <span class="stayhookrent">5,000 Rs</span>
            <p class="month">Monthly Rent</p>
        </div>
    </div>
</div>

</div>
</div>

<hr>

<div class="row section-9">
    <div class="col-md-6">
        <span class="square-black"></span>
        <span class="stayhookrent">11,000 Rs</span>
        <p class="month">Expected Monthly Rent </p>
        <br>

        <span class="square-black"></span>
            <span class="stayhookrent">21,000 Rs</span>
            <p class="month">Amount After Deducting<br>
            StayHook Service Charge<br>(30%)</p>
            <br>

            <span class="square-black"></span>
            <span class="stayhookrent">5,000 Rs</span>
            <p class="month">Total Yearly Rent Owner Will<br>
            Get After Deducting Stayhook<br>
        Charge</p>
            <br>

            <span class="square-black"></span>
            <span class="stayhookrent">5,000 Rs</span>
            <p class="month">Extra Profit Compare To<br> Traditional Renting</p>
            
    </div>

    <div class="col-md-6">
        <div class="circle">
            <h3>25%</h3>
            <p>Increased Rent Owner <br>
            Get Compare to Traditional<br>
        Renting</p>
        </div>
    </div>
</div>

<hr>