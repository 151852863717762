import { Component, OnInit } from '@angular/core';
import { HousesService } from './houses.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-houses',
  templateUrl: './houses.component.html',
  styleUrls: ['./houses.component.scss']
})
export class HousesComponent implements OnInit {

  houses = [];

  constructor(private houses_service: HousesService, private router: Router, private route: ActivatedRoute) {
    this.ongetHouses()
   }

  ngOnInit(): void {
  }
  
  ongetHouses() {
    this.houses_service.getHouses().subscribe(
      (res) => {
        this.houses = res
      }
    )
  }

  



}
