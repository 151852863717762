import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_ENDPOINTS } from '../api-url-constant';
import { info } from 'console';

@Injectable({ 
  providedIn: 'root',
})
export class AuthService {
  
  constructor(private http: HttpClient, private _router: Router) {}
 
  public registerUser(user): Observable<any> {
    return this.http.post<any>('/api/Authentication/Register',user);  
    // environment.apiUrl + API_ENDPOINTS.REGISTER_URL
  }

  public loginUser(user): Observable<any> {
    // return this.http.post<any>(
    //   environment.apiUrl + API_ENDPOINTS.LOGIN_URL,
    //   user
    // );
    console.log(user)
    return this.http.post<any>('/api/Authentication/Login',user);
  }
  
  public loggedIn() {
    // debugger;
    if(localStorage.getItem('token')&&(localStorage.getItem('roles'))){
    return !!localStorage.getItem('token');
    }
  }

  public getLoggedInUserRoleType() {
    // debugger;
    if(localStorage.getItem('token')&&(localStorage.getItem('roles'))){
    return localStorage.getItem('roles');
    }
  }

  public loggedOut() {
    localStorage.removeItem('token');
    this._router.navigate(['/login']);
  }

  public getToken() {
    return localStorage.getItem('token');
  }


  public changePassword(chngpsswrd): Observable<any>{
    return this.http.post('/api/Common/ChangePassword', chngpsswrd)
  }
}

