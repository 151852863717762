import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

 
  formettedaddress=" ";
  options={
    componentRestrictions: {
       country: ['IN']
      }
    }
  
   public handleAddressChange(address: any) {
      this.formettedaddress=address.formetted_address;
  }
  

  constructor() { }

  ngOnInit(): void {
  }

}
