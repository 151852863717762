import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Service/Users/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  
  loginUserData : FormGroup;

  // loginUserData = { email: '', password: '' };
  constructor(private _auth: AuthService, private _router: Router, private fb: FormBuilder) {} 

  ngOnInit(): void {

  this.loginUserData = this.fb.group({
    userName: [''],
    password: ['']
  })
}
 
  public onClickloginUser() {
    console.log('hi')
    console.log(this.loginUserData.value)
    this._auth.loginUser(this.loginUserData.value).subscribe(
      (res) => {
        console.log(res);      
        localStorage.setItem('token', res.token)
        localStorage.setItem('roles', res.roles)
        localStorage.setItem('userName', res.userName)
        
        if(res.roles == "Admin"){
        this._router.navigate(['/AdminHome']);
        }
        else if(res.roles == "User"){
        this._router.navigate(['/users-info']);
        }
      }
    )


    // if(this.loginUserData.email == "Admin" && this.loginUserData.password == "Admin"){
    //   localStorage.setItem('token','admin');
    //   this._router.navigate(['/AdminHome']);
                    
    // }
    // else if(this.loginUserData.email == "Vendor" && this.loginUserData.password == "Vendor"){
    //   localStorage.setItem('token','aeasdrs1s64d24sdsd22');
    //   this._router.navigate(['/vendor']);
      
    // }
    // else if(this.loginUserData.email == "Users" && this.loginUserData.password == "Users"){
    //   localStorage.setItem('token','aeasdrs1s64d24sdsd23');
    //   this._router.navigate(['/refer']);
      
    // }
    // else{
    //   this._router.navigate(['/error']);
    // }
    // this._auth.loginUser(this.loginUserData).subscribe(
    //   (res) => {
    //     console.log(res);
    //     localStorage.setItem('token', res.user.jwtToken);
    //     this._router.navigate(['/AdminHome']);
    //   },
    //   (err) => console.log(err)
   //);

//For Admin Testing
//this._router.navigate(['/AdminNabBar']);
    //  
  }

 
}
