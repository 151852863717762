<div class="dialog-box">
    <div class="row">
        <div class="col-md-6 bed-1">
            <p style="font-weight: 600;">BED 1</p>
        </div>
        <div class="col-md-6 bed-2">
            <span style="float: right;" class="material-icons"> more_horiz</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 p-0 m-0 text-center">
            <img src="../../../assets/img/Group 1603.png" width="80px" class="p-3 m-3" alt="profile">
            <h3><b>SURAJ KUMAR</b></h3>
            <h5 class="address"><span class="material-icons icc">
                location_on
                </span>Laxmi Nagar ,New Delhi</h5>
            <h5 class="mail"><b><span class="material-icons icc">
                mail
                </span>srjmr@gmail.com</b></h5>
            <p class="details"><a><span class="material-icons icc">
                arrow_forward_ios
                </span><b>VIEW DOCUMENTS</b></a></p>
        </div>
    </div>
</div>    