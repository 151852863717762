<div class="row mar-1">
    <!--Side Bar showing List-->
    <div class="col-md-4">
        <ul style="list-style: none;">
            <li>
                <table class="table">
                    <tr>
                        <span class="mar-3">
                            <mat-form-field>
                                <mat-label>All Invoices</mat-label>
                                <mat-select>
                                    <mat-option value="recurring"><a style="text-decoration: none;"href="/RecurringInvoice">All Recurring Invoices</a></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span class="mar-3 mar-4"><span style="float:right" class="material-icons  dehaze1">
                                dehaze
                            </span>
                            <mat-form-field>
                                <mat-label><span class="material-icons">
                                        add
                                    </span>New</mat-label>
                                <mat-select>
                                    <mat-option value="one">1</mat-option>
                                    <mat-option value="two">2</mat-option>
                                    <mat-option value="three">3</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                    </tr>
                </table>
                <table class="table tablecontent" *ngFor="let user of filterData">
                    <tr>
                        <td><i class="fas fa-square"></i><span style="color:orange">{{user.customerName}}</span></td>
                        <td><i class="fa fa-rupee"> {{user.balanceDue}}</i></td>
                    </tr>
                    <tr>
                        <td><span style="color:aqua">{{user.transaction}}</span> | {{user.date}}</td>
                        <td>{{user.status}}</td>
                    </tr>
                    <hr>
                    <hr>
                </table>
            </li>
        </ul>
    </div>
    <div class="col-md-8 mar-2">
        <h2>Payment for INV-001214</h2>
        <hr>
        <table class="table tab">
            <tr>
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Customer Name</mat-label>
                        <input matInput placeholder="Enter Your Name">
                    </mat-form-field>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Payment #</mat-label>
                        <input matInput placeholder="Amount">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <span class="material-icons icon-view">
                    person
                </span><a class="icon-view" href="#">View Customer Details</a>
            </tr>
            <br>
            <tr>
                <div class="box shadow col-space">
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Amount Received(INR)</mat-label>
                        <input matInput placeholder="Enter Amount">
                    </mat-form-field>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Bank Charges(if any)</mat-label>
                        <input matInput>
                    </mat-form-field>
                    <br>
                    <td class="tax-size"><label>Tax Deducted?</label></td>
                    <td class="tax-size">
                        <mat-radio-group>
                            <mat-radio-button value="no">No,Tax Deducted</mat-radio-button><br>
                            <mat-radio-button value="yes">Yes,TDS(Income Tax)</mat-radio-button>
                        </mat-radio-group>
                    </td>
                </div>
            </tr>
            <tr class="payment-date">
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Payment Date</mat-label>
                        <input matInput placeholder="Enter Amount">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Payment Mode</mat-label>
                        <mat-select>
                            <mat-option value="one">1</mat-option>
                            <mat-option value="two">2</mat-option>
                            <mat-option value="three">3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Payment Mode</mat-label>
                        <mat-select>
                            <mat-option value="one">1</mat-option>
                            <mat-option value="two">2</mat-option>
                            <mat-option value="three">3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>References</mat-label>
                        <input matInput placeholder="Write Here">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field floatLabel="auto" class="form-field" hideRequiredMarker appearance="outline">
                        <mat-label>Notes</mat-label>
                        <textarea matInput placeholder="Write Here" rows="3"></textarea>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <button class="btn-primary" mat-raised-button><span class="material-icons">
                        save_alt
                    </span> Upload File</button><br>
                <mat-hint class="tax-size">You can Upload a maximum of 5 files, 5MB each</mat-hint>
            </tr>
            <br>
            <tr class="tax-size">
                <mat-checkbox>Notify Via SMS</mat-checkbox><br>
                <mat-checkbox>Email a "thankyou" note for this Payment</mat-checkbox><br>
                <mat-checkbox>abc@gmail.com</mat-checkbox>
            </tr>
            <br>
            <tr>
                <button class="btn-primary1" style="background-color: orangered;"mat-raised-button>Record Payment</button>&nbsp;
                <button class="btn-primary1" mat-raised-button>Cancel</button>
            </tr>
        </table>
    </div>
</div>