import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recurring-invoice',
  templateUrl: './recurring-invoice.component.html',
  styleUrls: ['./recurring-invoice.component.scss']
})
export class RecurringInvoiceComponent implements OnInit {

  constructor() { }
  filterData =[
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Anup Singh","profileName":" Anup Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Rishi Singh","profileName":"Rishi Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Anup Singh","profileName":" Anup Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Rishi Singh","profileName":" Rishi Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Anup Singh","profileName":" Anup Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},
    {"customerName":"Mr. Asmeen Singh","profileName":"Asmeen Singh","frequency":"Monthly","lastInvoiceDate":"01-02-2021","nextInvoiceDate":"01-03-2021","status":"Active","amount":"50000"},

]
  ngOnInit(): void {
  }

}
