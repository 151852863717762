import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { HousesService } from '../houses/houses.service';
import { ActivatedRoute, Router } from '@angular/router';

export class Location {
  location: string;
}

export class HouseRule {
  houserule: string;
}


export class KeepinMind {
  mind: string;
}

export class RoomBed {
  bed: string;
}

export class PriceRoomBed {
  price: string;
}

export class PriceFullRoom {
  price: string;
}

export class SemiRoomBed {
  semibed: string;
}

export class PriceSemiRoomBed {
  price: string;
}

export class PriceFullSemiRoom {
  price: string;
}

export class UnRoomBed {
  Unbed: string;
}

@Component({
  selector: 'app-new-house',
  templateUrl: './new-house.component.html',
  styleUrls: ['./new-house.component.scss'],
})
export class NewHouseComponent implements OnInit {
  isLinear = false;

  IsSaved = false;

  HouseDetails : FormGroup;


  //location
  Location = new Location();
  dataarray = [];

  onClickAddLocation() {
    this.Location = new Location();
    this.dataarray.push(this.Location);
  }
  onClickRemoveLocation(index) {
    this.dataarray.splice(index);
  }
  //

  //HouseRule
  HouseRule = new HouseRule();
  dataarrayrule = [];

  onClickAddRule() {
    this.HouseRule = new HouseRule();
    this.dataarrayrule.push(this.HouseRule);
  }
  onClickRemoveRule(index) {
    this.dataarrayrule.splice(index);
  }
  //

  //KeepinMind
  KeepinMind = new KeepinMind();
  dataarraymind = [];

  onClickAddMind() {
    this.KeepinMind = new KeepinMind();
    this.dataarraymind.push(this.KeepinMind);
  }
  onClickRemoveMind(index) {
    this.dataarraymind.splice(index);
  }
  //

  constructor(private _formBuilder: FormBuilder, private houses_service: HousesService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {

  //formGroup

  this.HouseDetails = this._formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    userName: [''],
    email: ['', (Validators.required, Validators.email)],
    phoneNo: ['', (Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'))],
    role: [''],
    isVerrified: ['']

  })
   

    //location
    this.Location = new Location();
    this.dataarray.push(this.Location);
    //

    //HouseRule
    this.HouseRule = new HouseRule();
    this.dataarrayrule.push(this.HouseRule);
    //

    //KeepinMind
    this.KeepinMind = new KeepinMind();
    this.dataarraymind.push(this.KeepinMind);
    //

    //////////slide-2/////////////////////////////

    //for image-radio-1////
    $(document).ready(function () {
      // add/remove checked class
      $('.image-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('image-radio1-checked');
        } else {
          $(this).removeClass('image-radio1-checked');
        }
      });

      // sync the input state
      $('.image-radio1').on('click', function (e) {
        $('.image-radio1').removeClass('image-radio1-checked');
        $(this).addClass('image-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ////////

    ///for text radio-1///
    $(document).ready(function () {
      $('.text-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('text-radio1-checked');
        } else {
          $(this).removeClass('text-radio1-checked');
        }
      });

      // sync the input state
      $('.text-radio1').on('click', function (e) {
        $('.text-radio1').removeClass('text-radio1-checked');
        $(this).addClass('text-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    /////

    ///for text radio-2/////
    $(document).ready(function () {
      $('.text-radio2').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('text-radio2-checked');
        } else {
          $(this).removeClass('text-radio2-checked');
        }
      });

      // sync the input state
      $('.text-radio2').on('click', function (e) {
        $('.text-radio2').removeClass('text-radio2-checked');
        $(this).addClass('text-radio2-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ///////////

    /////End slid-2////////////////////////////////////////////////

    //////////start-slid-3///////////////////////////////////////

    ///for image-radio-2///
    $(document).ready(function () {
      // add/remove checked class
      $('.image-radio2').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('image-radio2-checked');
        } else {
          $(this).removeClass('image-radio2-checked');
        }
      });

      // sync the input state
      $('.image-radio2').on('click', function (e) {
        $('.image-radio2').removeClass('image-radio2-checked');
        $(this).addClass('image-radio2-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });

    //////////

    //number increment decrement on button click on slide 3//
    $(document).on('click', function () {});
    //////////

    //////End Slid-3////////////////////////////////////////////
  }

  //number increment decrement on button click on slide 3//
  //furnished Room//
  furnish_room_price: number = 0;
  furnish_bed_price: number = 0;
  furnish_room_quantity: number = 0;
  i = 0;
  FurnishRoomPlus() {
    if (this.i != 50) {
      this.i++;
      this.furnish_room_quantity = this.i;
      this.furnish_room_price = this.i;
      this.furnish_bed_price = this.i;
    }

  }

  FurnishRoomMinus() {
    if (this.i != 0) {
      this.i--;
      this.furnish_room_quantity = this.i;
      this.furnish_room_price = this.i;
      this.furnish_bed_price = this.i;
    }
  }

  furnish_washroom_quantity: number = 0;
  w = 0;
  FurnishWashRoomPlus() {
    if (this.w != 50) {
      this.w++;
      this.furnish_washroom_quantity = this.w;
    }
  }

  FurnishWashRoomMinus() {
    if (this.w != 0) {
      this.w--;
      this.furnish_washroom_quantity = this.w;
    }
  }

  ///

  RoomBed = new RoomBed();
  roombedarray = [];

  PriceRoomBed = new PriceRoomBed();
  priceroombedarray = [];

  PriceFullRoom = new PriceFullRoom();
  pricefullroomarray = [];


  onclickPlusRoomBed() {
    this.RoomBed = new RoomBed();
    this.roombedarray.push(this.RoomBed);

  } 

  onclickPlusRoomBedPrice() {
    this.PriceRoomBed = new PriceRoomBed();
    this.priceroombedarray.push(this.PriceRoomBed);
  }

  onclickPlusFullRoomPrice() {
    this.PriceFullRoom = new PriceFullRoom();
    this.pricefullroomarray.push(this.PriceFullRoom);
  }

  onclickMinusRoomBed(index) {
    this.roombedarray.splice(index);
  }

  onclickMinusRoomBedPrice(index) {
    this.priceroombedarray.splice(index)
  }

  onclickMinusFullRoomPrice(index) {
    this.pricefullroomarray.splice(index)
  }



  //End Furnished Room//

  //Semi-Furnished Room//
  semifurnish_room_quantity: number = 0;
  semifurnish_room_price: number = 0;
  semifurnish_bed_price: number = 0;
  s = 0;
  SemiFurnishRoomPlus() {
    if (this.s != 50) {
      this.s++;
      this.semifurnish_room_quantity = this.s;
      this.semifurnish_room_price = this.s;
      this.semifurnish_bed_price = this.s;
    }
  }

  SemiFurnishRoomMinus() {
    if (this.s != 0) {
      this.s--;
      this.semifurnish_room_quantity = this.s;
      this.semifurnish_room_price = this.s;
      this.semifurnish_bed_price = this.s;
    }
  }

  semifurnish_washroom_quantity: number = 0;
  w2 = 0;
  SemiFurnishWashRoomPlus() {
    if (this.w2 != 50) {
      this.w2++;
      this.semifurnish_washroom_quantity = this.w2;
    }
  }

  SemiFurnishWashRoomMinus() {
    if (this.w2 != 0) {
      this.w2--;
      this.semifurnish_washroom_quantity = this.w2;
    }
  }

  //
  SemiRoomBed = new SemiRoomBed();
  semiroombedarray = [];
  PriceSemiRoomBed = new PriceSemiRoomBed();
  pricesemiroombedarray = [];
  PriceFullSemiRoom = new PriceFullSemiRoom();
  pricefullsemiroomarray = [];

  onclickPlusSemiRoomBed() {
    this.SemiRoomBed = new SemiRoomBed();
    this.semiroombedarray.push(this.SemiRoomBed);
  }

  onClickPlusPriceSemiRoomBed() {
    this.PriceSemiRoomBed = new PriceSemiRoomBed();
    this.pricesemiroombedarray.push(this.PriceSemiRoomBed);
  }

  onClickPlusPriceFullSemiRoom() {
    this.PriceFullSemiRoom = new PriceFullSemiRoom();
    this.pricefullsemiroomarray.push(this.PriceFullSemiRoom);
  }

  onclickMinusSemiRoomBed(index) {
    this.semiroombedarray.splice(index);
  }

  onClickMinusPriceSemiRoomBed(index) {
    this.pricesemiroombedarray.splice(index);
  }

  onClickMinusPriceFullSemiRoom(index) {
    this.pricefullsemiroomarray.splice(index);
  }
  //
  ///

  //Un-Furnished Room//
  Unfurnish_room_quantity: number = 0;
  s1 = 0;
  UnFurnishRoomPlus() {
    if (this.s1 != 50) {
      this.s1++;
      this.semifurnish_room_quantity = this.s1;
    }
  }

  UnFurnishRoomMinus() {
    if (this.s1 != 0) {
      this.s1--;
      this.semifurnish_room_quantity = this.s1;
    }
  }

  Unfurnish_washroom_quantity: number = 0;
  w3 = 0;
  UnFurnishWashRoomPlus() {
    if (this.w3 != 50) {
      this.w3++;
      this.semifurnish_washroom_quantity = this.w3;
    }
  }

  UnFurnishWashRoomMinus() {
    if (this.w3 != 0) {
      this.w3--;
      this.semifurnish_washroom_quantity = this.w3;
    }
  }

  //
  UnRoomBed = new UnRoomBed();
  Unroombedarray = [];

  onclickPlusUnRoomBed() {
    this.UnRoomBed = new UnRoomBed();
    this.Unroombedarray.push(this.UnRoomBed);
  }

  onclickMinusUnRoomBed(index) {
    this.Unroombedarray.splice(index);
  }
  //
  ///

  //////////
  //set location
formettedaddress1=" ";
options={
  componentRestrictions: {
     country: ['IN']
    }
  }

 public handleAddress(address: any) {
    this.formettedaddress1=address.formetted_address;
}

onsavehouseDetails() {

  if(this.HouseDetails.get('firstName').invalid == true){
    alert('Please enter name')
  }
  else if(this.HouseDetails.get('email').invalid == true){
    alert('Please enter email')
  }
  else if(this.HouseDetails.get('phoneNo').invalid == true){
    alert('Please enter phone no.')
  }
  else{ 
   this.houses_service.saveHousesDetails(this.HouseDetails.value).subscribe (
     (res) => {
        if(res.firstName != null && res.lastName != null && res.phoneNo != null){
          this.IsSaved = true;
          alert('New house saved successfully');
          this.router.navigateByUrl('/Houses')
        }    
 
     }
   )
    }
}





}

  