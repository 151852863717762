<div class="row">
  <!--1st part Image bottom-->
  <div class="col-md-8 slide-1" style="padding-left: 9%;">
    <div id="demo" class="carousel slide" data-ride="carousel">
      <ul class="carousel-indicators">
        <li data-target="#demo" data-slide-to="0" class="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
        <li data-target="#demo" data-slide-to="2"></li>
      </ul>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="100%" height="400px" />

          <div class="carousel-caption">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
            <h1>3 BHK APARTMENT 2 MILES AWAY</h1>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <img mat-card-image src="assets/img/room2.jpg" alt="image1" width="100%" height="400px" />
          <div class="carousel-caption">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
            <h1>3 BHK APARTMENT 2 MILES AWAY</h1>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <img mat-card-image src="assets/img/room3.jpg" alt="image1" width="100%" height="400px" />
          <div class="carousel-caption">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
            <h1>3 BHK APARTMENT 2 MILES AWAY</h1>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#demo" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#demo" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>

    <mat-card style="margin-top: -16px;">
      <mat-card-content>
        <ul class="dot-2">
          <li>
            <div class="dot"></div>
          </li>
          <li>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
    <br />

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <h1 class="heading-a">AMENTILES</h1>
          <mat-tab-group>
            <mat-tab label="LIVING ROOM">
              <div class="row" style="padding: 35px 0px 72px;">
                  <ul style="padding: 0% 11% 0% 11%;">
                    <li>Sofa</li>
                    <li>Television</li>
                    <li>Wash Machine</li>
                  </ul>
                  <ul style="padding: 0% 11% 0% 11%;" >
                    <li>Wi-Fi</li>
                    <li>Dishtv</li>
                    <li>Bed</li>
                  </ul>
              </div>
            </mat-tab>

            <mat-tab label="KITCHEN">
              <p style="padding: 10px; text-align: center;">Kitchen</p>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div class="col-md-6 card-2">
        <h1 class="heading-a">NEIGHBORHOOD</h1>
        <mat-tab-group>
          <mat-tab label="CATEGORY-1">
            <div class="row card-a" style="padding: 35px 0px 10px;">
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
            </div>
          </mat-tab>

          <mat-tab label="CATEGORY-2">
            <div class="row card-a" style="padding: 35px 0px 72px;">
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
            </div>
          </mat-tab>

          <mat-tab label="CATEGORY-3">
            <div class="row card-a" style="padding: 35px 0px 72px;">
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
              <ul style="padding: 0% 3% 3% 10%">
                <li>Place-1</li>
                <li>Place-2</li>
                <li>Place-3</li>
              </ul>
            </div>
          </mat-tab>
        </mat-tab-group>

        <h3 class="explore-map">
          <i class="fas fa-angle-right" style="font-size: 14px;"></i> EXPLORE ON
          MAP
        </h3>
      </div>
    </div>
    <br />

    <mat-card class="house-details">
      <h1 class="heading-b">HOUSE DETAILS</h1>
      <span class="details">
        <p>HOUSE TYPE</p>
        <h3>Apartment</h3>
      </span>
      <span class="details">
        <p>HOUSE ID</p>
        <h3>04554D</h3>
      </span>
      <span class="details">
        <p>FOOD PREFERENCE</p>
        <h3>None</h3>
      </span>
      <br />
      <span class="details">
        <p>HOUSE AREA</p>
        <h3>1000 sq ft.</h3>
      </span>
      <span class="details" style="width: 44%;">
        <p>RENT PAYMENT</p>
        <h3>Before Every 5 months</h3>
      </span>

      <br />
      <hr style="background: aqua;" />

      <div class="house-rules">
        <h1 class="heading-c">HOUSE RULES</h1>
        <ul>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
          <li>
            Lorem ipsum is a popular form of dummy text used in the publishing
            industry.
          </li>
        </ul>
      </div>
    </mat-card>
  </div>
  <!--1st part Image bottom END-->

  <!--2nd part plan a visit part and bottom -->
  <div class="col-md-4 slide-2" style="padding-right: 5%;">
    <div class="visit-and-bookNow-card">
      <mat-tab-group>
        <mat-tab label="PLAN A VISIT">
          <div class="visit-text">
            <p>
              Please enter the OTP Sent to your phon (56******89) to Schedule
              your visit.
            </p>

            <mat-form-field class="example-full-width">
              <mat-label>ENTER OTP</mat-label>
              <input matInput placeholder="Enter OTP" />
            </mat-form-field>
            <button class="btn-back">BACK</button>
            <button class="btn-visit">PLAN VISIT</button>
          </div>
        </mat-tab>

        <mat-tab label="BOOK NOW">
          <p style="margin-top: 30px;">
            <mat-form-field appearance="legacy">
              <mat-label>FULL NAME</mat-label>
              <input matInput placeholder="Placeholder">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="legacy">
              <mat-label>PHONE & EMAIL</mat-label>
              <input matInput placeholder="Placeholder">
            </mat-form-field>
          </p>
          <p class="date-pic">
            <mat-form-field appearance="fill">
              <mat-label>PIC A DATE</mat-label>
                <input matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="legacy">
              <mat-label>PICK A TIME</mat-label>
              <input matInput placeholder="Placeholder">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="legacy">
              <mat-label>HAVE A REFRRAL CODE (Optional)</mat-label>
              <input matInput placeholder="Placeholder">
            </mat-form-field>
          </p>
          <p class="btn-plan">
            <button>PLAN VISIT</button>
          </p>
        </mat-tab>
      </mat-tab-group>
    </div>

    <br />
    <mat-card style="padding: 24px;" class="mat-1">
      <mat-card-content>
        <ul class="dot-2">
          <li>
            <div class="dot-1"></div>
          </li>
          <li>
            <p>
              10 Peoples Scheduled the same visit.
              <span class="book-now">BOOK NOW</span> to avoid missing out
            </p>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>

    <br />
    <mat-card class="keep-in-mind">
      <h1 class="heading-a">KEEP IN MIND</h1>
      <ul>
        <li>
          Lorem ipsum is a popular form of dummy text used in the publishing
          industry.
        </li>
        <li>
          Lorem ipsum is a popular form of dummy text used in the publishing
          industry.
        </li>
      </ul>
    </mat-card>
    <br />
    <mat-card class="shp-section">
      <div class="text-center">
        <div class="img-responsive" style="margin-top: 10px;">
          <img src="/assets/img/refer&ern.svg" alt="Refer &amp; earn" />
        </div>

        <div class="shpinr">1 SHP = 1 INR</div>
        <div class="shp">SHP= Stayhook Points</div>
        <br />
        <button class="login-now">LOGIN NOW</button>
      </div>
    </mat-card>
  </div>
  <!--2nd part plan a visit part and bottom  END-->
</div>

<br />

<!--Simillar House Part-->
<div class="similar-house" style="padding-left: 7%; margin-left: 10px; padding-right: 4%;">
  <h1 class="heading-d">SIMILAR HOUSE</h1>
  <div class="row">
    <div class="col-md-4">
      <mat-card class="example-card">
        <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="379px" height="300px" />
        <div class="text-block-1">
          <p class="room-rent">RENT/MONTH - SD</p>
          <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
        </div>
        <mat-card-content>
          <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
          <p style="margin-top: -13px;">
            1,200 Sq Ft. 3 Bathrooms. Fully Furnished
          </p>
          <span class="rating"> Rating</span>:
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-4">
      <mat-card class="example-card">
        <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="379px" height="300px" />
        <div class="text-block-1">
          <p class="room-rent">RENT/MONTH - SD</p>
          <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
        </div>
        <mat-card-content>
          <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
          <p style="margin-top: -13px;">
            1,200 Sq Ft. 3 Bathrooms. Fully Furnished
          </p>
          <span class="rating"> Rating</span>:
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-4">
      <mat-card class="example-card">
        <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="379px" height="300px" />
        <div class="text-block-1">
          <p class="room-rent">RENT/MONTH - SD</p>
          <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
        </div>
        <mat-card-content>
          <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
          <p style="margin-top: -13px;">
            1,200 Sq Ft. 3 Bathrooms. Fully Furnished
          </p>
          <span class="rating"> Rating</span>:
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>