<div class="container-fluid" style="padding: 5% 12% 5% 12%;">
  <div class="col-sm-12 pd-lf">
    <div class="dash-panel-head">
      <span class="pull-right margin-10">
        <a
          class="btn active btn-default btn-create"
          [routerLink]="['/NewAmenity']"
          routerLinkActive="router-link-active"
          >New Amenity</a
        >
      </span>
    </div>

    <!-- panel panel-default -->
    <div class="panel panel-default panel-padding">
      <div class="panel-body no-padding">
        <div class="table-responsive">
          <table class="table table-bordered table-striped all-user-table">
            <thead>
              <tr>
                <th class="thead">ID</th>
                <th class="thead">Name</th>
                <th class="thead">For Living Room</th>
                <th class="thead">Created At</th>
                <th class="thead">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr id="user_6" *ngFor = "let data of amenities">
                <td class="thead">
                  {{data.id}}
                </td>
                <td class="thead">{{data.name}}</td>
                <td class="thead">{{data.dorLivingRoom}}</td>
                <td class="thead">18/09/2019</td>
                <td class="thead">
                  <a style="cursor: pointer;" (click)= "onSelect(data.id)" >Edit</a>
                     |
                  <a 
                    data-confirm="Are you sure you want to delete amenity?"
                    class="amenity_delete"
                    id="amenity"
                    href="javascript:"
                    (click) = "onDeleteAmenities(data.id)"
                  >
                    <i class="fa fa-trash" ></i>
                  </a>
                </td>
              </tr>
              <!--<tr id="user_5">
                <td class="thead">
                  <a href="">5</a>
                </td>
                <td class="thead">Washing Machine</td>
                <td class="thead">Yes</td>
                <td class="thead">18/09/2019</td>
              </tr>
              <tr id="user_4">
                <td class="thead">
                  <a href="">4</a>
                </td>
                <td class="thead">Gym</td>
                <td class="thead">Yes</td>
                <td class="thead">18/09/2019</td>
              </tr>
              <tr id="user_3">
                <td class="thead">
                  <a href="">3</a>
                </td>
                <td class="thead">Parking</td>
                <td class="thead">Yes</td>
                <td class="thead">18/09/2019</td>
              </tr>
              <tr id="user_2">
                <td class="thead">
                  <a href="">2</a>
                </td>
                <td class="thead">Wifi</td>
                <td class="thead">Yes</td>
                <td class="thead">18/09/2019</td>
              </tr>
              <tr id="user_1">
                <td class="thead">
                  <a href="">1</a>
                </td>
                <td class="thead">Sofa</td>
                <td class="thead">Yes</td>
                <td class="thead">18/09/2019</td>
              </tr>-->
            </tbody>
          </table>
          <div class="apple_pagination"></div>
        </div>
      </div>
    </div>
    <!-- End panel panel-default -->
  </div>
</div>
