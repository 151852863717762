import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-move-out',
  templateUrl: './move-out.component.html',
  styleUrls: ['./move-out.component.scss']
})
export class MoveOutComponent implements OnInit {
  
  disabled= true;
  constructor() { }

  ngOnInit(): void {
    
  }
    hide1:boolean=true;
    hide2:boolean=false;
    hide3:boolean=false;



  onClick1(){
    this.hide1=false;
    this.hide2=true;
    this.hide3=false;
  }
  onClick2(){
    this.hide1=false;
    this.hide2=false;
    this.hide3=true;
  }
  disabled1(){
    this.disabled=false;
  }
}
