<div class="container mt-4">
    <mat-tab-group>
        <mat-tab label="Home">
            <div class="row section1 mr-3 ml-3" style="margin-top: 30px;">
                <div class="col-md-8 subsection1">
                    <h1><b>Welcome, Rony !</b></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                </div>
                <div class="col-md-4 mat-card border subsection2">
                    <p><b>485</b> peoples visited<br> from last week</p>
                </div>
            </div>
            <div class="row mr-3 ml-3" style="margin-top: 10px;">
                <div class="col-md-8 pr-3 pl-3">
                    <div class="row" style="margin-right: 0rem;">
                        <div class="col-md-6 p-0 m-0">
                            <div class="border text-center bgl p-5 m-2">
                                <img class="sec1" src="/assets/img/Group 33.png" alt="">
                                <p class="p-3"><b>SHARED ROOM</b></p>
                                <p class="p4">Day or night, we are here for you.Talk to 
                                    our support team.
                                </p>
                                <p class="btn">VIEW DETAILS</p>

                            </div>
                        </div>
                        <div class="col-md-6 p-0 m-0">
                            <div class=" border text-center bgl p-5 m-2">
                                <img class="sec2" src="/assets/img/opened-door-aperture.png" alt="">
                                <p class="p-3"><b>PRIVATE ROOM</b></p>
                                <p class="p4">Day or night, we are here for you.Talk to 
                                    our support team.</p>
                                <p class="btn">VIEW DETAILS</p>
    
                            </div>
                        </div>
                        <div class="col-md-6 p-0 m-0">
                            <div class="border text-center bgl p-5 m-2 fh">
                                <img class="sec3" src="/assets/img/Group 1413.png" alt="">
                                <p class="p-3"><b>FULL HOUSE</b></p>
                                <p class="btn">VIEW DETAILS</p>
                            </div>
                        </div>
                        
                        <div class="col-md-6 p-0 m-0">
                            <div class="border text-center bgl p-5 m-2 mh">
                                <img class="sec4" src="/assets/img/Group 1416.png" alt="">
                                <p class="p-3"><b>MY HOUSE</b></p>
                                <p class="btn">VIEW DETAILS</p>
                              
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-md-4 mt-2 p-0 m-0 border mat-card" style="max-height: 470px;">
                    <mat-toolbar>
                        <mat-toolbar-row>
                            <span class="ptm"><b>PAYMENTS</b></span>
                            <span class="example-spacer"></span>
                            <span class="material-icons view1">
                                keyboard_arrow_right
                            </span>
                            <span class="view">
                                <b>VIEW DETAILS</b>
                            </span>
                        </mat-toolbar-row>
                    </mat-toolbar>
                    <div class="list-scr">
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">S</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Shared Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-2">
                                <span>17,000/-</span><br>
                                <span style="color: red;">Pending</span>
                            </li>
                        </ul>
                        <ul class="list-1">
                            <li style="margin-top: 10px;">
                                <span class="ico">P</span>
                            </li>
                            <li style="margin-left: 10px;">
                                <span style="font-weight: 700;">Private Rooms</span>
                                <span style="margin-left: 6px;">House Rent</span><br>
                                <span>House ID #4547454</span>
                            </li>
                            <li class="pay-1">
                                <span>17,000/-</span><br>
                                <span style="color: green;">Successful</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="SHAERD ROOM">
            <div class="row section1 mr-3 ml-3" style="margin-top: 30px;">
                <div class="col-md-8 subsection1">
                    <h1><b>Shared Room</b></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                </div>
                <div class="col-md-4 mat-card border subsection2">
                    <p><b>485</b> peoples visited<br> from last week</p>
                </div>
            </div>
            <div class="row section1 mr-3 ml-3" style="margin-top: 10px;">
                <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
                    <mat-tab label="Shared Room">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs. 4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs. 2000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 p-0 m-0">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs. 4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs. 4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs. 2000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Private Room">
                        <div class="row mr-5 ml-5 p-5">
                            <div class="col-md-12 p-0 m-0">
                                <div class="row mr-5 ml-5 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.2000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="House">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd text-center">
                                <img src="../../../assets/img/Group 1603.png" width="80px" class="p-3 m-3"
                                    alt="profile">
                                <h3><b>SURAJ KUMAR</b></h3>
                                <h5 class="address"><span class="material-icons icc">
                                        location_on
                                    </span>Laxmi Nagar ,New Delhi</h5>
                                <h5 class="mail"><b><span class="material-icons icc">
                                            mail
                                        </span>srjmr@gmail.com</b></h5>
                                <p class="details"><a><span class="material-icons icc">
                                            arrow_forward_ios
                                        </span><b>VIEW DOCUMENTS</b></a></p>
                            </div>
                            <div class="col-md-6 p-0 m-0 text-center">
                                <img src="/assets/img/Group 1413.png" width="150px" alt="">
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
        <mat-tab label="PRIVATE ROOM">
            <div class="row section1 mr-3 ml-3" style="margin-top: 30px;">
                <div class="col-md-8 subsection1">
                    <h1><b>Shared Room</b></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                </div>
                <div class="col-md-4 mat-card border subsection2">
                    <p><b>485</b> peoples visited<br> from last week</p>
                </div>
            </div>
            <div class="row section1 mr-3 ml-3" style="margin-top: 10px;">
                <mat-tab-group [(selectedIndex)]="demo1TabIndex" mat-stretch-tabs
                    class="example-stretched-tabs mat-elevation-z4">
                    <mat-tab label="Shared Room">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 p-0 m-0">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Private Room">
                        <div class="row mr-5 ml-5 p-5">
                            <div class="col-md-12 p-0 m-0">
                                <div class="row mr-5 ml-5 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Rs.4000</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="House">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd text-center">
                                <img src="../../../assets/img/Group 1603.png" width="80px" class="p-3 m-3"
                                    alt="profile">
                                <h3><b>SURAJ KUMAR</b></h3>
                                <h5 class="address"><span class="material-icons icc">
                                        location_on
                                    </span>Laxmi Nagar ,New Delhi</h5>
                                <h5 class="mail"><b><span class="material-icons icc">
                                            mail
                                        </span>srjmr@gmail.com</b></h5>
                                <p class="details"><a><span class="material-icons icc">
                                            arrow_forward_ios
                                        </span><b>VIEW DOCUMENTS</b></a></p>
                            </div>
                            <div class="col-md-6 p-0 m-0 text-center">
                                <img src="/assets/img/Group 1413.png" width="150px" alt="">
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
        <mat-tab label="FULL HOUSE">
            <div class="row section1 mr-3 ml-3" style="margin-top: 30px;">
                <div class="col-md-8 subsection1">
                    <h1><b>Shared Room</b></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                </div>
                <div class="col-md-4 mat-card border subsection2">
                    <p><b>485</b> peoples visited<br> from last week</p>
                </div>
            </div>
            <div class="row section1 mr-3 ml-3" style="margin-top: 10px;">
                <mat-tab-group [(selectedIndex)]="demo2TabIndex" mat-stretch-tabs
                    class="example-stretched-tabs mat-elevation-z4">
                    <mat-tab label="Shared Room">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 p-0 m-0">
                                <div class="row m-0 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Bookin Bed.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Private Room">
                        <div class="row mr-5 ml-5 p-5">
                            <div class="col-md-12 p-0 m-0">
                                <div class="row mr-5 ml-5 p-3">
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 1</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 2</div>
                                    <div class="col-4 mt-2 mb-3 text-center">BEDROOM 3</div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                    <div class="col-4 p-3 text-center">
                                        <img src="/assets/img/Booking Room.png" width="30px" alt="bed">
                                        <br><a style="text-decoration: none; color: black;"
                                            (click)="openDialog()">Sold Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="House">
                        <div class="row m-0 p-5">
                            <div class="col-md-6 p-0 m-0 fd text-center">
                                <img src="../../../assets/img/Group 1603.png" width="80px" class="p-3 m-3"
                                    alt="profile">
                                <h3><b>SURAJ KUMAR</b></h3>
                                <h5 class="address"><span class="material-icons icc">
                                        location_on
                                    </span>Laxmi Nagar ,New Delhi</h5>
                                <h5 class="mail"><b><span class="material-icons icc">
                                            mail
                                        </span>srjmr@gmail.com</b></h5>
                                <p class="details"><a><span class="material-icons icc">
                                            arrow_forward_ios
                                        </span><b>VIEW DOCUMENTS</b></a></p>
                            </div>
                            <div class="col-md-6 p-0 m-0 text-center">
                                <img src="/assets/img/Group 1413.png" width="150px" alt="">
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
        <mat-tab label="MY HOUSE">
            <div class="row section1 mr-3 ml-3" style="margin-top: 30px;">
                <div class="col-md-8 subsection1">
                    <h1><b>My House</b> <span>LAST UPDATED ON 12/04/2019</span></h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                </div>
                <div class="col-md-4 subsection-2">
                    <button> + ADD ANOTHER CONTACT</button>
                </div>
            </div>
            <div class="row section1 mr-3 ml-3">
                <div class="col-md-7">
                    <img src="assets/img/vendor-my-house.jpg" class="img-1" alt="">
                </div>
                <div class="col-md-5">
                    <img src="assets/img/vendor-my-house-1.jpg" class="img-2" alt="">
                </div>
            </div>
            <div class="row section1 mr-3 ml-3" style="margin-top: 25px;">
                <div class="col-md-3">
                    <img src="assets/img/vendor-my-house-2.jpg" class="img-3" alt="">
                </div>
                <div class="col-md-3">
                    <img src="assets/img/vendor-my-house-3.jpg" class="img-3" alt="">
                </div>
                <div class="col-md-3">
                    <img src="assets/img/vendor-my-house-4.jpg" class="img-3" alt="">
                </div>
                <div class="col-md-3">
                    <img src="assets/img/vendor-my-house-5.jpg" class="img-3" alt="" width="245px" height="200px">
                </div>
            </div>
        </mat-tab>
        <mat-tab label="PAYMENTS">
            <div class="payment-list" style="margin-top: 30px;">
                <div class="col-md-10 offset-md-1 mat-card border subsection2">
                    <p><b>485</b> peoples visited<br> from last week</p>
                </div>
                <div class="col-md-10 offset-md-1 mat-card subsection3" style="margin-top: 20px;">
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                    <ul class="content-1">
                        <li>
                            <img src="../../../assets/img/Group 1603.png" width="50px" alt="profile">
                        </li>
                        <li>
                            <span>SURAJ KUMAR</span><br>
                            <span>House ID #45475465</span>
                        </li>
                        <li class="visit-date">
                            <span style="float: right;" class="material-icons"> more_horiz</span><br>
                            <span style="float: right;">visited on 12/12/2018</span>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="RENT PAYMENTS"></mat-tab>
        <mat-tab label="SETTINGS"></mat-tab>
    </mat-tab-group>


</div>
