<section class="py-5">
  <div class="container">
    <div class="row text">
      <div class="col-md-3">
        <img src="assets/img/Logo_SH_Grey.png" alt="" />
      </div>
      <div class="col-md-2">
        <h4><b>CONTACT</b></h4>
        <ul class="list-unstyled mt-3">
          <li><a href="#">Traning</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Official Blog</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Privacy Policy</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Guest Policy</a></li>
        </ul>
      </div>
      <div class="col-md-2">
        <h4><b>ABOUT</b></h4>
        <ul class="list-unstyled mt-3">
          <li><a href="#">About StayHook</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Pricing</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Media kit</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Jobs</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Refer</a></li>
        </ul>
      </div>
      <div class="col-md-2">
        <h4><b>RESOURCES</b></h4>
        <ul class="list-unstyled mt-3">
          <li><a href="#">Download</a></li>
          <div class="mb-2"></div>
          <li><a href="#">About Us</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Team/Careers</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Blog</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Supports</a></li>
          <div class="mb-2"></div>
          <li><a href="#">official Blog</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Press Kit</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Privacy Policy</a></li>
          <div class="mb-2"></div>
          <li><a href="#">Guest Policy</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <h4>GET LATEST NEWS UPDATES ABOUT OFFER AND MORE</h4>
        <ul class="list-unstyled mt-3">
          <li>
            <div class="input-group mb-3">
              <input
                type="mail"
                class="form-control"
                placeholder="Enter Email"
              />
              <div class="input-group-append">
                <button type="submit" class="brt">
                  <span class="material-icons icon">
                    keyboard_arrow_right
                  </span>
                </button>
              </div>
            </div>
          </li>
          <div class="mt-5"></div>
          <li>
            <a href="#"><b style="color: #000;">CONNECT WITH US</b></a>
          </li>
          <div class="mb-2"></div>
          <li>
            <ul class="list-unstyled list-inline social mt-3">
              <li class="list-inline-item">
                <a><i class="fa fa-facebook"></i></a>
              </li>
              <li class="list-inline-item">
                <a><i class="fa fa-twitter"></i></a>
              </li>
              <li class="list-inline-item">
                <a><i class="fa fa-instagram"></i></a>
              </li>
              <li class="list-inline-item">
                <a><i class="fa fa-envelope"></i></a>
              </li>
            </ul>
          </li>
          <div class="mt-3"></div>
          <li>
            <p class="legal">@StayHook Inc. All rights reserved</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
