<div id="container">
    <div class="alert alert-info">Signed in successfully.</div>
  </div>
  
  <div class="container-fluid fluid">
    <mat-tab-group>
      <mat-tab label="Shared Room">
        <div class="panel panel-default panel-with-select panel-padding">
          <div class="panel-body no-padding">
            <div class="table-responsive">
              <table class="table table-bordered table-striped all-user-table">
                <thead>
                  <a href="/AdminHome" *ngFor = "let user of fData">{{user.name}}</a>
                  <tr>
                    <th class="thead">Name</th>
                    <th class="thead">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor = "let user1 of fData1">
                    <td class="thead">
                      {{user1.name}}
                    </td>
                    <td class="thead">{{user1.status}}</td>
                    <td class="thead" *ngIf="user1.status==='book'"><mat-icon>home</mat-icon></td>
                    <td class="thead" *ngIf="user1.status==='empty'"><mat-icon class="icon-display">home</mat-icon></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      </mat-tab>
      <mat-tab label="Private Room">
          <div class="panel panel-default panel-with-select panel-padding">
            <div class="panel-body no-padding">
              <div class="table-responsive">
                <table class="table table-bordered table-striped all-user-table">
                  <thead>
                    <a href="/AdminHome" *ngFor = "let user of fData">{{user.name}}</a>
                    <tr>
                      <th class="thead">Name</th>
                      <th class="thead">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let user2 of fData2">
                      <td class="thead">
                        {{user2.name}}
                      </td>
                      <td class="thead">{{user2.status}}</td>
                      <td class="thead" *ngIf="user2.status==='book'"><mat-icon>home</mat-icon></td>
                      <td class="thead" *ngIf="user2.status==='empty'"><mat-icon class="icon-display">home</mat-icon></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </mat-tab>
      <mat-tab label="Full House">
          <div class="panel panel-default panel-with-select panel-padding">
            <div class="panel-body no-padding">
              <div class="table-responsive">
                <table class="table table-bordered table-striped all-user-table">
                  <thead>
                    <a href="/AdminHome" *ngFor = "let user of fData">{{user.name}}</a>
                    <tr>
                      <th class="thead">Name</th>
                      <th class="thead">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let user3 of fData3">
                      <td class="thead">
                       {{user3.name}}
                      </td>
                      <td class="thead">{{user3.status}}</td>
                      <td class="thead" *ngIf="user3.status==='book'"><mat-icon>home</mat-icon></td>
                      <td class="thead" *ngIf="user3.status==='empty'"><mat-icon class="icon-display">home</mat-icon></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>
</div>
<br /><br />