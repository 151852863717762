<div class="row navbar-1">
  <div class="col-md-3">
    <ul class="list-view">
      <li>Home</li>
      <li>
        <span class="material-icons">
          keyboard_arrow_right
        </span>
      </li>
      <li>Search</li>
      <li>
        <span class="material-icons">
          keyboard_arrow_right
        </span>
      </li>
      <li>Search</li>
    </ul>
  </div>
  <div class="col-md-4">
    <p>Found 6 result of your search</p>
  </div>
  <div class="col-md-5">
    <ul class="list-view-2">
      <li>
        <mat-form-field class="example-full-width">
          <mat-select>
            <mat-option value="delhi">Delhi</mat-option>
            <mat-option value="mumbai">MUMBAI</mat-option>
            <mat-option value="lucknow">LUCKNOW</mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class="right-line"></li>
      <li>
        <mat-form-field class="example-full-width" floatLabel="never">
          <mat-select>
            <mat-option value="1">I'm a Boy</mat-option>
            <mat-option value="2">I'm a Girl</mat-option>
            <mat-option value="3">We are family</mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li class="right-line"></li>
      <li>
        <mat-form-field class="example-full-width" floatLabel="never">
          <mat-select>
            <mat-option value="1">SHARED ROOM</mat-option>
            <mat-option value="2">PRIVATE ROOM</mat-option>
            <mat-option value="3">FULL HOUSE</mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ul>
  </div>
</div>
<br />


<div class="row">
  <div class="col-md-4" class="first-location loc-1" style="padding-left:9%; max-width: 35.333333%;">
    <mat-card>
      <span>Location</span>
      <button class="location-button"><i class='fas fa-map-marker-alt' style='font-size: 15px;
        margin-right: 13px;'></i>Laxmi Nagar</button>
    </mat-card>

    <mat-card class="accomdation-card">
      <div class="accomdation">ACCOMDATION FOR</div>
      <mat-button-toggle-group class="toggle-2" name="fontStyle" aria-label="Font Style" style="margin-top: 10px;">
        <mat-button-toggle value="1">BOYS</mat-button-toggle>
        <mat-button-toggle value="2">GIRLS</mat-button-toggle>
        <mat-button-toggle value="3">FAMILY</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card>

    <mat-card class="price-card">
      <div class="price">Price</div>
      <ng5-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ng5-slider>
    </mat-card>


    <mat-card class="bedroom-card">
      <div class="bedroom">BEDROOMS</div>
      <mat-button-toggle-group class="toggle-1" name="fontStyle" aria-label="Font Style" style="margin-top: 10px;">
        <mat-button-toggle class="bedroom-btn" value="1">1 BHK</mat-button-toggle>
        <mat-button-toggle class="bedroom-btn" value="2">2 BHK</mat-button-toggle>
        <mat-button-toggle class="bedroom-btn" value="3">3 BHK</mat-button-toggle>
        <mat-button-toggle class="bedroom-btn" value="2">ALL BHK</mat-button-toggle>
        <mat-button-toggle class="bedroom-btn" value="3">MOST RATED</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card>

    <mat-card class="condition-card">
      <div class="condition">CONDITIONS</div>
      <mat-button-toggle-group class="toggle-1" name="fontStyle" aria-label="Font Style" style="margin-top: 10px;">
        <mat-button-toggle class="condition-btn" value="1">FULLY FURNISHED</mat-button-toggle>
        <mat-button-toggle class="condition-btn" value="2">SEMI FURNISHED</mat-button-toggle>
        <mat-button-toggle class="condition-btn" value="3">UNFURNISHED</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card>

    <mat-card class="htype-card">
      <div class="htype">HOUSE TYPE</div>
      <mat-button-toggle-group class="toggle-2" name="fontStyle" aria-label="Font Style" style="margin-top: 10px;">
        <mat-button-toggle value="1">APARTMENT</mat-button-toggle>
        <mat-button-toggle value="2">INDEPENDENT</mat-button-toggle>
        <mat-button-toggle value="3">VILLA</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card>

  </div>

  <div class="col-md-8"class="first-location first-1" style="padding-right: 0%; max-width: 61.666667%;">
    <div class="row">
      <div class="col-md-6 margin-mot">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="379px" height="300px" />
          <div class="text-block-1">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room2.jpg" alt="image2" width="379px" height="300px" />
          <div class="text-block-2">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6 margin-mot">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room3.jpg" alt="image1" width="379px" height="300px" />
          <div class="text-block-2">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room4.jpg" alt="image2" width="379px" height="300px" />
          <div class="text-block-1">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6 margin-mot">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room1.jpg" alt="image1" width="379px" height="300px" />
          <div class="text-block-1">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room2.jpg" alt="image2" width="379px" height="300px" />
          <div class="text-block-2">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-md-6 margin-mot">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room3.jpg" alt="image1" width="379px" height="300px" />
          <div class="text-block-2">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <mat-card class="example-card" (click)="onclickRoomDetatils()">
          <img mat-card-image src="assets/img/room4.jpg" alt="image2" width="379px" height="300px" />
          <div class="text-block-1">
            <p class="room-rent">RENT/MONTH - SD</p>
            <h4 class="room-price">Rs. 4,000 . Rs. 10,000</h4>
          </div>
          <mat-card-content>
            <h3>3 BHK APARTMENT 2 MILES AWAY</h3>
            <p style="margin-top: -13px;">
              1,200 Sq Ft. 3 Bathrooms. Fully Furnished
            </p>
            <span class="rating"> Rating</span>:
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>