<div class="container">
    <div class="row">
        <div class="col-md-6">
            <img class="img1" src="/assets/img/refer&ern.svg" alt="Refer &amp; earn" />
            <h3 class="ref"> REFER & EARN </h3>
            <P>Invite your friends to StayHook via your invite code and your friend will
                get a coupon worth Rs.500. What's even better? Once they confirm a booking 
                via StayHook, even you will get a coupon worth Rs.500!
            </P>
            <hr class="new1">
            <div class="text-center">    
                <div class="img-responsive" style="margin-top: 10px;">
                    <!-- <img src="/assets/img/refer&ern.svg" alt="Refer &amp; earn" /> -->
                </div>
                <div class="shpinr">1 SHP = 1 INR</div>
                <div class="shp">SHP= Stayhook Points</div>
            </div>     
        </div>
        <div class="col-md-6">
            <div class="coupon">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="">
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy"></i>
                    </li>
                </ul>
                <hr>
            </div>
            <div class="coupon" style="margin-top: 15px;">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="" >
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy" ></i>
                    </li>
                </ul>
                <hr>
            </div>
            <div class="coupon" style="margin-top: 15px;">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="" >
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy"></i>
                    </li>
                </ul>
                <hr>
            </div>
            <div class="coupon" style="margin-top: 15px;">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="" >
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy"></i>
                    </li>
                </ul>
                <hr>
            </div>
            <div class="coupon" style="margin-top: 15px;">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="">
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy"></i>
                    </li>
                </ul>
                <hr>
            </div>
            <div class="coupon" style="margin-top: 15px;">
                <ul>
                    <li>
                        <img src="assets/img/coup.png" alt="">
                    </li>
                    <li class="text">
                    <p style="color: #483f3f;">By User | 12/04/2018</p>
                    <p>EARNED 500 SHP</p>
                    <span style="color: #483f3f;">Coupon Code:  </span>
                    <span style="color:black; font-weight: 900;"> 45ASD45</span>
                    <span style="margin-left: 15px;">Copy Code</span> <i class="far fa-copy"></i>
                    </li>
                </ul>
                <hr>
            </div>
            <!-- <img class="ref_img" src="/assets/img/home3.svg" alt="Shared rooms"> -->
            <div class="ref_f">
                <!-- <p>You don't have any coupon right now.</p> -->
                <p class="bld">It's like sharing cookies.Who doesn't love  cookies, right</p>
     
                <ul>
                    <li>
                        <p>YOUR INVITE CODE IS</p>
                    </li>
                    <li>
                        <button>8P7A
                            <i class="fas fa-share-alt" style="padding-left:15px"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
