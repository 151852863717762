import { Component, OnInit } from '@angular/core';
import { AmenitiesService } from '../amenities/amenities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-new-amenities',
  templateUrl: './new-amenities.component.html',
  styleUrls: ['./new-amenities.component.scss']
})
export class NewAmenitiesComponent implements OnInit {

  IsSaved = false;

  NewAmenitiesDetails : FormGroup

  constructor(private amenities_service: AmenitiesService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.NewAmenitiesDetails = this.fb.group({
      id: [''],
      name: [''],
      dorLivingRoom: ['']
    })
  }

  onsaveAmenitiesDetails(){
    console.log(this.NewAmenitiesDetails.value)
    this.amenities_service.saveAmenitiesDetails(this.NewAmenitiesDetails.value).subscribe(
       (res) => {
         console.log(res)
        //  console.log(res.id)
         if (res.name != null){
           this.IsSaved = true;
           alert('Amenity saved successfully');
           this.router.navigateByUrl('/Amenities')
         }
       }
    )

  }

}
