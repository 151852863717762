<div class="container">
    <div class="row">
        <p class=head>NOW TRANSFER RENT/ OTHER DUES DIRECTLY FROM YOUR BANK A/C</p> 
        
        <p>Dear Anurag,</p>
        <p class="via">We have recently introduce a alternative(and FREE) method of rent payment.It's based on virtual payment in which
            we allocate a unique number to which you add as payee in your bank-similar to how to ad payee when you need to send 
            money to someone. so you added once & then transfering your dues to it from your bank account. We shall automatically 
            detect your payment and update your dashboard within 24 hours max.
        </p>
        <p class="pay_sub">WHAT ARE THE ACCOUNT DETAILS ?</p>
        <p>Please add the folowing payee detail in your bank account</p>
        <div class="tbl" style="width: 100%;">
            <table>
            <tr>
              <th>BENFECIARY NAME</th> 
              <th>BENFECIARY ACCOUNT NUMBER</th>
              <th>IFSC CODE</th>
              <th>BANK NAME</th>
              <th>ACCOUNT TYPE (SAVING/CURRENT)</th>
            </tr>
            <tr>
              <td>SATYHOOK PVTLTD</td>
              <td>SA00098877HGGG</td>
              <td>SBIN0009167</td>
              <td>SBI BANK</td>
              <td>SAVING ACCOUNT</td>
            </tr>
            </table>
        </div>
        <p class="black">This account no is unique for you. Once you added this account as a payee, you need to keep your paying your dues to this account.</p>
        <p class="pay_sub">WHY DO THIS ?</p>
        <p class="via">We have recently introduce a alternative(and FREE) method of rent payment.It's based on virtual payment in which
            we allocate a unique number to which you add as payee in your bank-similar to how to ad payee when you need to send 
            money to someone. so you added once & then transfering your dues to it from your bank account. We shall automatically 
            detect your payment and update your dashboard within 24 hours max.
        </p>
        <p class="pay_sub">WILL IT WE FREE ALWAYS ?</p>
        <p class="via">We have recently introduce a alternative(and FREE) method of rent payment.It's based on virtual payment in which
            we allocate a unique number to which you add as payee in your bank-similar to how to ad payee when you need to send 
            money to someone. so you added once & then transfering your dues to it from your bank account. We shall automatically 
            detect your payment and update your dashboard within 24 hours max.
        </p>
        <p>Click here to read your detailed <a>FAQ</a> on this</p>
    </div>
</div>