<div class="row">
    <div class="col-md-12">
        <div>
            <input class="search" placeholder="FILTER SEARCH">
            <button class="but"><i class="fa fa-times" style="margin-left: -15px; font-size: 19px;"></i></button>
        </div>
        <div class="top"><span class="head">LOCATION</span><br>
            <input type="text" class="loc" placeholder="LOCATION">
            <button class="searchButton">Search</button>
        </div>
        
        <div class="top">
            <span class="head" style="margin-left: 195px;">ACCOMDATION FOR</span><br>
            <div class="row head">
                <div class="colum">
                    <label class="image-radio1">
                        <img class="imge" src="assets/img/b.png">
                        <input type="radio" name="img_radio" value="1" />
                        <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                    </label>
                    <p class="cata">Boy</p>
                </div>
                <div class="colum"> 
                    <label class="image-radio1">
                        <img class="imge" src="assets/img/a.png">
                        <input type="radio" name="img_radio" value="2" />
                        <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                    </label>
                    <p class="cata">Girl</p>
                </div> 
                <div class="colum">
                    <label class="image-radio1">
                        <img class="imge" src="assets/img/f.png">
                        <input type="radio" name="img_radio" value="3" />
                        <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                    </label>
                    <p class="cat">Family</p>
                </div>
            </div>
        </div>
        <div class="top">
            <span class="head" style="margin-left: 195px;">PRICE</span><br>
            <ng5-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ng5-slider>
        </div>
        <hr class="line">
        <div class="top">
            <span class="head" style="margin-left: 195px;">BEDROOMS</span><br>
            <div class="row head">
                <div class="colum">
                    <label class="bed-radio1">
                        <img class="imge" src="assets/img/bi.png">
                        <input type="radio" name="img_radio" value="1" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="cat">Shared</p>
                </div>
                <div class="colum">
                    <label class="bed-radio1">
                        <img class="imge" src="assets/img/p.png">
                        <input type="radio" name="img_radio" value="2" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="cat">Private</p>
                </div>
                <div class="colum">
                    <label class="bed-radio1">
                        <img class="imge" src="assets/img/ho.png">
                        <input type="radio" name="img_radio" value="3" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="cat">House</p>
                </div>
            </div>
        </div>
        <div class="top">
            <span class="head" style="margin-left: 195px;">CONDITION</span><br>
            <div class="row head">
                <div class="colum">
                    <label class="condition-radio1">
                        <img class="imges" src="assets/img/bed.jpg">
                        <input type="radio" name="condition_radio" value="1" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="caet cte">Boy</p>
                </div>
                <div class="colum">
                    <label class="condition-radio1">
                        <img class="imges" src="assets/img/bed.jpg">
                        <input type="radio" name="condition_radio" value="2" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="caet cte">Girl</p>
                </div>
                <div class="colum">
                    <label class="condition-radio1">
                        <img class="imges" src="assets/img/bed.jpg">
                        <input type="radio" name="condition_radio" value="3" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="caet cte">Family</p>
                </div>
            </div>
        </div>
        <hr class="line">
        <div class="top">
            <span class="head" style="margin-left: 195px;">HOUSE TYPE</span><br>
            <div class="row head">
                <div class="colum">
                    <label class="house-radio1">
                        <img class="imges" src="assets/img/h.jpg">
                        <input type="radio" name="condition_radio" value="1" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="cate ct">Apertment</p>
                </div>
                <div class="colum">
                    <label class="house-radio1">
                        <img class="imges" src="assets/img/h.jpg">
                        <input type="radio" name="condition_radio" value="2" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="ct">Independent House</p>
                </div>
                <div class="colum">
                    <label class="house-radio1">
                        <img class="imges" src="assets/img/h.jpg">
                        <input type="radio" name="condition_radio" value="2" />
                        <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <p class="cates cte">Villa</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <p class="heading">
        <span>RESULTS</span>
        <span><i class="fa fa-sliders marg"></i></span>
    </p>
    <div class="row cardrow">
        <div class="col-sm-4 cards">
            <div class="card">
                <img src="assets/img/bed.jpg" alt="Denim Jeans" style="width:100%">
                <div class="centered">
                    <span class="rent">RENT/MONTH</span>
                    <span class="rent">SD</span>
                    <p>
                        <span class="ret">Rs.4,000</span>
                        <span>.</span>
                        <span class="ret">Rs.10,000</span>
                    </p>
                    <div class="foot">
                        <p style="margin-bottom: .1rem">3BHK APERTMENT 2 MILES AWAY</p>
                        <p class="f">1,200 Sq. Ft. 3 Bathrooms Fully Furnished</p>
                        <p style="margin-right: 100px;"><button class="rat">Ratings</button>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 cards">
            <div class="card">
                <img src="assets/img/bed.jpg" alt="Denim Jeans" style="width:100%">
                <div class="centered">
                    <span class="rent">RENT/MONTH</span>
                    <span class="rent">SD</span>
                    <p>
                        <span class="ret">Rs.4,000</span>
                        <span>.</span>
                        <span class="ret">Rs.10,000</span>
                    </p>
                    <div class="foot">
                        <p style="margin-bottom: .1rem">3BHK APERTMENT 2 MILES AWAY</p>
                        <p class="f">1,200 Sq. Ft. 3 Bathrooms Fully Furnished</p>
                        <p style="margin-right: 100px;"><button class="rat">Ratings</button>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 cards">
            <div class="card">
                <img src="assets/img/bed.jpg" alt="Denim Jeans" style="width:100%">
                <div class="centered">
                    <span class="rent">RENT/MONTH</span>
                    <span class="rent">SD</span>
                    <p>
                        <span class="ret">Rs.4,000</span>
                        <span>.</span>
                        <span class="ret">Rs.10,000</span>
                    </p>
                    <div class="foot">
                        <p style="margin-bottom: .1rem">3BHK APERTMENT 2 MILES AWAY</p>
                        <p class="f">1,200 Sq. Ft. 3 Bathrooms Fully Furnished</p>
                        <p style="margin-right: 100px;"><button class="rat">Ratings</button>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>