import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from './users.service';
import { FormBuilder, FormControl, FormControlName, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users = [];

  public userId: any;

  public user_Id: any;

  UsersForm: FormGroup;

  userDetails: any;
  
  alert: Boolean = false;
  
   

  constructor(private users_service: UsersService, private router: Router, private route: ActivatedRoute, 
    private fb: FormBuilder) { 
    this.ongetUser()
    }

  ngOnInit(): void {

    // let id = parseInt(this.route.snapshot.paramMap.get('id'));
    // this.userId = id;
    this.UsersForm = this.fb.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      email: [''],
      phoneNo: [''],
      createdDate: [''],
      roleID: [''],
      role: [''],
      isVerrified: ['']
    })


  }

  onSelect(user_Id: any) {    
    // console.log(user_Id)
    this.router.navigate(['/userdetails', user_Id])
      }

  onDelete(userId) {
    console.log(userId)
    this.ondeleteuserDetails(userId)
  }    

  ongetUser() {
  //  console.log('hello')
   this.users_service.getUser().subscribe(
     (res) => {
      //  console.log(res)
       this.users = res
     }
   )
  }

  ondeleteuserDetails(userId) {
    // console.log(userId)
    if(confirm("Are you sure you want to delete user?")){
    this.users_service.deleteuserDetails(userId).subscribe(
      (res) => {
        //  console.log(userId)
        //  console.warn('Deleted', userId)
      }

    )
    }
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    // window.location.reload();
    alert('User deleted successfully')
    // this.router.navigateByUrl('/Users');
  }

  closeAlert() {
    this.alert = false
  }

  ongetuserbyEmail(){
   
    // this.users.filter(function(user) {
    //   if(user.email == "deepakgupta@gmail.com"){
    //     return console.log('hi')
    //   }
    // }

    // )

    
  
  }




}
