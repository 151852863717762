import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http'
import { AuthService } from './auth.service';
import { LoginComponent } from 'src/app/UI/login/login.component';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor( private injector : Injector) { }

  intercept(req, next){
    let authService = this.injector.get(AuthService)
    let token = localStorage.getItem('token')
    // console.log('kkk')
    let tokenizedReq = req.clone({
      setHeaders:{
        // Authorization:`Bearer ${authService.getToken()}`
        Authorization:`Bearer ${token}`

      }
    })
    return next.handle(tokenizedReq)
  }
}
