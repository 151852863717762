<mat-toolbar class="big-screen" style="background:inherit;">
    <mat-toolbar-row style="padding: 50px 0px;">
        <span class="wrap">
            <a routerLink="/home">
                <img src="/assets/img/Logo_SH_Grey.png" alt="logo">
            </a>
        </span>
        <span class="example-spacer"></span>
        <span class="wrap">
            <ul class="nav-part">
                <li [routerLink]="['/listYourHouse']" routerLinkActive="router-link-active" >LIST YOUR HOME</li>
                <li [routerLink]="['/refer']">REFER & EARN</li>
                <li>24x7 Call us:<strong>1800-758-00</strong></li>
                <li style="border-right:none;">More <i class="arrow down"></i></li>
            </ul>
        </span>
        <span class="example-spacer"></span>
        <span class="wrap">
            <button [routerLink]="['/register']" routerLinkActive="router-link-active" 
            *ngIf="!_authService.loggedIn()">REGISTER</button>
            <button [routerLink]="['/login']" routerLinkActive="router-link-active" 
            *ngIf="!_authService.loggedIn()">LOGIN</button>
            <a class="menu_nav"style="color: #484848;"rel="nofollow"*ngIf="_authService.loggedIn() && _authService.getLoggedInUserRoleType() == 'User'">{{getUserName()}}</a>
            <a class="menu_nav btn-sign"style="color: #484848;"rel="nofollow"data-method="delete"*ngIf="_authService.loggedIn() && _authService.getLoggedInUserRoleType() == 'User'"(click)="_authService.loggedOut()">Signout</a>
        </span>
      
      </mat-toolbar-row>
</mat-toolbar>

<!-- 
<div class="small-screen">
  <div class="col-md-12">
    <span class="wrap">
      <a routerLink="/home">
          <img src="/assets/img/Logo_SH_Grey.png" alt="logo">
      </a>
  </span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <span class="material-icons" style="font-size: 30px;">apps</span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <li class="navbar1" [routerLink]="['/listYourHouse']" routerLinkActive="router-link-active" >LIST YOUR HOME</li>
      </button>
      <button mat-menu-item>
        <li class="navbar1" [routerLink]="['/refer']">REFER & EARN</li>
      </button>
      <button mat-menu-item>
        <li class="navbar1">24x7 Call us:<strong>1800-758-00</strong></li>
      </button>
      <button mat-menu-item>
        <li class="navbar1" style="border-right:none;">More</li>
      </button>
      <button mat-menu-item style="margin: 0px 0px 13px;">
        <button class="btn1" [routerLink]="['/register']" routerLinkActive="router-link-active" 
            *ngIf="!_authService.loggedIn()">REGISTER</button>
      </button>
      <button mat-menu-item style="margin: 0px 0px 13px;">
        <button class="btn1" [routerLink]="['/login']" routerLinkActive="router-link-active" 
        *ngIf="!_authService.loggedIn()">LOGIN</button>
      </button>
    </mat-menu>
  </div>
</div> -->
<!-- <mat-toolbar class="big-screen" style="background: inherit;">
  <mat-toolbar-row style="padding: 50px 0px;">
    <span class="wrap">
      <a routerLink="/home">
        <img src="/assets/img/Logo_SH_Grey.png" alt="logo" />
      </a>
    </span>
    <span class="example-spacer"></span>
    <span class="wrap">
      <ul class="nav-part">
        <li
          [routerLink]="['/listYourHouse']"
          routerLinkActive="router-link-active"
        >
          LIST YOUR HOME
        </li>
        <li>REFER & EARN</li>
        <li>24x7 Call us:<strong>1800-758-00</strong></li>
        <li style="border-right: none;">More</li>
      </ul>
    </span>
    <span class="example-spacer"></span>
    <span class="wrap">
      <button
        [routerLink]="['/register']"
        routerLinkActive="router-link-active"
        *ngIf="!_authService.loggedIn()"
      >
        REGISTER
      </button>
      <button
        [routerLink]="['/login']"
        routerLinkActive="router-link-active"
        *ngIf="!_authService.loggedIn()"
      >
        LOGIN
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar> -->

<!-- <mat-toolbar class="small-screen" style="background: inherit;">
  <mat-toolbar-row style="padding: 50px 0px;">
    <span class="wrap">
      <a routerLink="/home">
        <img src="/assets/img/Logo_SH_Grey.png" alt="logo" />
      </a>
    </span>
    <span class="example-spacer"></span>
    <span class="wrap">
      <ul class="nav-part">
        <li
          [routerLink]="['/listYourHouse']"
          routerLinkActive="router-link-active"
        >
          HOME
        </li>
        <li
          [routerLink]="['/register']"
          routerLinkActive="router-link-active"
          *ngIf="!_authService.loggedIn()"
        >
          REGISTER
        </li>
        <li
          [routerLink]="['/login']"
          routerLinkActive="router-link-active"
          *ngIf="!_authService.loggedIn()"
        >
          LOGIN
        </li>
      </ul>
    </span>
  </mat-toolbar-row>
</mat-toolbar> -->


<div id="myNav" class="overlay">
  <a class="closebtn" (click)="closeNav()">&times;</a>
  <a href="#"><h1>StayHook</h1></a>
  <div class="overlay-content">
    <a [routerLink]="['/listYourHouse']" class="menu_nav active" routerLinkActive="router-link-active">LIST YOUR HOME</a>
    <a [routerLink]="['/refer']" class="menu_nav" routerLinkActive="router-link-active">REFER & EARN</a>
    <a href="#">24x7 Call us:<strong>1800-758-00</strong></a>
    <a href="#">More <span style="position: absolute; font-size: 21px;" class="material-icons">keyboard_arrow_down</span></a>
    <a  class="btn-change" [routerLink]="['/register']" routerLinkActive="router-link-active" 
      *ngIf="!_authService.loggedIn()">REGISTER</a>
    <a class="btn-sign" [routerLink]="['/login']" routerLinkActive="router-link-active" 
      *ngIf="!_authService.loggedIn()">LOGIN</a>
      <span>
        <ul class="new-user">
          <li class="dot"></li>
          <li>Hi , User!</li>
          <li class="arr-1"><span class="material-icons">keyboard_arrow_down</span></li>
        </ul>
      </span>
  </div>
</div>
<div class="menu-1">
  <span>
    <a href="#">
     <span class="menu-heading"> We are SatyBook </span>
     <p class="menu-text">Some text about company & it's services</p>
    </a></span>
  <span class="menu-btn" (click)="openNav()">&#9776;</span>
</div>