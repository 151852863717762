import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DialogboxComponent } from './dialogbox/dialogbox.component';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openDialog() {
    this.dialog.open(DialogboxComponent);
  }

  public demo1TabIndex = 1;
  public demo1BtnClick() {
    const tabCount = 1;
    this.demo1TabIndex = (this.demo1TabIndex + 1) % tabCount;
  }

  public demo2TabIndex = 2;
  public demo2BtnClick() {
    const tabCount = 1;
    this.demo2TabIndex = (this.demo2TabIndex + 1) % tabCount;
  }
}
