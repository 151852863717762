<div class="container-fluid" style="padding: 5% 0% 5% 0%;">
    <div class="container">
      <div class="card">
        <div class="card-header">Booking Details</div>
        <div class="panel-body show-com">
          <form [formGroup]= "NewBookingsDetails"
          autocomplete="off" class="form-horizontal" id="new_user">
            <input name="utf8" type="hidden" value="✓" /><input
              type="hidden"
              name="authenticity_token"
            />
            <div class="row form-group">
                <label class="control-label col-sm-3 col-md-2" for="user_first"
                  >CUSTOMER ID</label
                >
                <div class="col-sm-4 col-md-4">
                  <input
                    class="form-control"
                    type="text"
                    name="customerId"
                    formControlName="customerId"
                    id="customerId_id"
                  />
                </div>
              </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_first"
                >CUSTOMER NAME</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="customerName"
                  formControlName="customerName"
                  id="customerName_id"
                />
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_lastName"
                >HOUSE ADDRESS</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="houseAddress"
                  formControlName="houseAddress"
                  id="houseAddress_id"
                />
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_username"
                >BOOKING FOR</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="bookingFor"
                  formControlName="bookingFor"
                  id="bookingFor_id"
                />
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_email"
                >PAYMENT STATUS</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="paymentStatus"
                  formControlName="paymentStatus"
                  id="paymentStatus_id"
                />
                <span class="error"></span>
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_phone"
                >AMOUNT</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="amount"
                  formControlName="amount"
                  id="amount_id"
                />
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_role"
                >BOOKING ACTION</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="bookingAction"
                  formControlName="bookingAction"
                  id="bookingAction_id"
                />
              </div>
            </div>
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_verified"
                >BOOKING DESCRIPTION</label
              >
              <div class="col-sm-4 col-md-4">
                <input name="verified" type="hidden" value="0" /><input
                class="form-control"
                  type="text"
                  name="bookingDescription"
                  formControlName="bookingDescription"
                  id="bookingDescription_id"
                />
              </div>
            </div>
            <div class="row form-group btn-top">
              <div class=" col-sm-3  col-md-2"></div>
              <div class=" col-sm-9  col-md-10">
                <div class="row">
                  <div class="col-sm-2">
                    <input
                      type="submit"
                      name="commit"
                      value="Edit"
                      class="btn active btn-system btn-block btn_comty"
                      (click)= "oneditBookingDetails()"
                    />
                  </div>
                  <div class="col-sm-2">
                    <a
                      class="btn active btn-default btn-block"
                      [routerLink]="['/Bookings']"
                      routerLinkActive="router-link-active"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  