import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-reminder',
  templateUrl: './payment-reminder.component.html',
  styleUrls: ['./payment-reminder.component.scss']
})
export class PaymentReminderComponent implements OnInit {

  constructor() { }
  filterData =[
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"5000","balanceDue":"1000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"4000","balanceDue":"1000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"6000","balanceDue":"2000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"5000","balanceDue":"1500"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"3000","balanceDue":"1000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"5000","balanceDue":"3000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"8000","balanceDue":"1000"},
    {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"5000","balanceDue":"2000"}
]

  ngOnInit(): void {
  }

}
