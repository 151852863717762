import { Component, OnInit } from '@angular/core';
import { BookingsService } from '../Admin/bookings/bookings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent implements OnInit {

  IsSaved = false;

  NewBookingsDetails: FormGroup;

  amount: any[];

  constructor(private bookings_service: BookingsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.NewBookingsDetails = this.fb.group({
      customerId: ['', Validators.required],
      customerName: ['', Validators.required],
      houseAddress: ['', Validators.required],
      bookingFor: [''],
      paymentStatus: [''],
      amount: 4000,
      bookingAction: [''],
      bookingDescription: ['']
    })
  }

  onsaveBookingsDetails(){
    // console.log(this.NewBookingsDetails.value)
    
    this.bookings_service.saveBookingsDetails(this.NewBookingsDetails.value).subscribe(
       res => {
        // console.log(res)
         if(res.customerId != null){
          this.IsSaved = true;
          alert('Booked successfully');
          this.router.navigateByUrl('/Bookings')
         }
      }
    )
  }

}
