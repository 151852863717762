<div class="row" *ngIf="showMsg">
  <div class="col-md-12">
    <p class="alert alert-success">
      <strong>Registration Success!</strong> Please click login button!.
    </p>
  </div>
</div>

<div class="logincard">
  <div class="container-fluid" style="margin: 0; padding: 0;">
    <div class="logincardone">
      <div class="img-responsive">
        <a href="/">
          <img
            class="img-responsive"
            width="130px"
            height="23px"
            src="/assets/img/Logo_SH_Grey.png"
            alt="logo"
          />
        </a>
      </div>
      <div class="singin">
        SIGNUP TO CONTINUE
      </div>
    </div>
    <div class="container-fluid" id="logininfo" style="margin: 0; padding: 0;">
      <div class="formsec2">
        <form [formGroup] = "registerUserData"
          #f="ngForm"
          class="sh-form"
          id="loginform"
          action="/users"
          accept-charset="UTF-8"
          method="post"
        >
          <input name="utf8" type="hidden" value="✓" /><input
            type="hidden"
            name="authenticity_token"
            value="lEffDSUGv2tmBhOI60ryvCX42mNQDk4NIaWHdk/OMRTci1kV3LG+3xPlzPB2Z0uq1gBLgbaiE+WEI3X0at/zVg=="
          />
          <div class="formhead">
            USERNAME
            <div>
              <input
                id="fname"
                autocomplete="off"
                class="formentry"
                data-validate-error="true"
                type="text"
                name="first_name"
                formControlName = "userName"
              />
            </div>
          </div>
          <div class="formhead h_mtop">
            PHONE
            <div>
              <input
                id="mobile"
                autocomplete="off"
                class="formentry"
                data-validate-error="true"
                type= "tel"
                name="mobile"
              />
            </div>
          </div>
          <div class="formhead h_mtop">
            EMAIL
            <div>
              <input
                id="signup-email"
                autocomplete="off"
                class="formentry"
                data-validate-error="true"
                type="text"
                value=""
                name="email"
                formControlName = "email"
              />
            </div>
          </div>
          <div class="formhead h_mtop">
            CREATE PASSWORD
            <input
              autocomplete="off"
              class="formentry"
              id="signup-pwd"
              data-validate-error="true"
              type="password"
              name="password"
              formControlName = "password"
            />
          </div>
          <input
            type="submit"
            name="commit"
            value="REGISTER"
            class="signinbtn"
            (click)="onClickregisterUser(); f.resetForm()"
          />
        </form>
      </div>
      <div class="noaccreg">
        <span style="color: #988b8b;">Already have an account?</span>
        <a
          style="color: #221f1f; white-space: nowrap;"
          [routerLink]="['/login']"
          routerLinkActive="router-link-active"
          >Sign in</a
        >
      </div>
      <div>
        <div class="orsign">
          <div class="col-md-4 col-sm-4 col-xs-3 osline"></div>
          <div class="col-md-4 col-sm-4 col-xs-6 orsigncontent">
            OR SIGN UP WITH
          </div>
          <div class="col-md-4 col-sm-4 col-xs-3 osline"></div>
        </div>
        <div class="h_mtop">
          <div class="row">
            <div class="col-md-6 col-sm-6" style="padding: 0 !important;">
              <button class="socialmediabtn fb" style="border: none;">
                <span
                  ><img
                    style="
                      height: 20px;
                      width: 20px;
                      margin-left: 20px;
                      margin-top: 5px;
                    "
                    src="/assets/img/fb.svg"
                    alt="Facebook"
                  />
                </span>
                <span class="socialmediabtncontent">FACEBOOK</span>
              </button>
            </div>
            <div class="col-md-6 col-sm-6" style="padding: 0 !important;">
              <button class="socialmediabtn google">
                <span
                  ><img
                    style="margin-left: 20px; margin-top: 5px;"
                    src="/assets/img/google.svg"
                    alt="Google"
                /></span>
                <span class="socialmediabtncontent" style="color: #565353;"
                  >GOOGLE</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
