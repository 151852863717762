<!-- <div *ngIf = "IsEdited" style="text-align: center;">Amenities updated successfully</div> -->

<div class="container-fluid" style="padding: 5% 0% 5% 0%;">
    <div class="container">
      <div class="card">
        <div class="card-header"> Amenity Details</div>
        <div class="panel-body show-com">
          <form [formGroup] = "AmenitiesDetailsForm"
             autocomplete="off" class="form-horizontal" id="new_amenity">
            <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="amenity_name"
                >Name</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  required="required"
                  type="text"
                  name="name"
                  id="amenity_name"
                  formControlName = "name"
                />
              </div>
            </div>
            <div class="row form-group">
              <label
                class="control-label col-sm-3 col-md-2"
                for="amenity_living_room"
                >For Living Room</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  name="amenity[living_room]"
                  type="hidden"
                  value="0"
                /><input
                  type="checkbox"
                  value="1"
                  checked="checked"
                  name="living_room"
                  id="amenity_living_room"
                  formControlName = "dorLivingRoom"
                />
              </div>
            </div>
            <div class="row form-group btn-top">
              <div class="col-sm-3 col-md-2"></div>
              <div class="col-sm-9 col-md-10">
                <div class="row">
                  <div class="col-sm-2">
                    <input
                      type="submit"
                      name="commit"
                      value="Edit"
                      class="btn active btn-system btn-block btn_comty"
                      (click)="oneditAmenitiesDetails()"
                    />
                  </div>
                  <div class="col-sm-2">
                    <a
                      class="btn active btn-default btn-block"
                      [routerLink]="['/Amenities']"
                      routerLinkActive="router-link-active"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  