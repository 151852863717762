<div class="container-xl" style="margin-top: 1%;">
    <div class=" row vertical-tabs">
      <div class="col-md-3" style="max-width: 21%;"> 
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#pag1" role="tab" aria-controls="home">PAYMENTS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag2" role="tab" aria-controls="profile">WISHLIST</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag3" role="tab" aria-controls="messages">STAYHOOKBUCKS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag4" role="tab" aria-controls="settings">EXPENSES</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag5" role="tab" aria-controls="settings">MOVE OUT</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag6" role="tab" aria-controls="settings">MY PLANED VISITS</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag7" role="tab" aria-controls="settings">PAY VIA NEFT/IMPS</a>
          </li>
        </ul>
      </div>   
        <!--For responsive -->
      

 <div class="container">
  <div class="row">
    <div class="col-xs-12 ">
      <nav>
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#pag1" role="tab" aria-controls="nav-home" aria-selected="true">PAYMENTS</a>
          <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pag2" role="tab" aria-controls="nav-profile" aria-selected="false">WISHLIST</a>
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#pag3" role="tab" aria-controls="nav-contact" aria-selected="false">STAYHOOKBUCKS</a>
          <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#pag4" role="tab" aria-controls="nav-about" aria-selected="false">EXPENSES</a>
           <!-- <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">MOVE OUT</a> -->
          <!--<a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">MY PLANED VISITS</a>
          <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">PAY VIA NEFT/IMPS</a> -->

        </div>
      </nav>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
        </div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
        </div>
        <div class="tab-pane fade" id="#pag4" role="tabpanel" aria-labelledby="nav-about-tab">
          <div class="sv-tab-panel">
            <app-expenses></app-expenses>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>
        


       <!--till here-->
    
      <div class="col-md-9">
        <div class="tab-content">
          <div class="tab-pane active" id="pag1" role="tabpanel">
            <div class="sv-tab-panel">
              <app-payment></app-payment>
            </div>
          </div>
          <div class="tab-pane wish_l" id="pag2" role="tabpanel">
            <div class="sv-tab-panel">
              <app-wishlist></app-wishlist>
            </div>
          </div>
          <div class="tab-pane" id="pag3" role="tabpanel">
            <div class="sv-tab-panel">
              <app-stayhook-bucks></app-stayhook-bucks>
            </div>
          </div>
          <div class="tab-pane" id="pag4" role="tabpanel">
            <div class="sv-tab-panel">
              <app-expenses></app-expenses>
            </div>
          </div>

          <div class="tab-pane" id="pag5" role="tabpanel">
            <div class="sv-tab-panel">
              <app-move-out></app-move-out>
            </div>
          </div>

          <div class="tab-pane" id="pag6" role="tabpanel">
            <div class="sv-tab-panel">
          <app-my-planed-visits></app-my-planed-visits>
            </div>
          </div>

          <div class="tab-pane" id="pag7" role="tabpanel">
            <div class="sv-tab-panel">
              <app-pay-via></app-pay-via>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



<!-- 

  <div class="container-x2" style="margin-top: 0%;">
    <div class=" row horizontal-tabs">
      <div class="col-md-6 style="max-width: 50%;"> 
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#pag1" role="tab" aria-controls="home">PAYMENTS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag2" role="tab" aria-controls="profile">WISHLIST</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag3" role="tab" aria-controls="messages">STAYHOOKBUCKS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag4" role="tab" aria-controls="settings">EXPENSES</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag5" role="tab" aria-controls="settings">MOVE OUT</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag6" role="tab" aria-controls="settings">MY PLANED VISITS</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag7" role="tab" aria-controls="settings">PAY VIA NEFT/IMPS</a>
          </li>
        </ul>
      </div>
      <div class="col-md-9">
        <div class="tab-content">
          <div class="tab-pane active" id="pag1" role="tabpanel">
            <div class="sv-tab-panel">
              <app-payment></app-payment>
            </div>
          </div>
          <div class="tab-pane wish_l" id="pag2" role="tabpanel">
            <div class="sv-tab-panel">
              <app-wishlist></app-wishlist>
            </div>
          </div>
          <div class="tab-pane" id="pag3" role="tabpanel">
            <div class="sv-tab-panel">
              <app-stayhook-bucks></app-stayhook-bucks>
            </div>
          </div>
          <div class="tab-pane" id="pag4" role="tabpanel">
            <div class="sv-tab-panel">
              <app-expenses></app-expenses>
            </div>
          </div>

          <div class="tab-pane" id="pag5" role="tabpanel">
            <div class="sv-tab-panel">
              <app-move-out></app-move-out>
            </div>
          </div>

          <div class="tab-pane" id="pag6" role="tabpanel">
            <div class="sv-tab-panel">
          <app-my-planed-visits></app-my-planed-visits>
            </div>
          </div>

          <div class="tab-pane" id="pag7" role="tabpanel">
            <div class="sv-tab-panel">
              <app-pay-via></app-pay-via>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->