import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class HousesService {


    constructor(private http: HttpClient) {}

    public getHouses() : Observable<any> {
      // return this.http.get('/api/User')
      return this.http.get('')

    }

    public saveHousesDetails(houseDetails: any) : Observable<any> {
      return this.http.post('/api/User', houseDetails)
    }
    
    public editHousesDetails(abc) : Observable<any> {
      return this.http.put('', abc)
    }

    public deleteHousesDetails() : Observable<any> {
       return this.http.delete('') 
    }

}