import { Component, OnInit} from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import * as $ from 'jquery';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.scss']
})
export class PageCardComponent implements OnInit {


  constructor() { }
  minValue: number = 1;
  maxValue: number = 25000;
  options: Options = {
    ceil: 25000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b> Rs.' + value;
        case LabelType.High:
          return '<b>Max price:</b> Rs.' + value;
        default:
          return '$' + value;
      }
    }
  };

  ngOnInit(): void {
    //for image-radio-1////
    $(document).ready(function () {
      // add/remove checked class
      $('.image-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('image-radio1-checked');
        } else {
          $(this).removeClass('image-radio1-checked');
        }
      });

      // sync the input state
      $('.image-radio1').on('click', function (e) {
        $('.image-radio1').removeClass('image-radio1-checked');
        $(this).addClass('image-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ////////

     //for bed-radio-1////
     $(document).ready(function () {
      // add/remove checked class
      $('.bed-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('bed-radio1-checked');
        } else {
          $(this).removeClass('bed-radio1-checked');
        }
      });

      // sync the input state
      $('.bed-radio1').on('click', function (e) {
        $('.bed-radio1').removeClass('bed-radio1-checked');
        $(this).addClass('bed-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ////////

    //for Condition-radio-1////
    $(document).ready(function () {
      // add/remove checked class
      $('.condition-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('condition-radio1-checked');
        } else {
          $(this).removeClass('condition-radio1-checked');
        }
      });

      // sync the input state
      $('.condition-radio1').on('click', function (e) {
        $('.condition-radio1').removeClass('condition-radio1-checked');
        $(this).addClass('condition-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ////////
     //for House-radio-1////
     $(document).ready(function () {
      // add/remove checked class
      $('.house-radio1').each(function () {
        if ($(this).find('input[type="radio"]').first().attr('checked')) {
          $(this).addClass('house-radio1-checked');
        } else {
          $(this).removeClass('house-radio1-checked');
        }
      });

      // sync the input state
      $('.house-radio1').on('click', function (e) {
        $('.house-radio1').removeClass('house-radio1-checked');
        $(this).addClass('house-radio1-checked');
        var $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', !$radio.prop('checked'));

        e.preventDefault();
      });
    });
    ////////
    
  }
}
