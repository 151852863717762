<div class="logincard">
  <div class="logincardone">
    <div class="img-responsive">
      <a href="/">
        <img
          class="img-responsive"
          width="130px"
          height="23px"
          src="/assets/img/Logo_SH_Grey.png"
          alt="logo"
        />
      </a>
    </div>
    
    <div class="singin">
      SIGN IN TO CONTINUE
    </div>
  </div>
  <div class="formsec2" id="newlogin">
    <form [formGroup] = "loginUserData"
      id="loginform"
      class="new_user"
      action="/users/sign_in"
      accept-charset="UTF-8"
      method="post"
    >
      <input name="utf8" type="hidden" value="✓" /><input
        type="hidden"
        name="authenticity_token"
        value="rsCjXTvWkKE+2Kx1ocli9bpxMkaxenALHUi5cJcFsxbmDCVFwmGRFUs7cw085NvjSYmjpFfWLeO4zkvyshRxVA=="
      />
      <div class="formhead">
        USERNAME
        <div>
          <input
            id="loginphone"
            class="formentry"
            type="text"
            value=""
            name="email"
            formControlName = "userName"
          />
        </div>
      </div>
      <div class="formhead h_mtop">
        PASSWORD
        <div class="help" id="forgotpass">
          <a href="/users/password/new">FORGOT PASSWORD?</a>
        </div>
        <div>
          <input
            autocomplete="off"
            class="formentry"
            type="password"
            name="password"
            id="user_password"
            formControlName = "password"
          />
        </div>
      </div>
      <input
        type="submit"
        name="commit"
        value="Login"
        class="signinbtn"
        (click)="onClickloginUser()"
      />
    </form>
    <div class="noaccreg">
      <span style="color: #988b8b;">Don't have an account?</span>
      <a
        style="color: #221f1f;"
        [routerLink]="['/register']"
        routerLinkActive="router-link-active"
        >Register Here</a
      >
    </div>
  </div>
  <div id="socialbtns">
    <div class="orsign">
      <div class="col-md-4 col-sm-4 col-xs-3 osline"></div>
      <div class="col-md-4 col-sm-4 col-xs-6 orsigncontent">
        OR SIGN UP WITH
      </div>
      <div class="col-md-4 col-sm-4 col-xs-3 osline"></div>
    </div>
    <div class="h_mtop">
      <div class="row">
        <div class="col-md-6 col-sm-6" style="padding: 0 !important;">
          <button class="socialmediabtn fb" style="border: none;">
            <span
              ><img
                style="
                  height: 20px;
                  width: 20px;
                  margin-left: 20px;
                  margin-top: 5px;
                "
                src="/assets/img/fb.svg"
                alt="Facebook"
              />
            </span>
            <span class="socialmediabtncontent">FACEBOOK</span>
          </button>
        </div>
        <div class="col-md-6 col-sm-6" style="padding: 0 !important;">
          <button class="socialmediabtn google">
            <span
              ><img
                style="margin-left: 20px; margin-top: 5px;"
                src="/assets/img/google.svg"
                alt="Google"
            /></span>
            <span class="socialmediabtncontent" style="color: #565353;"
              >GOOGLE</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="loginasvendorbtn">
    LOGIN AS VENDOR
  </div>
</div>
