import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Service/Users/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

 
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  showMsg: boolean = false;

  registerUserData: FormGroup;

  // registerUserData = { first_name: '', mobile: '', email: '', password: '' };
  constructor(private _auth: AuthService, private _router: Router, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.registerUserData = this.fb.group({
      userName: [''],
      email: [''],
      password: ['']
    })
  }

  onClickregisterUser() {
    console.log(this.registerUserData.value)
    this._auth.registerUser(this.registerUserData.value).subscribe(
      (res) => {
        console.log(res);
        if(res.status == 'Success'){
         alert('User registered successfully');
         this._router.navigateByUrl('/login');
        //  this.registerUserData.reset()

        }
        // this.showMsg = true;
      },
      // (err) => console.log(err)
    );
  }
}
