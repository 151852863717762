import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class BookingsService {

    
  constructor(private http: HttpClient, private router: Router) {}


    public getBookings() : Observable<any> {
      return this.http.get('/api/Bookings')
    }

    public saveBookingsDetails(booking) : Observable<any> {
      // console.log('hello')
      return this.http.post('/api/Bookings', booking)
    }

    public getBookingsbyId(bookingsId: any) : Observable<any> {
      let url = '/api/Bookings' ;
      url = `${url}/${bookingsId}`
      return this.http.get(url);
    }

    public editBookingsDetails(bookingsId: any,bookingsDetails: any) : Observable<any> {
      let url = '/api/Bookings' ;
      url = `${url}/${bookingsId}`
      return this.http.put(url, bookingsDetails)
    }

    public deleteBookingsDetails(bookingsId: any) : Observable<any> {
      let url = '/api/Bookings' ;
      url = `${url}/${bookingsId}`
      return this.http.delete(url);    
    }
    
}