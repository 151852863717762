import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
 

    constructor(private http: HttpClient) {}
   
    public getInvoice() : Observable<any> {
      return this.http.get('/api/Invoices')
    }

    public saveInvoiceDetails(invoice) : Observable<any> {
      return this.http.post('/api/Invoices', invoice)
    }

    public getInvoicebyId(invoiceId: any) : Observable<any> {
      let url = '/api/Invoices' ;
      url = `${url}/${invoiceId}`
      return this.http.get(url);
    }

    public editInvoiceDetails(invoiceId: any,invoiceDetails: any) : Observable<any> {
      let url = '/api/Invoices' ;
      url = `${url}/${invoiceId}`
      return this.http.put(url, invoiceDetails);
    }

    public deleteInvoiceDetails(invoiceId: any) : Observable<any> {
      let url = '/api/Invoices' ;
      url = `${url}/${invoiceId}`
      return this.http.delete(url);    
    }
    

}