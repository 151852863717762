<!--Side Bar starts-->
<div class="row row1">
    <div class="col-md-4">
        <div class="header">
            <mat-form-field>
                <mat-label>All Recurring Invoices</mat-label>
                <mat-select>
                    <mat-option value="one">1</mat-option>
                    <mat-option value="two">2</mat-option>
                    <mat-option value="three">3</mat-option>
                </mat-select>
            </mat-form-field>
            <span style="float:right" class="material-icons icon-design">
                dehaze
            </span>
            <mat-form-field style="float:right">
                <mat-label><span class="material-icons">
                        add
                    </span>New</mat-label>
                <mat-select>
                    <mat-option value="one">1</mat-option>
                    <mat-option value="two">2</mat-option>
                    <mat-option value="three">3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <table class="table tablecontent" *ngFor="let user1 of filterData">
            <tr>
                <td><i class="fas fa-square"></i><span
                        style="color:rgba(11, 130, 228, 0.986); text-transform: uppercase;">{{user1.customerName}}</span>
                </td>
                <td><i class="fa fa-rupee">{{user1.amount}}</i></td>
            </tr>
            <tr>
                <td><span style="text-transform: uppercase;">{{user1.profileName}}</span></td>
                <td>{{user1.frequency}}</td>
            </tr>
            <tr>
                <td> Next Invoice on {{user1.nextInvoiceDate}}</td>
                <td><span style="text-transform: uppercase; color:green">{{user1.status}}</span></td>
            </tr>
            <hr>
            <hr>
        </table>
    </div>
    <!--Sidebar ends-->
    <!--main recurring invoice starts-->
    <div class="col-md-8 m-top">
        <div style="padding-top:20px;">
            <a class="name-invoice" href="#" style="padding:10px; font-size:20px; text-decoration:none">Shalini Yadav</a>
            <span style="float: right; margin:5px" class="material-icons icon-design">create</span>
            <button style="float: right; margin:5px; padding:3px;" mat-raised-button>Create Invoice</button>&nbsp;
            <mat-form-field style="float: right; margin:5px;">
                <mat-label>More</mat-label>
                <mat-select>
                    <mat-option value="more"><a style="text-decoration: none;" href="#">1</a></mat-option>
                </mat-select>
            </mat-form-field>
            <mat-tab-group class="design-tab">
                <mat-tab label="Overview">
                    <div class="row">
                        <div class="col-md-5">
                            <table class="table tbl">
                                <tr>
                                    <td class="table-content" style="font-weight: bold;">
                                        Tower G2 1001 Golf City
                                    </td>
                                </tr>
                                <hr>
                                <tr>
                                    <td class="table-content"><span class="material-icons design_1">
                                            account_circle
                                        </span>
                                        <a href="#">Mrs. Shalini Yadav</a>
                                        <p>shaliyadav@gmail.com</p>
                                        <p><span class="material-icons">
                                                call
                                            </span>8865291146</p>
                                        <p><span class="material-icons">
                                                stay_current_portrait
                                            </span>9856372930</p>
                                    </td>
                                </tr>
                                <hr>
                                <tr>
                                    <th>DETAILS</th>
                                    <th></th>
                                </tr>
                                <hr>
                                <tr>
                                    <td class="table-content">
                                        <p>Profile Status:</p>
                                        <p>Start Date:</p>
                                        <p>End Date:</p>
                                        <p>Payment Terms:</p>
                                    </td>
                                    <td class="table-content">
                                        <p style="Color:green; font-weight: bold;">ACTIVE</p>
                                        <p>01/02/2021</p>
                                        <p>Never Expires</p>
                                        <p>Net 2</p>
                                    </td>
                                </tr>
                                <hr>
                                <tr>
                                    <th>Address</th>
                                </tr>
                                <hr>
                            </table>
                            <br>
                            <p style="font-weight:bold">Billing Address</p>
                            <p>Tower G2 flat 1002 Golf City
                                Plot no. 11 sector 75 Noida
                                201306
                                Noida<br>
                                Uttar Pradesh<br>
                                India</p>
                            <hr>
                            <br>
                            <p style="font-weight:bold">Shipping Address</p>
                            <p>276 Peptech city Raghurajangar Satne
                                480012<br>
                                Satna<br>
                                Madhya Pradesh India
                            </p>
                            <p style="font-weight: bold;">NOTES</p>
                            <hr><br>
                            <p style="font-weight: bold;">Thankyou for choosing Stayhook</p>
                        </div>
                        <div class="col-md-7">
                            <table class="table table-tab">
                                <tr>
                                    <td class="table-tab">Invoice Amount
                                        <p><i class="fa fa-rupee">10,000.00</i></p>
                                    </td>
                                    <td class="table-tab">Next Invoice Date
                                        <p style="color:rgb(18, 106, 221); font-weight: bold;">01/02/2021</p>
                                    </td>
                                    <td class="table-tab">Recurring Period
                                        <p style="font-weight: bold;">Monthly</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <mat-label style="font-weight: bold;">All Child Invoices</mat-label>
                                            <mat-select>
                                                <mat-option value="one">1</mat-option>
                                                <mat-option value="two">2</mat-option>
                                                <mat-option value="three">3</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td></td>
                                    <td style="font-weight: bold">
                                        Unpaid Invoice: <span><i class="fa fa-rupee"> 0.00 </i></span>
                                    </td>
                                    <tr>
                                    <td style="font-weight: bold">
                                        <p>Mrs. Shalini Yadav</p>
                                        <p><span style="color:rgb(20, 107, 236)">INV-2210</span> | 01-02-2021</p>
                                    </td>
                                    <td></td>
                                    <td>
                                        <span><i class="fa fa-rupee">10,000.00</i></span>
                                        <p style="text-transform: uppercase; color:green;">paid</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Next Invoice">

                </mat-tab>
                <mat-tab label="Recent Activities">


                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>