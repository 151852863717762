import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public userId: string;

  constructor(private http: HttpClient, private router: Router) {}

  public getUser() : Observable<any> {
   return this.http.get('/api/User')

  }

  public getuserbyId(userId: string) : Observable<any> {
   let params = new HttpParams()
  //  console.log(userId);
   //return this.http.get('/api/User', {params: {id: userId}});
   let url = '/api/User' ;
      url = `${url}/${userId}`
      return this.http.get(url);

  }

  public saveuserDetails(userDetails: any) : Observable<any> {
    // console.log('hi');
    return this.http.post('/api/User', userDetails)
  }

  public edituserDetails(userId: string, userDetails: any) : Observable<any> {
    //let params = new HttpParams()
    // return this.http.put('/api/User', {params: {id: userId}}, userDetails)
    // console.log(userDetails)
    // console.log(userId)
    // let url = '/api/User' ;
    // url = `${url}/${userId}`
    // return this.http.put(url, userDetails)
    //userDetails.phoneNo = "9717955265"
    let url = '/api/User';
    //userId ='1324d64b-899c-4f91-929e-3fc79acd00f5'
    url = `${url}/${userId}`
    console.log(userDetails)
    return this.http.put(url, userDetails);
  }
  
  public deleteuserDetails(userId: string) :Observable<any> {
    // let params = new HttpParams()
    // return this.http.delete('/api/User', {params: {id: userId}})
    let url = '/api/User'
    url = `${url}/${userId}`
    console.log(userId)
    return this.http.delete(url);
  }

}
