import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Service/Users/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  isCollapsed = false;
  constructor(public _authService : AuthService) { }

  ngOnInit(): void {
  }

  openNav(){
    document.getElementById("myNav").style.height = "auto";
    // this.abc=false;
  }
  
  closeNav(){
    document.getElementById("myNav").style.height = "0%";
    // this.abc=true;
  }

  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }
  
  getUserName()
  {
   if(this._authService.loggedIn() && this._authService.getLoggedInUserRoleType() == 'User')
   {
     return localStorage.getItem('userName')
     //console.log(this.userName);
   }

  }

}
