import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatrialModule } from './matrial/matrial.module';
import { NavBarComponent } from './UI/nav-bar/nav-bar.component';
import { FooterComponent } from './UI/footer/footer.component';
import { HomeComponent } from './UI/home/home.component';
import { LoginComponent } from './UI/login/login.component';
import { RegisterComponent } from './UI/register/register.component';
import { AuthService } from './Service/Users/auth.service';
import { AuthGuard } from './Service/Users/auth.guard';
import { TokenInterceptorService } from './Service/Users/token-interceptor.service';
import { AdminNavbarComponent } from './UI/Admin/admin-navbar/admin-navbar.component';
import { AdminHomeComponent } from './UI/Admin/admin-home/admin-home.component';
import { HousesComponent } from './UI/Admin/houses/houses.component';
import { UsersComponent } from './UI/Admin/users/users.component';
import { AmenitiesComponent } from './UI/Admin/amenities/amenities.component';
import { BookingsComponent } from './UI/Admin/bookings/bookings.component';
import { NewAmenitiesComponent } from './UI/Admin/new-amenities/new-amenities.component';
import { NewUserComponent } from './UI/Admin/new-user/new-user.component';
import { NewHouseComponent } from './UI/Admin/new-house/new-house.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { VendorComponent } from './vendor/vendor.component';
import { ListYourHouseComponent } from './UI/list-your-house/list-your-house.component';
import { ReferEarnComponent } from './UI/refer-earn/refer-earn.component';
import { PaymentComponent } from './UI/refer-earn/payment/payment.component';
import { WishlistComponent } from './UI/refer-earn/wishlist/wishlist.component';

import { UserDetailsComponent } from './UI/user-details/user-details.component';
import { QuestionComponent } from './UI/question/question.component';
import { PageCardComponent } from './UI/page-card/page-card.component';
import { Ng5SliderModule } from 'ng5-slider';
import { Ng2SearchPipeModule } from 'ng2-search-filter';



import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SearchRoomsComponent } from './UI/search-rooms/search-rooms.component';
import { RoomDetailsComponent } from './UI/search-rooms/room-details/room-details.component';
import { ErrorComponent } from './UI/error/error.component';

import {MatTabsModule} from '@angular/material/tabs';
import { StayhookBucksComponent } from './UI/refer-earn/stayhook-bucks/stayhook-bucks.component';
import { ExpensesComponent } from './UI/refer-earn/expenses/expenses.component';
import { MoveOutComponent } from './UI/refer-earn/move-out/move-out.component';
import { MyPlanedVisitsComponent } from './UI/refer-earn/my-planed-visits/my-planed-visits.component';
import { PayViaComponent } from './UI/refer-earn/pay-via/pay-via.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { CreateOwnerDetailsComponent } from './UI/Admin/create-owner-details/create-owner-details.component';
import {MatMenuModule} from '@angular/material/menu';
import { IgxCalendarModule, IgxDialogModule } from "igniteui-angular";
import { InvoiceListComponent } from './UI/Admin/invoice/invoice-list/invoice-list.component';
import { InvoiceDetailComponent } from './UI/Admin/invoice/invoice-detail/invoice-detail.component';
import { PaymentReminderComponent } from './UI/Admin/payment-reminder/payment-reminder.component';
import { RecurringInvoiceComponent } from './UI/Admin/recurring-invoice/recurring-invoice.component';
import { RecurringDetailComponent } from './UI/Admin/recurring-detail/recurring-detail.component';
import { RecurringInvoiceCheckComponent } from './UI/Admin/recurring-invoice-check/recurring-invoice-check.component';
import { DialogboxComponent } from './vendor/dialogbox/dialogbox.component';
import { DialogBoxComponent } from './UI/Admin/admin-home/dialog-box/dialog-box.component';
import { AmenitiesdetailsComponent } from './UI/Admin/amenitiesdetails/amenitiesdetails.component';
import { ChangePasswordComponent } from './UI/change-password/change-password.component';
import { UsersInfoComponent } from './UI/users-info/users-info.component';
import { MyAccountComponent } from './UI/my-account/my-account.component';
import { NewBookingComponent } from './UI/new-booking/new-booking.component';
import { BookingDetailsComponent } from './UI/booking-details/booking-details.component';
import { NewInvoiceComponent } from './UI/new-invoice/new-invoice.component';





@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    AdminNavbarComponent,
    AdminHomeComponent,
    HousesComponent,
    UsersComponent,
    AmenitiesComponent,
    BookingsComponent,
    NewAmenitiesComponent,
    NewUserComponent,
    NewHouseComponent,
    VendorComponent,
    ListYourHouseComponent,
    ReferEarnComponent,
    PaymentComponent,
    WishlistComponent,
    UserDetailsComponent,
    QuestionComponent,
    PageCardComponent,
    PaymentComponent,
    SearchRoomsComponent,
    RoomDetailsComponent,
    
    ErrorComponent,

    StayhookBucksComponent,

    ExpensesComponent,

    MoveOutComponent,

    MyPlanedVisitsComponent,

    PayViaComponent,

    CreateOwnerDetailsComponent,

    InvoiceListComponent,

    InvoiceDetailComponent,

    PaymentReminderComponent,

    RecurringInvoiceComponent,

    RecurringDetailComponent,

    RecurringInvoiceCheckComponent,

    DialogboxComponent,

    DialogBoxComponent,

    AmenitiesdetailsComponent,

    ChangePasswordComponent,

    UsersInfoComponent,

    MyAccountComponent,

    NewBookingComponent,

    BookingDetailsComponent,

    NewInvoiceComponent,




  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatrialModule,
    HttpClientModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    Ng5SliderModule,
    Ng2SearchPipeModule,
    GooglePlaceModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    IgxCalendarModule, 
    IgxDialogModule
  ],
  providers: [AuthService,AuthGuard,
    {
      provide:HTTP_INTERCEPTORS,
      useClass :TokenInterceptorService,
      multi:true,
    }
  ],
  entryComponents: [DialogboxComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
