<!-- <h1>You selected {{userId}}</h1> -->

<form [formGroup]="UserDetailsForm">
  <div class="row form-group">
    <div class="col-md-2 progress-back">
      <div style="margin-top: 20%;">
        <span class="profile">PROFILE COMPLETED</span>
        <div class="progress">
          <div class="progress-bar bg-dark" style="height: 10%;">10%</div>
        </div>
      </div>

      <p class="progress-percentage">10%</p>
    </div>

    <div class="col-md-10">
      <mat-horizontal-stepper labelPosition="bottom">

        
        <div formControlGroup = "UserDetailsForm">
          <mat-step
            label="USER DETAILS"
            state="address"
            class="mat-step-text-label"
          >
            <div class="row">
              <div class="col-md-6">

                <!-- <div
                class="formhead"
                style="font-weight: 500; margin-top: 65px;"
              >
                <label>ID</label>
                <div
                  style="
                    line-height: 28px;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.72px;
                  "
                >
                
                  <input
                    class="formentry"
                    style="width: 95%;"
                    type="text"
                    name="id"
                    formControlName="id"
                  />
                </div>
              </div> -->


                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>FIRST NAME</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="fname"
                      formControlName="firstName"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>LAST NAME</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="fname"
                      formControlName="lastName"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>USERNAME</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="name"
                      formControlName="userName"
                    />
                  </div>
                </div>

                <div
                class="formhead"
                style="font-weight: 500; margin-top: 65px;"
              >
                <label>EMAIL</label>
                <div
                  style="
                    line-height: 28px;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.72px;
                  "
                >
                  <input
                    class="formentry"
                    style="width: 95%;"
                    type="text"
                    name="email"
                    formControlName="email"
                  />
                </div>
              </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>PHONE</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="phoneNo"
                      formControlName="phoneNo"
                    />
                  </div>
                </div>
              </div>

              <div
              class="formhead"
              style="font-weight: 500; margin-top: 65px;"
            >
              <label>CREATE DATE</label>
              <div
                style="
                  line-height: 28px;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0.72px;
                "
              >
                <input
                  class="formentry"
                  style="width: 95%;"
                  type="text"
                  name="date"
                  formControlName="createdDate"
                />
              </div>
            </div>

            <div
            class="formhead"
            style="font-weight: 500; margin-top: 65px;"
          >
            <label>ROLE ID</label>
            <div
              style="
                line-height: 28px;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.72px;
              "
            >
              <input
                class="formentry"
                style="width: 95%;"
                type="text"
                name="id"
                formControlName="roleID"
              />
            </div>
          </div>

          <div
            class="formhead"
            style="font-weight: 500; margin-top: 65px;"
          >
            <label>ROLE</label>
            <div
              style="
                line-height: 28px;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.72px;
              "
            >
              <input
                class="formentry"
                style="width: 95%;"
                type="text"
                name="role"
                formControlName="role"
              />
            </div>
          </div>

          <div
          class="formhead"
          style="font-weight: 500; margin-top: 65px;"
          >
          <label>VERIFIED</label>
          <div
            style="
              line-height: 28px;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.72px;
            "
          >
            <input
              class="formentry"
              style="width: 95%;"
              type="checkbox"
              name="verified"
              formControlName="isVerrified"
            />
          </div>
        </div>



              <div class="col-md-6">
                <!-- <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>EMAIL</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="email"
                      name="email"
                      formControlName="email"
                    />
                  </div>
                </div> -->
                <div class="row">
                  <!-- <div class="col-md-6">
                    <div
                      class="formhead"
                      style="font-weight: 500; margin-top: 65px;"
                    >
                      <label>GENDER</label>
                      <div>
                        <mat-radio-group
                          aria-label="Select an option"
                          formControlName="gender"
                        >
                          <mat-radio-button value="Male">Male</mat-radio-button>
                          <mat-radio-button value="Female"
                            >Female</mat-radio-button
                          >
                        </mat-radio-group>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="col-md-6">
                    <div
                      class="formhead"
                      style="font-weight: 500; margin-top: 65px;"
                    >
                      <label>MARITAL STATUS</label>
                      <div>
                        <select
                          class="formentry"
                          style="width: 90%;"
                          formControlName="MaritalStatus"
                        ><option value="0">Select</option>
                          <option value="1">Single</option>
                          <option value="2">Married</option>
                        </select>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <br /><br /> -->
            <div>
              <button mat-button matStepperNext class="usernextbtn"
              (click) = "onedituserDetails()">
                SAVE & CONTINUE
              </button>
            </div>
          </mat-step>
        </div>

        <!-- <div formGroupName="PermanentAddress">
          <mat-step label="PERMANENT ADDRESS" state="address">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>ADDRESS</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="address"
                      formControlName="address"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>STATE</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="state"
                      formControlName="state"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>PIN CODE</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="pin_code"
                      formControlName="pin_code"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>CITY</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="city"
                      formControlName="city"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>COUNTRY</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="country"
                      formControlName="country"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button mat-button matStepperNext class="usernextbtn">
                SAVE & CONTINUE
              </button>
              <button mat-button matStepperPrevious class="userpreviousbtn">
                Back
              </button>
            </div>

          </mat-step>
        </div> -->

        <!-- <div formGroupName="BankingInfo">
          <mat-step label="BANKING INFO" state="chat">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>ACCOUNT HOLDER NAME</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="account_holderName"
                      formControlName="account_holderName"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>ACCOUNT NO.</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="account_no"
                      formControlName="account_no"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>IFSC CODE</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="ifsc_Code"
                      formControlName="ifsc_Code"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>BANK</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="bank"
                      formControlName="bank"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>BRANCH</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="branch"
                      formControlName="branch"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button mat-button matStepperNext class="usernextbtn">
                SAVE & CONTINUE
              </button>
              <button mat-button matStepperPrevious class="userpreviousbtn">
                Back
              </button>
            </div>
          </mat-step>
        </div> -->

<!-- 
        <div formGroupName="PanInfo">
          <mat-step label="PAN INFO" state="chat">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>PAN HOLDER NAME</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="pan_holder_name"
                      formControlName="pan_holder_name"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>DATE OF BIRTH</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="date_of_birth"
                      formControlName="date_of_birth"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>PAN NO.</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="pan_no"
                      formControlName="pan_no"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button mat-button matStepperNext class="usernextbtn">
                SAVE & CONTINUE
              </button>
              <button mat-button matStepperPrevious class="userpreviousbtn">
                Back
              </button>
            </div>
          </mat-step>
        </div> -->

<!-- 
        <div formGroupName="EmercencyContact">
          <mat-step label="EMERGENCY CONTACT" state="phones">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>NAME OF PERSON</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="person_name"
                      formControlName="person_name"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>PHONE</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="phone"
                      formControlName="phone"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>RELATIONSHIP</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="relationship"
                      formControlName="relationship"
                    />
                  </div>
                </div>

                <div
                  class="formhead"
                  style="font-weight: 500; margin-top: 65px;"
                >
                  <label>ADDRESS</label>
                  <div
                    style="
                      line-height: 28px;
                      font-size: 12px;
                      font-weight: 500;
                      letter-spacing: 0.72px;
                    "
                  >
                    <input
                      class="formentry"
                      style="width: 95%;"
                      type="text"
                      name="address"
                      formControlName="address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            <div>
              <button mat-button matStepperNext class="usernextbtn">
                SAVE & CONTINUE
              </button>
              <button mat-button matStepperPrevious class="userpreviousbtn">
                Back
              </button>
            </div>
          </mat-step>
        </div> -->



        <!-- <div formGroupName="EmployementInfo">
          <mat-step label="EMPLOYEMENT INFO" state="phones">
            <div class="formhead" style="font-weight: 500; margin-top: 35px;">
              <label>GENDER</label>
              <div>
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="gender"
                >
                  <mat-radio-button value="I AM EMPLOYED"
                    >I AM EMPLOYED</mat-radio-button
                  >
                  <mat-radio-button value="I AM SELF EMPLOYED"
                    >I AM SELF EMPLOYED</mat-radio-button
                  >
                  <mat-radio-button value="I AM STUDENT"
                    >I AM STUDENT</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>

            <div class="formhead" style="font-weight: 500; margin-top: 35px;">
              <label>EDUCATION QUALIFICATION</label>
              <div>
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="education_qualification"
                >
                  <mat-radio-button value="HIGH SCHOOL"
                    >HIGH SCHOOL</mat-radio-button
                  >
                  <mat-radio-button value="DIPLOMA">DIPLOMA</mat-radio-button>
                  <mat-radio-button value="BACHELOR'S DEGREE"
                    >BACHELOR'S DEGREE</mat-radio-button
                  >
                  <mat-radio-button value="MASTER'S DEGREE"
                    >MASTER'S DEGREE</mat-radio-button
                  >
                  <mat-radio-button value="PHD">PHD</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <br /><br />

            <div>
              <button
                mat-button
                matStepperNext
                class="usernextbtn"
                type="submit"
                (click)="onSubmit()"
              >
                SAVE
              </button>
              <button mat-button matStepperPrevious class="userpreviousbtn">
                Back
              </button>
            </div>
          </mat-step>
        </div> -->
      </mat-horizontal-stepper>
    </div>
  </div>
</form>
