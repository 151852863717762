<div class="container-fluid big-screen vendordashmenu">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand" href="#">
          <img
            width="141px"
            height="30px"
            src="/assets/img/Logo_SH_Grey.png"
            alt="Logo"
          />
        </a>
      </div>
      <ul class="nav navbar-nav"  id="myDIV">
        <li class="vdmenuselected">
          <a [routerLink]="['/AdminHome']" class="menu_nav active" routerLinkActive="router-link-active">Home</a>
        </li>
        <li class="">
          <a [routerLink]="['/Houses']" class="menu_nav" routerLinkActive="router-link-active">Houses</a>
        </li>
        <li class="">
          <a [routerLink]="['/Users']" class="menu_nav" routerLinkActive="router-link-active">Users</a>
        </li>
        <li class="">
          <a [routerLink]="['/Amenities']" class="menu_nav" routerLinkActive="router-link-active">Amenities</a>
        </li>
        <li class="">
          <a [routerLink]="['/Bookings']" class="menu_nav" routerLinkActive="router-link-active">Bookings</a>
        </li>
        <li class="">
          <a [routerLink]="['/InvoiceList']" class="menu_nav" routerLinkActive="router-link-active">Invoice</a>
        </li>
        <li class="">
          <a [routerLink]="['/changepassword']" routerLinkActive="router-link-active" class="menu_nav"><div>Change Password</div></a>
        </li>
        <li class="">
          <a class="menu_nav"style="color: #484848; cursor: pointer;"rel="nofollow"data-method="delete"*ngIf="_authService.loggedIn()"(click)="_authService.loggedOut()">Signout</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<!--  
<div class="small-screen">
  <div class="col-md-12">
    <span class="wrap">
      <a routerLink="/home">
          <img src="/assets/img/Logo_SH_Grey.png" alt="logo">
      </a>
    </span>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <span class="material-icons" style="font-size: 30px;">menu</span>
      </button>
    <mat-menu #menu="matMenu" class="Admin_nav">
      <button mat-menu-item>
        <a [routerLink]="['/AdminHome']" routerLinkActive="router-link-active">Home</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="['/Houses']" routerLinkActive="router-link-active">Houses</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="['/Users']" routerLinkActive="router-link-active">Users</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="['/Amenities']" routerLinkActive="router-link-active">Amenities</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="['/Bookings']" routerLinkActive="router-link-active">Bookings</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="['/InvoiceList']" routerLinkActive="router-link-active">Invoice</a>
        </button>
        <button mat-menu-item>
          <a href="#">
            <div>Change Password</div>
          </a>
        </button>
        <button mat-menu-item>
          <a
          style="color: #484848;"
          rel="nofollow"
          data-method="delete"
          *ngIf="_authService.loggedIn()"
          (click)="_authService.loggedOut()"
          >Signout</a>
        </button>
    </mat-menu>
  </div>
</div> -->


<div id="myNav" class="overlay">
  <a class="closebtn" (click)="closeNav()">&times;</a>
  <a href="#"><h1>StayHook</h1></a>
  <div class="overlay-content">
    <a [routerLink]="['/AdminHome']" class="menu_nav active" routerLinkActive="router-link-active" (click)="closeNav()">Home</a>
    <a [routerLink]="['/Houses']" class="menu_nav" routerLinkActive="router-link-active" (click)="closeNav()">Houses</a>
    <a [routerLink]="['/Users']" class="menu_nav" routerLinkActive="router-link-active" (click)="closeNav()">Users</a>
    <a [routerLink]="['/Amenities']" class="menu_nav" routerLinkActive="router-link-active"(click)="closeNav()">Amenities</a>
    <a [routerLink]="['/Bookings']" class="menu_nav" routerLinkActive="router-link-active" (click)="closeNav()">Bookings</a>
    <a [routerLink]="['/InvoiceList']" class="menu_nav" routerLinkActive="router-link-active" (click)="closeNav()">Invoice</a>
    <a [routerLink]="['/changepassword']" class="menu_nav btn-change" routerLinkActive="router-link-active" (click)="closeNav()"><div>Change Password</div></a>
    <a class="menu_nav btn-sign"style="color: #484848;"rel="nofollow"data-method="delete"*ngIf="_authService.loggedIn()"(click)="_authService.loggedOut()">Signout</a>
  </div>
</div>
<div class="menu-1">
  <span>
    <a href="#">
      <span class="menu-heading"> We are StayBook </span>
     <p class="menu-text">Some text about company & it's services</p>
    </a>
  </span>
  <span class="menu-btn" (click)="openNav()">&#9776;</span>
</div>