import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Service/Users/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  ChangePassword: FormGroup

  IsChanged = false;

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private auth_service: AuthService) { }

  ngOnInit(): void {
    this.ChangePassword = this.fb.group({
      userName: [''],
      currentPassword: [''],
      newPassword: ['']
    })
  }

  onChangePassword(){
    // console.log(this.ChangePassword.value)
    this.auth_service.changePassword(this.ChangePassword.value).subscribe(
      (res) => {
        // console.log(res)
        if(res.status == "Success"){
          this.IsChanged = true;
          alert('Password changed successfully');
          this.router.navigateByUrl('/Users')
        }
        
      }
    )
  }

}
