import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-owner-details',
  templateUrl: './create-owner-details.component.html',
  styleUrls: ['./create-owner-details.component.scss']
})
export class CreateOwnerDetailsComponent implements OnInit {

  constructor() { }

  searchKey:string;

  fData =[
    {"name":"Asmen Singh"},
]
fData1 =[
  {"name":"Flat1", "status":"book"},
  {"name":"Flat2", "status":"book"},
  {"name":"Flat3", "status":"empty"},
  {"name":"Flat4", "status":"book"},
  {"name":"Flat5", "status":"empty"},
  {"name":"Flat6", "status":"book"},
  {"name":"Flat7", "status":"book"},
  {"name":"Flat8", "status":"empty"}
]
fData2 =[
  {"name":"Flat1", "status":"book"},
  {"name":"Flat2", "status":"book"},
  {"name":"Flat3", "status":"empty"},
  {"name":"Flat4", "status":"book"},
  {"name":"Flat5", "status":"empty"},
  {"name":"Flat6", "status":"book"},
  {"name":"Flat7", "status":"book"},
  {"name":"Flat8", "status":"empty"}
]
fData3 =[
  {"name":"Flat1", "status":"book"},
  {"name":"Flat2", "status":"book"},
  {"name":"Flat3", "status":"empty"},
  {"name":"Flat4", "status":"book"},
  {"name":"Flat5", "status":"empty"},
  {"name":"Flat6", "status":"book"},
  {"name":"Flat7", "status":"book"},
  {"name":"Flat8", "status":"empty"}
]


  ngOnInit(): void {
  }

  onSearchClear(){
    this.searchKey="";
  }

}
