import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UsersService } from '../Admin/users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Params,ParamMap } from '@angular/router';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  // providers: [
  //   {
  //     provide: STEPPER_GLOBAL_OPTIONS,
  //     useValue: { displayDefaultIndicatorType: false },
  //   },
  // ],
})
export class UserDetailsComponent implements OnInit {
  UserDetailsForm: FormGroup;

  public userId: string;

  public id: string;

  // public user_Id: any;
  public userDetails : any;



  constructor(private fb: FormBuilder, private users_service: UsersService, private route: ActivatedRoute, private router: Router) {
    
  }


  ngOnInit(): void {


    this.id = this.route.snapshot.paramMap.get('id');
    this.userId = this.id;
    this.ongetuserbyId()  


    // console.log(this.userDetails.id);
    this.UserDetailsForm = this.fb.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      email: [''],
      phoneNo: [''],
      createdDate: [''],
      roleID: [''],
      role: [''],
      isVerrified: ['']
    //   PersonalDetail: this.fb.group({
    //     fname: [' ',Validators.required],
    //     phone: [' '],
    //     email: [' '],
    //     gender: [' '],
    //     MaritalStatus: [' ', Validators.required],
    //   }),
      
    //   PermanentAddress: this.fb.group({
    //     address: [' '],
    //     state: [' '],
    //     pin_code: [' '],
    //     city: [' '],
    //     country: [' '],
    //   }),

    //   BankingInfo: this.fb.group({
    //     account_holderName: [' '],
    //     account_no: [' '],
    //     ifsc_Code: [' '],
    //     bank: [' '],
    //     branch: [' '],
    //   }),

    //   PanInfo: this.fb.group({
    //     pan_holder_name: [' '],
    //     date_of_birth: [' '],
    //     pan_no: [' '],
    //   }),

    //   EmercencyContact: this.fb.group({
    //     person_name: [' '],
    //     phone: [' '],
    //     relationship: [' '],
    //     address: [' '],
    //   }),

    //   EmployementInfo: this.fb.group({
    //     gender: [' '],
    //     education_qualification: [' '],
    //   }),
    // });
    })

    
    
  }
  onSubmit() {
    console.log(this.UserDetailsForm.value);

    if (this.UserDetailsForm.valid) {
      this.UserDetailsForm.reset();
    }
  }
 
  ongetuserbyId(){
    //  console.log(this.userId)
    return this.users_service.getuserbyId(this.userId).subscribe(
      (res) => {
        // console.log(res)
        // console.log(res[0].id)
        this.userDetails = res

        this.UserDetailsForm = this.fb.group({       
            id: res[0].id,
            firstName: res[0].firstName,
            lastName: res[0].lastName,
            userName: res[0].userName,
            email: res[0].email,
            phoneNo: res[0].phoneNo,
            createdDate: res[0].createdDate,
            roleID: res[0].roleID,
            role: res[0].role,
            isVerrified: res[0].isVerrified
          
        })
      }
    )
    }   
    
    

    

    
   
   onedituserDetails() {
    //  console.log(this.userId)
    //  console.log(this.userDetails)
      console.log(this.UserDetailsForm.get('phoneNo').value)
      //this.userDetails = this.UserDetailsForm
     this.userDetails[0].firstName = this.UserDetailsForm.get('firstName').value
     this.userDetails[0].lastName = this.UserDetailsForm.get('lastName').value
     this.userDetails[0].userName = this.UserDetailsForm.get('userName').value
     this.userDetails[0].email = this.UserDetailsForm.get('email').value
     this.userDetails[0].phoneNo =this.UserDetailsForm.get('phoneNo').value    
     this.userDetails[0].role = this.UserDetailsForm.get('role').value
     this.userDetails[0].isVerrified = true//this.UserDetailsForm.get('isVerrified').value
     
     return this.users_service.edituserDetails(this.userId.toString(), this.userDetails[0]).subscribe(
       (res) => {

        //this.userDetails = res
        console.log(res)

        if(res.status == 'Success'){
         alert('User updated successfully')
         this.router.navigateByUrl('/Users');

        }

        

       }
     )
   }

   


}
