import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { RouterModule, Routes,Router } from '@angular/router';

@Component({
  selector: 'app-search-rooms',
  templateUrl: './search-rooms.component.html',
  styleUrls: ['./search-rooms.component.scss']
})
export class SearchRoomsComponent implements OnInit {

 
  constructor(private router:Router) { }
  minValue: number = 1;
  maxValue: number = 25000;
  options: Options = {
    ceil: 25000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return 'Rs.' + value;
        case LabelType.High:
          return 'Rs.' + value;
        default:
          return 'Rs' + value;
      }
    }
  };

  ngOnInit(): void {
  }

  onclickRoomDetatils(){
this.router.navigate(['/room-details']);
  }

}

