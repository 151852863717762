<h1 class="heading">My Account</h1>
    <div class="row form-group">
      <div class="col-md-2 progress-back">
        <div style="margin-top: 20%;">
          <span class="profile">PROFILE COMPLETED</span>
          <div class="progress">
            <div class="progress-bar bg-dark" style="height: 10%;">100%</div>
          </div>
        </div>
  
        <p class="progress-percentage">100%</p>
      </div>
    
<div class="row">
    <div class="col-sm-3" style="color: black;"></div>
    <div class="col-sm-3"></div>
    <div class="col-sm-3"></div>
    <div class="col-sm-3"></div>
</div>
      

      