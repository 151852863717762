import { Component, OnInit } from '@angular/core';
import { AmenitiesService } from './amenities.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit {

  amenities = [];

  public amenetiesId;

  constructor(private amenities_service: AmenitiesService, private route: ActivatedRoute, private router: Router) {
    this.ongetAmenities()
   }

  ngOnInit(): void {
  }

  ongetAmenities() {
    this.amenities_service.getAmenities().subscribe(
      (res) => {
        console.log(res);
        this.amenities = res
        
      }
    )
  }

  onSelect(amenityId) {
   // console.log(amenityId)
   return this.router.navigate(['/AmenetiesDetails',amenityId])
    
  }

  onDeleteAmenities(amenetiesId) {
    console.log(amenetiesId)
    if(confirm("Are you sure you want to delete amenity")) {
    this.amenities_service.deleteAmenitiesDetails(amenetiesId).subscribe(
      (res) => {
        //console.log(res.id)
      }
    )
    }
    //this.router.navigateByUrl('/Amenities')
    // window.location.reload();
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    alert('User deleted successfully')

  }

}
