<!--<div *ngIf = "alert" class="alert alert-success">
  <strong>Deleted Successfully!!</strong><h3 *ngIf = "alert">You selected {{userId}}</h3>
</div>
<button *ngIf = "alert" (click) = "closeAlert()">OK</button>-->

<!-- <h2 mat-dialog-title>Deleted successfully</h2> -->
<!-- <mat-dialog-content *ngIf = "alert">Deleted Successfully</mat-dialog-content> -->
<mat-dialog-actions>
  <!-- <button mat-button mat-dialog-close>OK</button> -->
</mat-dialog-actions>

<div class="container-fluid" style="padding: 5% 12% 5% 12%;">
  <div class="col-sm-12 pd-lf">
    <div class="dash-panel-head">
      <span class="pull-right margin-10">
        <a
          class="btn active btn-default btn-create"
          [routerLink]="['/NewUser']"
          routerLinkActive="router-link-active"
          >New User</a
        >
      </span>

    </div>
    <div class="panel panel-default">
      <div class="panel-heading"><h4></h4></div>
      <div class="panel-body">
        <form class="form-horizontal"
              [formGroup] = "UsersForm">
          <input name="utf8" type="hidden" value="✓" />
          <div class="row form-group">
            <!-- <label class="control-label col-sm-3 col-md-2" for="id"
              >User Id</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                type="text"
                name="id"
                id="id"
                class="form-control"
                placeholder="Insert here..."
              />
            </div> -->
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="email"
              >Email</label
            >
            <div class="col-sm-4 col-md-4" >
              <input
                type="text"
                name="email"
                id="email"
                class="form-control"
                placeholder="Insert here..."
              />
            </div>
          </div>
          <div class="row form-group">
            <div class="control-label col-sm-3 col-md-2">Range</div>
            <div class="col-sm-9 col-md-10">
              <div class="checkbox-custom checkbox-system mb5">
                <input
                  type="checkbox"
                  name="verified"
                  id="checkbox1"
                  value="yes"
                />
                <label for="checkbox1">verified</label>
              </div>
            </div>
          </div>
          <div class="row form-group btn-top">
            <div class="col-sm-3 col-md-2"></div>
            <div class="col-sm-9 col-md-10">
              <div class="row">
                <div class="col-sm-2" 
                >
                  <input
                    type="submit"
                    name="commit"
                    value="Search"
                    class="btn active btn-default btn-search btn-block"
                    href = "/"
                  />
                </div>
                <div class="col-sm-2">
                  <a
                    class="btn active btn-default btn-block"
                    href="/Users"
                    >Reset</a
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- form -->
      </div>
    </div>
    <!-- panel panel-default -->

    <!-- panel panel-default -->
    <div class="panel panel-default">
      <div class="panel-body no-padding">
        <div class="table-responsive">
          <table class="table table-bordered table-striped all-user-table">
            <thead>
              <tr>
                <th class="thead">ID</th>
                <th class="thead">First Name</th>
                <th class="thead">Last Name</th>
                <th class="thead">UserName</th>
                <th class="thead">Email</th>
                <th class="thead">Phone no.</th>
                <th class="thead">Created Date</th>
                <th class="thead">RoleID</th>
                <th class="thead">Role</th>
                <th class="thead">Verified</th>
                <th class="thead">Action</th>
                <th class="thead">Delete</th>



              </tr>
            </thead>
            <tbody>
              <tr id="user_14" *ngFor = "let item of users">
                <td class="thead"
                >
                  {{item.id}}
                </td>
                <td class="thead">{{item.firstName}}</td>
                <td class="thead">{{item.lastName}}</td>
                <td class="thead">{{item.userName}}</td>
                <td class="thead">{{item.email}}</td>
                <td class="thead">{{item.phoneNo}}</td>
                <td class="thead">{{item.createdDate}}</td>
                <td class="thead">{{item.roleID}}</td>
                <td class="thead">{{item.role}}</td>
                <td class="thead">{{ item.isVerrified }}</td>
                <td class="thead">
                  <a style="cursor: pointer;" (click) = "onSelect(item.id)">Edit</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user"
                    href="javascript:"
                    (click) = "onDelete(item.id)"
                  >
                    <i class="fa fa-trash" ></i>
                  </a>
                </td>
              </tr>
              <!--<tr id="user_13">
                <td class="thead">
                  <a href="/">13</a>
                </td>
                <td class="thead">
                  <a href="/">Aditya Shrivastava</a>
                </td>
                <td class="thead">adityanext.291212@gmail.com</td>
                <td class="thead">customer</td>
                <td class="thead">08527129020</td>
                <td class="thead">09/04/2020</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-13"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_12">
                <td class="thead">
                  <a href="/">12</a>
                </td>
                <td class="thead">
                  <a href="/">Lal Mohan Jha</a>
                </td>
                <td class="thead">j02011958@gmail.com</td>
                <td class="thead">customer</td>
                <td class="thead">9313124943</td>
                <td class="thead">14/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-12"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_11">
                <td class="thead">
                  <a href="/">11</a>
                </td>
                <td class="thead">
                  <a href="/">Rachit Jha</a>
                </td>
                <td class="thead">jharachit2@gmail.com</td>
                <td class="thead">customer</td>
                <td class="thead">07093701467</td>
                <td class="thead">14/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-11"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_10">
                <td class="thead">
                  <a href="/">10</a>
                </td>
                <td class="thead">
                  <a href="/">Aman Tripathi</a>
                </td>
                <td class="thead">aman@yopmail.com</td>
                <td class="thead">customer</td>
                <td class="thead">9540075888</td>
                <td class="thead">13/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-10"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_9">
                <td class="thead">
                  <a href="/">9</a>
                </td>
                <td class="thead"><a href="/">Aman</a></td>
                <td class="thead">aman@gmail.com</td>
                <td class="thead">admin</td>
                <td class="thead">9540075888</td>
                <td class="thead">13/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-9"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_8">
                <td class="thead">
                  <a href="/">8</a>
                </td>
                <td class="thead">
                  <a href="/">DIPANSHU</a>
                </td>
                <td class="thead">srivastav94dipanshu@gmaill.com</td>
                <td class="thead">customer</td>
                <td class="thead">8707071382</td>
                <td class="thead">13/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-8"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_7">
                <td class="thead">
                  <a href="/">7</a>
                </td>
                <td class="thead">
                  <a href="/">smit pandya </a>
                </td>
                <td class="thead">smitsp07@gmail.com</td>
                <td class="thead">customer</td>
                <td class="thead">9929397123</td>
                <td class="thead">13/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-7"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_6">
                <td class="thead">
                  <a href="/">6</a>
                </td>
                <td class="thead">
                  <a href="/">Shinendra kumar</a>
                </td>
                <td class="thead">shinendra@stayhook.com</td>
                <td class="thead">customer</td>
                <td class="thead">8384061158</td>
                <td class="thead">12/12/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-6"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              <tr id="user_5">
                <td class="thead">
                  <a href="/">5</a>
                </td>
                <td class="thead"><a href="/">smit</a></td>
                <td class="thead">smitsp@stayhook.com</td>
                <td class="thead">customer</td>
                <td class="thead">9929397123</td>
                <td class="thead">21/10/2019</td>
                <td class="thead">
                  <a href="/">No</a>
                </td>
                <td class="thead">
                  <a
                    data-confirm="Are you sure you want to delete user?"
                    class="user_delete"
                    id="user_delete-5"
                    href="javascript:"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>-->
            </tbody>
          </table>
          <div class="apple_pagination">
            <div class="pagination">
              <span class="previous_page disabled">← Previous </span>
              <em class="current"> 1 </em>
              <a rel="next" href="/"> 2 </a>
              <a class="next_page" rel="next" href="/">Next →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End panel panel-default -->
  </div>
</div>
