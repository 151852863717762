import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Service/Users/auth.service';
import { InvoiceService } from '../invoice.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

  InvoiceDetailForm: FormGroup;

  public invoiceId;

  public invoiceDetails;

  IsEdited = false;

  constructor(private invoice_service: InvoiceService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) {}   

//   filterData1 =[
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"5000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"4000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"6000","balanceDue":"2000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"5000","balanceDue":"1500"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"3000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"5000","balanceDue":"3000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmeen Singh","age":"10 Days","amount":"8000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"5000","balanceDue":"2000"}
// ]

  ngOnInit(): void {


    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.invoiceId = id;
    this.ongetInvoicebyId()

    this.InvoiceDetailForm = this.fb.group({
      id: [''],
      invoiceDate: [''],
      transactionId: [''],
      invoiceType: [''],
      invoiceStatus: [''],
      customerId: ['', Validators.required],
      age: 20,
      amount: 20000,
      balanceDue: 12000,
      modeOfPayment: [''],
      billToAddress: [''],
      shipToAddress: [''],
      invoiceDescription: [''],
      createdBy: [''],
      createdOn: [''],
      moifiedBy: [''],
      modifiedOn: [''],
      isActive: [''],
      customer: ['']


    })
  }

  ongetInvoicebyId() {
   this.invoice_service.getInvoicebyId(this.invoiceId).subscribe(
     res => {
       this.invoiceDetails = res;
       this.InvoiceDetailForm = this.fb.group({
        id: res.id,
        invoiceDate: res.invoiceDate,
        transactionId: res.transactionId,
        invoiceType: res.invoiceType,
        invoiceStatus: res.invoiceStatus,
        customerId: res.customerId,
        age: res.age,
        amount: res.amount,
        balanceDue: res.balanceDue,
        modeOfPayment: res.modeOfPayment,
        billToAddress: res.billToAddress,
        shipToAddress: res.shipToAddress,
        invoiceDescription: res.invoiceDescription,
        createdBy: res.createdBy,
        createdOn: res.createdOn,
        moifiedBy: res.moifiedBy,
        modifiedOn: res.modifiedOn,
        isActive: res.isActive,
        customer: res.customer
  
  
      })

     }
   )
  }

  oneditInvoiceDetails() {
    this.invoiceDetails.invoiceDate = this.InvoiceDetailForm.get('invoiceDate').value
    this.invoiceDetails.transactionId = this.InvoiceDetailForm.get('transactionId').value
    this.invoiceDetails.invoiceType = this.InvoiceDetailForm.get('invoiceType').value
    this.invoiceDetails.invoiceStatus = this.InvoiceDetailForm.get('invoiceStatus').value
    this.invoiceDetails.customerId = this.InvoiceDetailForm.get('customerId').value
    this.invoiceDetails.age = this.InvoiceDetailForm.get('age').value
    this.invoiceDetails.amount = this.InvoiceDetailForm.get('amount').value
    this.invoiceDetails.balanceDue = this.InvoiceDetailForm.get('balanceDue').value
    this.invoiceDetails.modeOfPayment = this.InvoiceDetailForm.get('modeOfPayment').value
    this.invoiceDetails.billToAddress = this.InvoiceDetailForm.get('billToAddress').value
    this.invoiceDetails.shipToAddress = this.InvoiceDetailForm.get('shipToAddress').value
    this.invoiceDetails.invoiceDescription = this.InvoiceDetailForm.get('invoiceDescription').value
    // console.log(this.InvoiceDetailForm.get('invoiceDescription').value)
    this.invoice_service.editInvoiceDetails(this.invoiceId, this.invoiceDetails).subscribe(
      res => {
        console.log(res)
         if(res.status == 'Success'){
          this.IsEdited = true;
          alert('Updated successfully')
          this.router.navigateByUrl('/InvoiceList')
         }
      }
    )
  }


}