import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AmenitiesService } from '../amenities/amenities.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-amenitiesdetails',
  templateUrl: './amenitiesdetails.component.html',
  styleUrls: ['./amenitiesdetails.component.scss']
})
export class AmenitiesdetailsComponent implements OnInit {
  AmenitiesDetailsForm: FormGroup;

  IsEdited = false;


  public amenitiesId;

  public amenitiesDetails;


  constructor(private amenities_service: AmenitiesService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
   
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.amenitiesId = id;
    this.ongetAmenitiesById()  

    this.AmenitiesDetailsForm = this.fb.group({
      id: [''],
      name: [''],
      dorLivingRoom: ['']
    })
  }

  ongetAmenitiesById() {
    this.amenities_service.getAmenetiesbyId(this.amenitiesId).subscribe(
       (res) => {
        this.amenitiesDetails = res;
        //  console.log(res.id)
         this.AmenitiesDetailsForm = this.fb.group({
          id: res.id,
          name: res.name,
          dorLivingRoom: res.dorLivingRoom
        })
       }
    )
  }

  oneditAmenitiesDetails() {
    console.log(this.amenitiesId)
    //console.log(this.amenitiesDetails)
    console.log(this.AmenitiesDetailsForm.get('name').value)
    this.amenitiesDetails.name = this.AmenitiesDetailsForm.get('name').value
    this.amenitiesDetails.dorLivingRoom = this.AmenitiesDetailsForm.get('dorLivingRoom').value
    this.amenities_service.editAmenitiesDetails(this.amenitiesId, this.amenitiesDetails).subscribe(
      (res) => {
        console.log(res.status)
          // console.log(this.amenitiesId)
          // console.log(this.amenitiesDetails.value)
          if (res.status == 'Success'){
            this.IsEdited = true;
            alert('Amenity Updated successfully');
            this.router.navigateByUrl('/Amenities')
          }
      }
    )

    
  }

}
