<div class="container-fluid" style="padding: 5% 0% 5% 0%;">
  <div class="container">
    <div class="card">
      <div class="card-header">Invoice Details</div>
      <div class="panel-body show-com">
       <form [formGroup]= "InvoiceDetailForm"
         autocomplete="off" class="form-horizontal" id="new_user">
          <input name="utf8" type="hidden" value="✓" /><input
            type="hidden"
            name="authenticity_token"
          />
          <div class="row form-group">
              <label class="control-label col-sm-3 col-md-2" for="user_first"
                >ID</label
              >
              <div class="col-sm-4 col-md-4">
                <input
                  class="form-control"
                  type="text"
                  name="id"
                  formControlName="id"
                  id="id_id"
                />
              </div>
            </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_first"
              >INVOICE DATE</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="invoiceDate"
                formControlName="invoiceDate"
                id="invoiceDate_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_lastName"
              >TRANSACTION ID</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="transactionId"
                formControlName="transactionId"
                id="transactionId_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_username"
              >INVOICE TYPE</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="invoiceType"
                formControlName="invoiceType"
                id="invoiceType_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_email"
              >INVOICE STATUS</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="invoiceStatus"
                formControlName="invoiceStatus"
                id="invoiceStatus_id"
              />
              <span class="error"></span>
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_phone"
              >CUSTOMER ID</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="customerId"
                formControlName="customerId"
                id="customerId_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_role"
              >AGE</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="age"
                formControlName="age"
                id="age_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >AMOUNT</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="amount"
                formControlName="amount"
                id="amount_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >BALANCE DUE</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="balanceDue"
                formControlName="balanceDue"
                id="balanceDue_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >MODE OF PAYMENT</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="modeOfPayment"
                formControlName="modeOfPayment"
                id="modeOfPayment_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >BILL TO ADDRESS</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="billToAddress"
                formControlName="billToAddress"
                id="billToAddress_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >SHIP TO ADDRESS</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="shipToAddress"
                formControlName="shipToAddress"
                id="shipToAddress_id"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >INVOICE DESCRIPTION</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
              class="form-control"
                type="text"
                name="invoiceDescription"
                formControlName="invoiceDescription"
                id="invoiceDescription_id"
              />
            </div>
          </div>

          
          <div class="row form-group btn-top">
            <div class=" col-sm-3  col-md-2"></div>
            <div class=" col-sm-9  col-md-10">
              <div class="row">
                <div class="col-sm-2">
                  <input
                    type="submit"
                    name="commit"
                    value="Edit"
                    class="btn active btn-system btn-block btn_comty"
                    (click)= "oneditInvoiceDetails()"
                  />
                </div>
                <div class="col-sm-2">
                 <a class="btn active btn-default btn-block"
                    [routerLink]="['/InvoiceList']"
                    routerLinkActive="router-link-active"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
