import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-your-house',
  templateUrl: './list-your-house.component.html',
  styleUrls: ['./list-your-house.component.scss']
})
export class ListYourHouseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
