<div class="container-xl">
    <div class="vertical-tabs">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#payment1" role="tab" aria-controls="home">UPCOMING PAYMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#payment2" role="tab" aria-controls="profile">PAST PAYMENTS</a>
        </li>
      </ul>
      
      <div class="tab-content">
        <div class="tab-pane active" id="payment1" role="tabpanel">
          <div class="sv-tab-panel">
              <ul>
                <li> 
                  <p class="logo"> M</p>
                </li>
                <li>
                  <p class="light">DUE DATA</p>
                  <P class="bold">DUE SINCE 2 MAY 2018</P>
                </li>
                <li>
                  <P class="light">RECEIPT</P>
                  <P class="bold">GLKJSLKFJLKSDFHLD</P>
                </li>
                <li>
                  <P class="light">AMOUNT</P>
                  <P class="bold">3000/-</P>
                </li>
                <li>
                  <mat-checkbox></mat-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="logo"> M</p>
                </li>
                <li>
                  <p class="light">DUE DATA</p>
                  <P class="bold">DUE SINCE 2 MAY 2018</P>
                </li>
                <li>
                  <P class="light">RECEIPT</P>
                  <P class="bold">GLKJSLKFJLKSDFHLD</P>
                </li>
                <li>
                  <P class="light">AMOUNT</P>
                  <P class="bold">3000/-</P>
                </li>
                <li>
                  <mat-checkbox></mat-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="logo"> M</p>
                </li>
                <li>
                  <p class="light">DUE DATA</p>
                  <P class="bold">DUE SINCE 2 MAY 2018</P>
                </li>
                <li>
                  <P class="light">RECEIPT</P>
                  <P class="bold">GLKJSLKFJLKSDFHLD</P>
                </li>
                <li>
                  <P class="light">AMOUNT</P>
                  <P class="bold">3000/-</P>
                </li>
                <li>
                  <mat-checkbox></mat-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="logo"> M</p>
                </li>
                <li>
                  <p class="light">DUE DATA</p>
                  <P class="bold">DUE SINCE 2 MAY 2018</P>
                </li>
                <li>
                  <P class="light">RECEIPT</P>
                  <P class="bold">GLKJSLKFJLKSDFHLD</P>
                </li>
                <li>
                  <P class="light">AMOUNT</P>
                  <P class="bold">3000/-</P>
                </li>
                <li>
                  <mat-checkbox></mat-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="logo"> M</p>
                </li>
                <li>
                  <p class="light">DUE DATA</p>
                  <P class="bold">DUE SINCE 2 MAY 2018</P>
                </li>
                <li>
                  <P class="light">RECEIPT</P>
                  <P class="bold">GLKJSLKFJLKSDFHLD</P>
                </li>
                <li>
                  <P class="light">AMOUNT</P>
                  <P class="bold">3000/-</P>
                </li>
                <li>
                  <mat-checkbox></mat-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <mat-form-field class="example-full-width">
                    <mat-label>REFERAL & COUPON CODE</mat-label>
                    <input matInput  value="" style="width: 100px;">
                  </mat-form-field>
                </li>
                <li>
                  <button>APPLY NOW</button>
                </li>
                <li>
                  <p class="total">TOTAL AMOUNT . <b>Rs. 1200/-</b></p>
                </li>
                <li>
                  <button class="btn-pay">PAY NOW</button>
                </li>
              </ul>
          </div>
        </div>
        <div class="tab-pane" id="payment2" role="tabpanel">
          <div class="sv-tab-panel">
            <ul>
              <li>
                <p class="light">12/05/2020</p>
                <P class="bold">PAYMENT FOR MAY 2018</P>
              </li>
              <li>
                <P class="light">TYPE</P>
                <P class="bold">SECURITY DEPOSIT</P>
              </li>
              <li>
                <P class="light">AMOUNT</P>
                <P class="bold">3000/-</P>
              </li>
              <li style="padding: 22px 0px;">
                <p class="light sec-1">inclusive of all GST taxes 
                  <mat-icon class="icons-1">error_outline</mat-icon>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="light">12/05/2020</p>
                <P class="bold">PAYMENT FOR JUNE 2018</P>
              </li>
              <li>
                <P class="light">RECIPT</P>
                <P class="bold">EXTRA EXPENSES</P>
              </li>
              <li>
                <P class="light">AMOUNT</P>
                <P class="bold">3000/-</P>
              </li>
              <li style="padding: 22px 0px;">
                <p class="light sec-1">inclusive of all GST taxes 
                  <mat-icon class="icons-1">error_outline</mat-icon>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="light">12/05/2020</p>
                <P class="bold">PAYMENT FOR JULY 2018</P>
              </li>
              <li>
                <P class="light">RECEIPT</P>
                <P class="bold">MAINTENANCE</P>
              </li>
              <li>
                <P class="light">AMOUNT</P>
                <P class="bold">3000/-</P>
              </li>
              <li style="padding: 22px 0px;">
                <p class="light sec-1">inclusive of all GST taxes 
                  <mat-icon class="icons-1">error_outline</mat-icon>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="light">12/05/2020</p>
                <P class="bold">PAYMENT FOR AUGUST 2018</P>
              </li>
              <li>
                <P class="light">RECEIPT</P>
                <P class="bold">RENT</P>
              </li>
              <li>
                <P class="light">AMOUNT</P>
                <P class="bold">3000/-</P>
              </li>
              <li style="padding: 22px 0px;">
                <p class="light sec-1">inclusive of all GST taxes 
                  <mat-icon class="icons-1">error_outline</mat-icon>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="light">12/05/2020</p>
                <P class="bold">PAYMENT FOR MAY 2018</P>
              </li>
              <li>
                <P class="light">TYPE</P>
                <P class="bold">SECURITY DEPOSIT</P>
              </li>
              <li>
                <P class="light">AMOUNT</P>
                <P class="bold">3000/-</P>
              </li>
              <li style="padding: 22px 0px;">
                <p class="light sec-1">inclusive of all GST taxes 
                  <mat-icon class="icons-1">error_outline</mat-icon>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>