import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Service/Users/auth.service';
import { InvoiceService } from '../Admin/invoice/invoice.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DEFAULT_RESIZE_TIME } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss']
})
export class NewInvoiceComponent implements OnInit {

  InvoiceDetailForm: FormGroup;

  isSaved = false;
  
  invoiceDate: any;



  constructor(private invoice_service: InvoiceService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.InvoiceDetailForm = this.fb.group({
      invoiceDate: this.invoiceDate,
      transactionId: ['', Validators.required],
      invoiceType: ['', Validators.required],
      invoiceStatus: [''],
      customerId: [''],
      age: 20,
      amount: 20000,
      balanceDue: 12000,
      modeOfPayment: [''],
      billToAddress: [''],
      shipToAddress: [''],
      invoiceDescription: ['']

    })
  }

  onsaveInvoiceDetails(){
    this.invoice_service.saveInvoiceDetails(this.InvoiceDetailForm.value).subscribe(
      res => {
        if((res.transactionId != null) && (res.customerId != null) && (res.invoiceType != null)){
            this.isSaved = true;
            alert('Invoice saved successfully');
            this.router.navigateByUrl('/InvoiceList')
        }
      }
    )
  }

}
