import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

  invoices = [];

  constructor(private invoice_service: InvoiceService, private router: Router, private route: ActivatedRoute) {
    this.ongetInvoice()
   }

//   filterData =[
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmen Singh","age":"10 Days","amount":"5000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"4000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"6000","balanceDue":"2000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmen Singh","age":"10 Days","amount":"5000","balanceDue":"1500"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmen Singh","age":"10 Days","amount":"3000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Anup Singh","age":"10 Days","amount":"5000","balanceDue":"3000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Asmen Singh","age":"10 Days","amount":"8000","balanceDue":"1000"},
//     {"date":"01-02-2021","transaction":"INV-2201","type":"Invoice","status":"overdue","customerName":"Rishi Singh","age":"10 Days","amount":"5000","balanceDue":"2000"}
// ]
  ngOnInit(): void {
  }

  onSelect(invoiceId) {
    return this.router.navigate(['/InvoiceDetail',invoiceId])
     
   }

  ongetInvoice() {
    this.invoice_service.getInvoice().subscribe(
      (res) => {
        this.invoices = res
      }
    )
  }

  onDeleteInvoiceDetails(invoiceId) {
    if(confirm('Are you sure you want to delete Invoice?')) {
      this.invoice_service.deleteInvoiceDetails(invoiceId).subscribe(
        res => {
          console.log(res)
        }
      )
    }
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    alert('Invoice deleted successfully')
   }
  }


