<div class="container-fluid" style="padding: 5% 0% 5% 0%;">
  <div class="container">
    <div class="card">
      <div class="card-header">New User Form</div>
      <div class="panel-body show-com">
        <form [formGroup] = "NewUserDetails"
        autocomplete="off" class="form-horizontal" id="new_user">
          <input name="utf8" type="hidden" value="✓" /><input
            type="hidden"
            name="authenticity_token"
          />
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_first"
              >First Name</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                required="required"
                type="text"
                name="firstName"
                formControlName="firstName"
                id="user_firstName"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_lastName"
              >Last Name</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                required="required"
                type="text"
                name="lastName"
                formControlName="lastName"
                id="user_lastName"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_username"
              >UserName</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                required="required"
                type="text"
                name="userName"
                formControlName="userName"
                id="user_userName"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_email"
              >Email</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                required="required"
                type="text"
                name="email"
                formControlName="email"
                id="user_email"
              />
              <span class="error"></span>
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_phone"
              >Phone No.</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                type="text"
                name="phoneNo"
                formControlName="phoneNo"
                id="userphoneNo"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_role"
              >Role</label
            >
            <div class="col-sm-4 col-md-4">
              <input
                class="form-control"
                required="required"
                type="text"
                name="role"
                formControlName="role"
                id="user_role"
              />
            </div>
          </div>
          <div class="row form-group">
            <label class="control-label col-sm-3 col-md-2" for="user_verified"
              >Verified</label
            >
            <div class="col-sm-4 col-md-4">
              <input name="verified" type="hidden" value="0" /><input
                type="checkbox"
                value="0"
                name="verified"
                formControlName="isVerrified"
                id="user_verified"
              />
            </div>
          </div>
          <div class="row form-group btn-top">
            <div class=" col-sm-3  col-md-2"></div>
            <div class=" col-sm-9  col-md-10">
              <div class="row">
                <div class="col-sm-2">
                  <input
                    type="submit"
                    name="commit"
                    value="Create"
                    class="btn active btn-system btn-block btn_comty"
                  (click) = "onsaveuserDetails()"/>
                </div>
                <div class="col-sm-2">
                  <a
                    class="btn active btn-default btn-block"
                    [routerLink]="['/Users']"
                    routerLinkActive="router-link-active"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
