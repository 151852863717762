import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Service/Users/auth.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {

  // abc:boolean=true;

  constructor(public _authService : AuthService) { }

  ngOnInit(): void {

      var header = document.getElementById("myDIV");
      var menu_navs = header.getElementsByClassName("menu_nav");
      for (var i = 0; i < menu_navs.length; i++) {
      menu_navs[i].addEventListener("click", function() {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active", "");
      this.className += " active";
      });
    }
  }

  openNav(){
    document.getElementById("myNav").style.height = "auto";
    // this.abc=false;
  }
  
  closeNav(){
    document.getElementById("myNav").style.height = "0%";
    // this.abc=true;
  }

}
