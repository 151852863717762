import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-users-info',
  templateUrl: './users-info.component.html',
  styleUrls: ['./users-info.component.scss']
})
export class UsersInfoComponent implements OnInit {

  PersonalDetailsForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {

    this.PersonalDetailsForm = this.fb.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      email: [''],
      phoneNo: [''],
      createdDate: [''],
      roleID: [''],
      role: [''],
      isVerrified: [''],

      PersonalDetail: this.fb.group({
        // fname: [' '],
        // phone: [' '],
        // email: [' '],
        // gender: [' '],
        MaritalStatus: [' '],
      }),
      
      PermanentAddress: this.fb.group({
        address: [' '],
        state: [' '],
        pin_code: [' '],
        city: [' '],
        country: [' '],
      }),

      BankingInfo: this.fb.group({
        account_holderName: [' '],
        account_no: [' '],
        ifsc_Code: [' '],
        bank: [' '],
        branch: [' '],
      }),

      PanInfo: this.fb.group({
        pan_holder_name: [' '],
        date_of_birth: [' '],
        pan_no: [' '],
      }),

      EmercencyContact: this.fb.group({
        person_name: [' '],
        phone: [' '],
        relationship: [' '],
        address: [' '],
      }),

      EmployementInfo: this.fb.group({
        gender: [' '],
        education_qualification: [' '],
      }),
    });
    


  }

}
