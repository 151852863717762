<div class="row">
    <div class="col-md-6">
        <div class="m-3  mat-elevation-z6">
            <div class="img-section">
                <img src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt="house image">
                <p class="part-1">RENT/MONTH</p>
                <p class="part-2"><b>Rs. 4,000 - Rs. 10,000</b></p>
            </div>
            <div class="text-section">
                <b>3 BHK APARTMENT 2 MILES AWAY</b>
                <p>1,200 Sq Ft. 3 bathrooms . Fully Furnished</p>
                <button class="btn1">Rating</button>
                <button class="btn2">GOOD</button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="m-3  mat-elevation-z6">
            <div class="img-section">
                <img src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt="house image">
                <p class="part-1">RENT/MONTH</p>
                <p class="part-2"><b>Rs. 4,000 - Rs. 10,000</b></p>
            </div>
            <div class="text-section">
                <b>3 BHK APARTMENT 2 MILES AWAY</b>
                <p>1,200 Sq Ft. 3 bathrooms . Fully Furnished</p>
                <button class="btn1">Rating</button>
                <button class="btn2">GOOD</button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="m-3  mat-elevation-z6">
            <div class="img-section">
                <img src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt="house image">
                <p class="part-1">RENT/MONTH</p>
                <p class="part-2"><b>Rs. 4,000 - Rs. 10,000</b></p>
            </div>
            <div class="text-section">
                <b>3 BHK APARTMENT 2 MILES AWAY</b>
                <p>1,200 Sq Ft. 3 bathrooms . Fully Furnished</p>
                <button class="btn1">Rating</button>
                <button class="btn2">GOOD</button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="m-3  mat-elevation-z6">
            <div class="img-section">
                <img src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt="house image">
                <p class="part-1">RENT/MONTH</p>
                <p class="part-2"><b>Rs. 4,000 - Rs. 10,000</b></p>
            </div>
            <div class="text-section">
                <b>3 BHK APARTMENT 2 MILES AWAY</b>
                <p>1,200 Sq Ft. 3 bathrooms . Fully Furnished</p>
                <button class="btn1">Rating</button>
                <button class="btn2">GOOD</button>
            </div>
        </div>
    </div>
</div>