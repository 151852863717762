<div class="container-fluid" style="padding: 5% 12% 5% 12%;">
  <div class="container-fluid" style="
      margin: 0;
      padding: 0;
      overflow-y: hidden !important;
      height: 100%;
      overflow-x: hidden;
    ">
    <div class="row">
      <div class="col-md-4 vendordashside" style="margin-bottom: -99999px; padding-bottom: 100250px;">
        <div>
          <img style="height: 200px;" src="/assets/img/newhouse1.svg" alt="House" />
        </div>
        <div class="vdstext">
          <div>FLEXIBILTY</div>
          <div style="margin-top: 20px; font-size: 16px; font-weight: normal;">
            Creativity has always depended on openness and flexibility, so let
            us hope for more of both in the future.
          </div>
        </div>
      </div>
      <div class="col-md-8 vendordashcontent vendordashcontent1"
        style="width: 70.3%; margin-bottom: -99999px; padding-bottom: 99940px;">
        <div class="container-fluid" style="margin: 0; padding: 0;">
          <div class="col-md-6" style="margin-left: 0; padding-left: 0;">
            <div style="font-size: 28px; font-weight: 600;">
              List Your House<br>
              <p style="font-size: 18px;
              font-weight: 500;
              color: #00000080;
              margin-top: 10px;">We need basic details of you</p>
            </div>
          </div>
          <div class="col-md-6">
            <div style="
                font-size: 15px;
                font-weight: 600;
                color: rgb(146, 146, 146);
                margin-top: 20px;
              ">
              <span>DONE WITH YOUR LISTING</span>
            </div>
          </div>
        </div>
        <div class="stpper_house">
          <mat-horizontal-stepper [linear]="isLinear" #stepper>

            <!--Slide-1-->
            <mat-step>
              <form action="" [formGroup] = "HouseDetails">
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                      <mat-label>FULL NAME</mat-label>
                      <input matInput placeholder="Enter your name"
                      formControlName = "firstName" required >
                      <!-- <small *ngIf = "HouseDetails.get('firstName').invalid" class="text-danger">Please enter name</small> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                      <mat-label>EMAIL</mat-label>
                      <input matInput placeholder="abcd@gmail.com"
                      formControlName = "email" type="url" required>
                      <small *ngIf = "HouseDetails.get('email').invalid  && HouseDetails.get('email').touched" >Please enter valid email id</small>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>PHONE</mat-label>
                      <input matInput placeholder="+911234567891"
                      formControlName = "phoneNo" type="tel" required>
                      <small *ngIf = "HouseDetails.get('phoneNo').invalid  && HouseDetails.get('phoneNo').touched" >Please enter valid number</small>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>PROPERTY</mat-label>
                      <input matInput placeholder="Enter your address" 
                      formControlName = "lastName" required>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            
              <div class="row example-full-width">
                <!-- <div class="col-md-4" style="display: flex;margin-top: 15px;">
                  <span style="margin-top: 2px; font-size: 15px;font-weight: 700;color: #0000008c;padding: 0 10px 0 0;">Step 1/5</span>  
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div> -->
                <div class="col-md-12">
                  <button mat-button matStepperNext class="vendornextbtn">
                    NEXT
                  </button>
                  <!-- <button mat-button matStepperPrevious class="vendorpreviousbtn">
                    NEXT
                  </button> -->
                </div>
              </div>
            </mat-step>

            <!--Slide-2-->
            <mat-step>

              <div style="margin-top: 50px;">
                <div>
                  <div style="margin-top: 50px; font-size: 18px; font-weight: 500;">
                    What type of house do you have?
                  </div>
                  <div class="container-fluid" style="
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    margin-top: 50px;
                    margin-left: -4% !important;
                    font-size: 14px;
                  ">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="App_1" style="margin-top: 25px; margin-bottom: 30px;">
                          APARTMENT
                        </div>
                        <label class="image-radio1">
                          <img class="img-responsive img_1" src="/assets/img/newhouse2.svg" />
                          <input type="radio" name="img_radio" value="1" 
                          />
                          <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <div class="App_1" style="margin-top: 25px; margin-bottom: 30px;">
                          VILLA
                        </div>
                        <label class="image-radio1">
                          <img class="img-responsive img_1" src="/assets/img/newhouse2.svg" />
                          <input type="radio" name="img_radio" value="2"
                          />
                          <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <div class="App_1" style="margin-top: 25px; margin-bottom: 30px;">
                          INDEPEDENT HOUSE
                        </div>
                        <label class="image-radio1">
                          <img class="img-responsive" src="/assets/img/newhouse2.svg" />
                          <input type="radio" name="img_radio" value="3"
                          />
                          <i class="glyphicon glyphicon-ok hidden hidden_1"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="App_2">
                  <div style="margin-top: 50px; font-size: 18px; font-weight: 500;">
                    Is currenty someone staying at your home?
                  </div>
                  <div class="container-fluid" style="
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    margin-top: 50px;
                    margin-left: -4% !important;
                    font-size: 14px;
                  ">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="text-radio1">
                          <div class="currentlystayingbox">I'm Staying</div>
                          <input type="radio" name="text_radio" value="1"
                          />
                          <i class="glyphicon glyphicon-ok hidden"></i>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <label class="text-radio1">
                          <div class="currentlystayingbox2">Tenant Staying</div>
                          <input type="radio" name="text_radio" value="2"
                          />
                          <i class="glyphicon glyphicon-ok hidden"></i>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <label class="text-radio1">
                          <div class="currentlystayingbox">It's Vacant</div>
                          <input type="radio" name="text_radio" value="3"
                           />
                          <i class="glyphicon glyphicon-ok hidden"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row example-full-width" style="margin-bottom: 50px;">
                <!-- <div class="col-md-4" style="display: flex;margin-top: 15px;">
                  <span style="margin-top: 2px; font-size: 15px;font-weight: 700;color: #0000008c; padding: 0 10px 0 0;">Step 1/5</span>  
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div> -->
                <div class="col-md-12">
                  <button mat-button matStepperNext class="vendornextbtn">
                    Next
                  </button>
                  <button mat-button matStepperPrevious class="vendorpreviousbtn">
                    Previous
                  </button>
                </div>
              </div>
            </mat-step>

            <!--Slide-3-->
            <!-- <mat-step>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <mat-label> AREA SQ FT. </mat-label>
                    <input matInput placeholder="Area of sq. ft.">
                  </mat-form-field>
                </div>
              </div>
              <div class="container-fluid" style="
                margin: 0;
                padding: 0;
                text-align: center;
                margin-top: 50px;
                margin-left: -4% !important;
                font-size: 14px;
              ">
                <div class="row">
                  <div class="col-md-4 border_1" style="border-right: solid 1px #d2d2d2;">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse4.svg" />
                      <input type="radio" name="img_radio" value="furnish">
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      FURNISHED
                    </div>
                  </div>
                  <div class="col-md-4 border_1" style="border-right: solid 1px #d2d2d2;">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse5.svg" />
                      <input type="radio" name="img_radio" value="semi-furnish">
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      SEMI-FURNISHED
                    </div>
                  </div>
                  <div class="col-md-4 border_1">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse5.svg" />
                      <input type="radio" name="img_radio" value="semi-furnish" #Unfurnished>
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      UNFURNISHED
                    </div>
                  </div>
                </div>
              </div>


              <div class="container" *ngIf="Unfurnished.checked">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="textrooms">UNFURNISHED ROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button" (click)="UnFurnishRoomMinus()"
                          (click)="onclickMinusUnRoomBed(s)">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="Unfurnish_room_quantity" />
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="UnFurnishRoomPlus()"
                          (click)="onclickPlusUnRoomBed()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h5 class="textrooms">UNFURNISHED WASHROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button"
                          (click)="SemiFurnishWashRoomMinus()">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="semifurnish_washroom_quantity" />
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="SemiFurnishWashRoomPlus()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngFor="let rl of Unroombedarray; let s = index">
                  <h5 class="textrooms">BEDS IN SEMI FURNISHED ROOM {{s+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ s }}" [(ngModel)]="rl.semibed" />
                  </div>
                </div>
              </div>

              <input type="text" (change)="true" #groupRef style="display: none;">
              <div class="row example-full-width" style="margin-bottom: 50px;">
                <div class="col-md-4" style="display: flex;margin-top: 15px;">
                  <span style="margin-top: 2px; font-size: 15px;font-weight: 700;padding: 0 10px 0 0; color: #0000008c;">Step 1/5</span>  
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div>
                <div class="col-md-12">
                  <button mat-button matStepperNext class="vendornextbtn">
                    Next
                  </button>
                  <button mat-button matStepperPrevious class="vendorpreviousbtn">
                    Previous
                  </button>
                </div>
              </div>

            </mat-step> -->

            <!--Slide-4-->
            <mat-step>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <mat-label> AREA SQ FT. </mat-label>
                    <input matInput placeholder="Area of sq. ft." required>
                  </mat-form-field>
                </div>
              </div>
              <div class="container-fluid" style="
                margin: 0;
                padding: 0;
                text-align: center;
                margin-top: 50px;
                margin-left: -4% !important;
                font-size: 14px;
              ">
                <div class="row">
                  <div class="col-md-4 border_1" style="border-right: solid 1px #d2d2d2;">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse4.svg" />
                      <input type="radio" name="img_radio" value="furnish" #furnished>
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      FURNISHED
                    </div>
                  </div>
                  <div class="col-md-4 border_1" style="border-right: solid 1px #d2d2d2;">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse5.svg" />
                      <input type="radio" name="img_radio" value="semi-furnish" #semifurnished>
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      SEMI-FURNISHED
                    </div>
                  </div>
                  <div class="col-md-4 border_1">
                    <label class="image-radio2">
                      <img class="img-responsive2" src="/assets/img/newhouse5.svg" />
                      <input type="radio" name="img_radio" value="semi-furnish">
                      <i class="glyphicon glyphicon-ok hidden"></i>
                    </label>
                    <div style="margin-top: 25px; margin-bottom: 30px;">
                      UNFURNISHED
                    </div>
                  </div>
                </div>
              </div>


              <h5 class="textrooms">PRICE OF FULL HOUSE</h5>
              <div class="input-group mb-3 number-spinner">
                <input type="text" class="form-control text-center" name="full_house"/>
              </div>


              <div class="container" *ngIf="furnished.checked">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="textrooms">FURNISHED ROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button" (click)="FurnishRoomMinus()"
                          (click)="onclickMinusRoomBed(i)" (click)="onclickMinusRoomBedPrice(i)" (click)="onclickMinusFullRoomPrice">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="furnish_room_quantity"  [value]="furnish_room_price" 
                      [value]="furnish_bed_price"/>
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="FurnishRoomPlus()"
                          (click)="onclickPlusRoomBed()" (click) = "onclickPlusRoomBedPrice()" (click) = "onclickPlusFullRoomPrice">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
            
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h5 class="textrooms">FURNISHED WASHROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button" (click)="FurnishWashRoomMinus()">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="furnish_washroom_quantity" />
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="FurnishWashRoomPlus()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>



                <div *ngFor="let rl of roombedarray and priceroombedarray and pricefullroomarray ; let i = index">
                  <h5 class="textrooms">BEDS IN FURNISHED ROOM {{i+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ i }}" [(ngModel)]="rl.bed" />
                  </div>
                  <h5 class="textrooms">PRICE PER BED {{i+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ i }}" [(ngModel)]="rl.bed" />
                  </div>
                  <h5 class="textrooms">PRICE - PRIVATE ROOM {{i+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ i }}" [(ngModel)]="rl.bed" />
                  </div>
                </div>  
              </div> 



              <div class="container" *ngIf="semifurnished.checked">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="textrooms">SEMIFURNISHED ROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button" (click)="SemiFurnishRoomMinus()"
                          (click)="onclickMinusSemiRoomBed(s)" (click) = "onClickMinusPriceSemiRoomBed(s)" 
                          (click)="onClickMinusPriceFullSemiRoom(s)">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="semifurnish_room_quantity" [value]="semifurnish_room_price"
                      [value]="semifurnish_bed_price"/>
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="SemiFurnishRoomPlus()"
                          (click)="onclickPlusSemiRoomBed()" (click)="onClickPlusPriceSemiRoomBed()"
                          (click)="onClickPlusPriceFullSemiRoom()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h5 class="textrooms">SEMIFURNISHED WASHROOMS</h5>
                    <div class="input-group mb-3 number-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-danger" data-dir="dwn" type="button"
                          (click)="SemiFurnishWashRoomMinus()">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control text-center" [value]="semifurnish_washroom_quantity" />
                      <div class="input-group-append">
                        <button class="btn btn-danger" data-dir="up" type="button" (click)="SemiFurnishWashRoomPlus()">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngFor="let rl of semiroombedarray and pricesemiroombedarray and pricefullsemiroomarray; let s = index">
                  <h5 class="textrooms">BEDS IN SEMI FURNISHED ROOM {{s+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ s }}" [(ngModel)]="rl.semibed" />
                  </div>
                  <h5 class="textrooms">PRICE PER BED {{s+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ s }}" [(ngModel)]="rl.semibed" />
                  </div>
                  <h5 class="textrooms">PRICE - SEMI FURNISHED PRIVATE ROOM {{s+1}}</h5>
                  <div class="input-group mb-3 number-spinner">
                    <input type="text" class="form-control text-center" name="bed{{ s }}" [(ngModel)]="rl.semibed" />
                  </div>
                </div>
              </div>

              <input type="text" (change)="true" #groupRef style="display: none;">
              <div class="row example-full-width"style="margin-bottom: 50px;">
                 <!--<div class="col-md-4" style="display: flex;margin-top: 15px;">
                  <span style="margin-top: 2px; font-size: 15px; padding: 0 10px 0 0;font-weight: 700;color: #0000008c;">Step 1/5</span>  
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div> -->
                <div class="col-md-12">
                  <button mat-button matStepperNext class="vendornextbtn">
                    Next
                  </button>
                  <button mat-button matStepperPrevious class="vendorpreviousbtn">
                    Previous
                  </button>
                </div>
              </div>

            </mat-step>

            <!--Slide-5-->
            <mat-step>
             <div class="row">
               <div class="col-md-6">
                <div class="card card-1" style="background-color: #e2e1e157;">
                  <div style="padding: 50px 10px 10px 22px;">
                    <img src="assets/img/new-house-g.png" alt="" width="95px">
                    <h2 style="margin: 0;">For Boys/Girls</h2>
                    <p style="color: #00000061; font-size: 11px;">Earn More</p>
                  </div>
                  <ul style="font-size: 10px;line-height: 25px;word-spacing: 2px;">
                    <li>Proin vulputate ligula at mis</li>
                    <li>Sed vulputate felis nec hendreit</li>
                    <li>Nam at nisl qvis mauris placerat</li>
                    <li>Morbi sed velit nec odio m</li>
                    <li>Cars accumsan nunc id sagittis</li>
                  </ul>
                  <button class="choose-plan">CHOOSE PLAN</button>
                </div>
               </div>
               <div class="col-md-6">
                 <div class="card" style="background-color: #e2e1e157;">
                   <div style="padding: 50px 10px 10px 22px;">
                     <img src="assets/img/new-house-f.png" alt="" width="95px">
                     <h2 style="margin: 0;">For Family</h2>
                    <p style="color: #00000061; font-size: 11px;">A Little Better Than asic Rent</p>
                   </div>
                   <ul style="font-size: 10px;line-height: 25px;word-spacing: 2px;">
                     <li>Proin vulputate ligula at mis</li>
                     <li>Sed vulputate felis nec hendreit</li>
                     <li>Nam at nisl qvis mauris placerat</li>
                     <li>Morbi sed velit nec odio m</li>
                     <li>Cars accumsan nunc id sagittis</li>
                   </ul>
                   <button class="choose-plan">CHOOSE PLAN</button>
                 </div>
               </div>
             </div>

             <div class="row example-full-width">
              <!-- <div class="col-md-4" style="display: flex;margin-top: 15px;">
                <span style=" padding: 0 10px 0 0; margin-top: 2px; font-size: 15px;font-weight: 700;color: #0000008c;">Step 1/5</span>  
                <span class="material-icons">keyboard_arrow_left</span>
                <span class="material-icons">keyboard_arrow_right</span>
              </div> -->
              <div class="col-md-12">
                <button mat-button matStepperNext class="vendornextbtn"
                (click) = "onsavehouseDetails()">
                  Next

                </button>
                <button mat-button matStepperPrevious class="vendorpreviousbtn">
                  Previous
                </button>
              </div>
            </div>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </div>
  </div>
</div>