<div class="container-fluid" style="padding: 5% 12% 5% 12%;">
  <div class="col-sm-12 pd-lf">
    <div class="dash-panel-head">
      <span class="pull-right margin-10">
        <a
          class="btn active btn-default btn-create"
          [routerLink]="['/NewBookings']"
          routerLinkActive="router-link-active"
          >New Bookings</a
        >
      </span>
    </div>
    <!-- panel panel-default -->
    <div class="panel panel-default panel-with-select panel-padding">
      <div class="panel-body no-padding">
        <div class="table-responsive">
          <table class="table table-bordered table-striped all-user-table">
            <thead>
              <tr>
                <th class="thead">ID</th>
                <th class="thead">CUSTOMER ID</th>
                <th class="thead">CUSTOMER NAME</th>
                <th class="thead">HOUSE ADDRESS</th>
                <th class="thead">BOOKING FOR</th>
                <th class="thead">PAYMENT STATUS</th>
                <th class="thead">AMOUNT</th>
                <th class="thead">BOOKING ACTION</th>
                <th class="thead">BOOKING DESCRIPTION</th>
                <th class="thead">CREATED BY</th>
                <th class="thead">CREATED ON</th>
                <th class="thead">MODIFIED BY</th>
                <th class="thead">MODIFIED ON</th>
                <th class="thead">IS ACTIVE</th>
                <th class="thead">CUSTOMER</th>
                <th class="thead">ACTION</th>


              </tr>
            </thead>
            <tbody>
              <tr *ngFor = "let item of bookings">
                <td class="thead" >
                  <a>{{item.id}}</a>
                </td>
                <td class="thead">{{item.customerId}}</td>
                <td class="thead">{{item.customerName}}</td>
                <td class="thead">{{item.houseAddress}}</td>
                <td class="thead">{{item.bookingFor}}</td>
                <td class="thead">{{item.paymentStatus}}</td>
                <td class="thead">{{item.amount}}</td>
                <td class="thead">{{item.bookingAction}}</td>
                <td class="thead">{{item.bookingDescription}}</td>
                <td class="thead">{{item.createdBy}}</td>
                <td class="thead">{{item.createdOn}}</td>
                <td class="thead">{{item.moifiedBy}}</td>
                <td class="thead">{{item.modifiedOn}}</td>
                <td class="thead">{{item.isActive}}</td>
                <td class="thead">{{item.customer}}</td>
                <td class="thead">
                  <a style="cursor: pointer;" (click) = "onSelect(item.id)">Edit</a>
                  <a
                  id="delete_booking"
                  class="delete"
                  style="cursor: pointer;"
                  (click) = "onDeleteBookings(item.id)">
                  <i class="fa fa-trash" ></i>
                  </a>
                </td>
              </tr>
              <!--<tr>
                <td class="thead">
                  <a href="#">Aman</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">Room</td>
                <td class="thead">paid</td>
                <td class="thead">100.0</td>
                <td class="thead"></td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="#">Lal Mohan Jha</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">Bed</td>
                <td class="thead">paid</td>
                <td class="thead">600.0</td>
                <td class="thead"></td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="#">Aman Tripathi</a>
                </td>
                <td class="thead">Mahagun Mywoods, Noida, UTTAR PRADESH</td>
                <td class="thead">House</td>
                <td class="thead">paid</td>
                <td class="thead">2400.0</td>
                <td class="thead"></td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="#">Aman</a>
                </td>
                <td class="thead"></td>
                <td class="thead">Bed</td>
                <td class="thead">paid</td>
                <td class="thead">2000.0</td>
                <td class="thead"></td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="#">Shinendra kumar</a>
                </td>
                <td class="thead"></td>
                <td class="thead">Bed</td>
                <td class="thead">paid</td>
                <td class="thead">14200.0</td>
                <td class="thead"></td>
              </tr>
              <tr>
                <td class="thead">
                  <a href="#">Smit</a>
                </td>
                <td class="thead"></td>
                <td class="thead">Bed</td>
                <td class="thead">paid</td>
                <td class="thead">2000.0</td>
                <td class="thead"></td>
              </tr>-->
            </tbody>
          </table>
          <div class="apple_pagination"></div>
        </div>
      </div>
    </div>
  </div>
</div>
