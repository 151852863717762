<div class="container">
    <div class="row head">
        <div class="header col-md-8">
            <p class="heading">We're here to help you?</p>
            <p>Cant't find the answer? Call us at 1800-7895-000, email us at <span style="font-weight: bolder;">support@stayhook.com</span></p>
        </div>
        <div class="col-md-4">
            <img src="assets/img/u.png" style="height: 100%;">
        </div>
        <i class="fa fa-chevron-circle-down"></i>
    </div>
</div>
<div class="container">
  <div class="row">
    <div class="vertical-tabs col-md-6">
        <span class="tophead">QUESTION</span>  
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag1" role="tab" aria-controls="home">What happens if I cancel my booking?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag2" role="tab" aria-controls="profile">Can I visit a property before booking it?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag3" role="tab" aria-controls="messages">What happens if I damage property by accident?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag4" role="tab" aria-controls="settings">What docoments do I need to carry to the place of booking?</a>
          </li>
  
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag5" role="tab" aria-controls="settings">What if I doesn't complete the minimum lock-in-period?</a>
          </li>
  
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag6" role="tab" aria-controls="settings">Can I keep Pet inside Stayhook permises?</a>
          </li>
  
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag7" role="tab" aria-controls="settings">Can I call my Friends/Family in my house?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag8" role="tab" aria-controls="settings">What if I dosen' pay security?</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#pag9" role="tab" aria-controls="settings">From when my rent will start?</a>
          </li>
        </ul>
      </div>  
        <div class="tab-content col-md-6">
          <span class="tophead">ANSWER</span> 
          <div class="tab-pane active" id="pag1" role="tabpanel">
            <div class="sv-tab-panel">
                <p>If you cancel your booking, you'll receive refund according to Stayhook policies. I.e booking amount will not berefunded.</p>
            </div>
          </div>
          <div class="tab-pane" id="pag2" role="tabpanel">
            <div class="sv-tab-panel">
                <p>Yes, you! feel free to contact us of the desired place for tour any.</p>
            </div>
          </div>
          <div class="tab-pane" id="pag3" role="tabpanel">
            <div class="sv-tab-panel">
                <p>In such cases, the resolution will be done between you and the Stayhook. You're liable to compensate for the damage done to the vendors property.</p> 
            </div>
          </div>
          <div class="tab-pane" id="pag4" role="tabpanel">
            <div class="sv-tab-panel">
                <p>You need to carry the Aadhar copy along with the original docoment, 2 passport size photographs. Please note that PAN card does not qualify as an acceptable ID proof.</p>
            </div>
          </div>
  
          <div class="tab-pane" id="pag5" role="tabpanel">
            <div class="sv-tab-panel">
                <p>In that case your security will not be refundable. For more refer to your rent agreement section.</p>
            </div>
          </div>
  
          <div class="tab-pane" id="pag6" role="tabpanel">
            <div class="sv-tab-panel">
                <p>Yes, you can but only if all peoples that are living in the premises agreed on this.</p>
            </div>
          </div>
  
          <div class="tab-pane" id="pag7" role="tabpanel">
            <div class="sv-tab-panel">
                <p>Yes, you can but make sure that other flatmates don't get disturb.</p>
            </div>
          </div>
          <div class="tab-pane" id="pag8" role="tabpanel">
            <div class="sv-tab-panel">
                <p>Your booking gets cancelled automatically.</p>
            </div>
          </div>
          <div class="tab-pane" id="pag9" role="tabpanel">
            <div class="sv-tab-panel">
                <p>Rent will start from the date of Licence start date.</p>
            </div>
          </div>
        <div class="but">
            <span style="color: #4c38387d;">was this is usefull</span>
            <button class="bute">No</button><button class="bute bt">Yes</button>
        </div>
    </div>
  </div>  
</div>